import React, {useContext, useState, useEffect} from 'react'
import { useRouteMatch } from 'react-router-dom'
import s from './Jobs.module.scss'
import SideBar from 'Components/Nav/SideBar';
import { SessionContext } from 'Utils/sessions';
import Card from 'react-bootstrap/Card'
import {VscCircleFilled} from 'react-icons/vsc'
import {Root} from 'Root'
import Spinner from 'react-bootstrap/esm/Spinner'
import placeholder from 'Static/placeholder.jpeg'
import {isMentor} from 'Utils/sessions'
import {Link} from 'react-router-dom'
import Button from 'react-bootstrap/Button'
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa';

const Jobs = () => {
  const {session, refreshSession} =  useContext(SessionContext);
  const [jobs, setJobs] = useState(null)
  const [error, setError] = useState(null)
  const page = Number(getParameterByName("pg"))

  function getJobs() {
    Root.careerApi.getJobs({pg: page}).then(res => {
      setJobs(res.data)
      setError(null)
    }).catch(err => {
        setJobs([])
        setError('Unable to get roles, please try again later')
    });
  }

  function getParameterByName(param) {
    var match = RegExp('[?&]' + param + '=([^&]*)').exec(window.location.search);
    return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
  }

  useEffect(() => {
    getJobs()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <div>
        <SideBar session={session} title={"Open Roles"}>
          {isMentor(session) && <Link to='/applications/student' className='btnFill mb-5 md d-inline-block'>View My Applications</Link>}
            {error && <p className='form-error'>{error}</p>}
          {jobs ? <>
            <div className='responsiveGrid'>
              {jobs.map((job, idx) => { return <JobCard job={job} /> })}
            </div>  
          </> : 
          <Spinner style={{color:"rgb(16, 142, 115)"}} className='d-block' animation="border" role="status"/>}
                      <div className='d-flex align-items-center'>
              {page > 1 && <Button className='btnLink' onClick={()=> window.location.href = '/jobs?pg=' + (page - 1)}><FaArrowLeft/> Previous</Button>}
              <Button className='btnLink' onClick={()=> window.location.href = '/jobs?pg=' + (page + 1)}>Next <FaArrowRight/> </Button>
            </div>  
   </SideBar>
</div>
}

export default (Jobs);

export const JobCard = ({job, logo, className}) => {
  const [company, setCompany] = useState([])
  useEffect(()=> {
    if(!logo) {
      Root.careerApi.getCompany(job.companyId).then((res)=> {
        setCompany(res.data)
      }).catch(()=> setCompany({}))
    }
  },[job, logo])
    return <Card className={`xlCard ${s.jobCard} ${className}`} onClick={()=> window.location.href = '/job/' + job.id}>
    <Card.Img style={{ height: '100px', objectFit: 'cover' }} variant="top" src={logo || company.logo || placeholder} />
    <Card.Body>
      <Card.Title className='fs-16 trunc'>{job.title}</Card.Title>
      <Card.Text className='text-muted trunc fs-13'>
      {job.compensationDescription} 
      <VscCircleFilled size={7}/>
       {` ${job.location || '-'} `} 
       <VscCircleFilled size={7}/>
        {` ${job.timeCommitment || (job.fullTime ? 'Full Time' : 'Part Time')} `}</Card.Text>
    </Card.Body>
  </Card>
}