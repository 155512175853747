
import React, {useContext, useEffect, useState} from 'react'
import { SessionContext } from 'Utils/sessions';
import Card from 'react-bootstrap/Card';
import defaultAvi from 'Static/default-avi.png';
import { FaGraduationCap, FaEdit } from 'react-icons/fa';
import Button from 'react-bootstrap/esm/Button';
import avi from 'Static/default-avi.png';
import Modal from 'react-bootstrap/Modal'
import s from '../styles/Dashboard.module.scss'
import { TextAreaInput } from 'Input/Input';
import { Link } from 'react-router-dom';
import { majors } from 'Utils/majors';
import { ids } from 'Utils/idToCollege';
import { states } from 'Utils/regions';
import {FaArrowAltCircleRight} from 'react-icons/fa'
import { ScheduleMeeting } from '../../Discover/Components/BookMeeting';
import { SelectInput } from 'Input/Input';
import { Root } from 'Root';
import { formatIso } from 'Utils/time';
import {isEmployer, isStudent, isMentor} from 'Utils/sessions'
import { isActiveMeeting, getTimezone } from 'Utils/time'
import {RiBriefcase2Line} from 'react-icons/ri'
import {VscCircleFilled} from 'react-icons/vsc'
import Spinner from 'react-bootstrap/esm/Spinner';
import {Tile} from 'Components/Tile/Tile'
import {
  Redirect
} from "react-router-dom";
import { FaLink } from 'react-icons/fa';

export const ApplicantCard = ({app, session}) => {
    const [show, setShow] = useState(false)
    const [showSchedule, setShowSchedule] = useState(false)
    const [updateErr, setUpdateErr] = useState(null)
    const [application, setApplication] = useState({})
    const [statusLoading, setStatusLoading] = useState(false)
    const a = app.applicant || {}
    const p = app.applicant ? app.applicant.profile || {} : {}
    const exists = (val) => val && val != 'N/A'

    const statusTypes = {
        "New": "New",
        "Reviewing" : "Reviewing",
        "Interviewing": "Interviewing",
        "Reviewing" : "Reviewing",
        "Hired": "Hired",
        "Rejected" :"Rejected"
    }

    function locationString() {
        let res = ""
        if(exists(p.state) &&  states[p.state]) res+= states[p.state] + ' '
        if(exists(p.country)) res+= p.country + ' '
        return res
    }

    function getApp () {
        Root.careerApi.getApplication(app.id).then((res) => {console.log(res); setApplication(res.data)})
        .catch(()=> setApplication(app))
    }

    function updateStatus(value) {
        setStatusLoading(true)
        setUpdateErr(null)
        Root.careerApi.updateApplication(application.jobPostingId, app.id, {state: value})
        .then(()=> { 
          getApp()
          setStatusLoading(false)
         }).catch(()=> {
          setStatusLoading(false)
          setUpdateErr('Error updating status')
        })
    }

    useEffect(()=> { setApplication(app) },[])

    return <><Card style={{height:'250px', width:'280px'}}>
        <Card.Body>
            <div className='d-flex justify-content-center mb-2'>
                <img className='me-3' style={{width:'70px', height:'70px', borderRadius:'10px', objectFit:'cover'}} src={p.photo || defaultAvi} alt='avi'/>
                <span>
                    <p style={{maxWidth:'200px'}} className='fs-13 mb-1 trunc'><strong>{a.firstName} {a.lastName}</strong></p>
                    <p style={{maxWidth:'150px'}} className='fs-10 mb-0 trunc'><FaGraduationCap className='purple me-2'/> {ids[a.college]}</p>
                    <p style={{maxWidth:'150px'}} className='fs-10 mb-1 trunc'><FaGraduationCap className='purple me-2'/> {majors[p.major]}</p>
                </span>
            </div>
            <p className='fs-13 mb-1 trunc'><span className='text-muted'>Position:</span> {app.jobPosting.title}</p>
            <p className='fs-13 mb-1 trunc'><span className='text-muted'>Graduation Year:</span> {a.graduationYear}</p>
            <p className='fs-13 mb-1 trunc'><span className='text-muted'>Location:</span> {locationString()}</p>
            <p className='fs-13 mb-1 trunc'><span className='text-muted'>GPA:</span> {a.gpa || '-'}</p>
            {a.resume && <p className='fs-13 mb-1 trunc'><span className='text-muted'>Resume:</span> <a className='btnLink purple' target="_blank" href={a.resume}><FaLink/> {a.firstName}'s Resume</a></p>}
            
            <Button className='btnFill wide mt-2' onClick={()=>{setShow(true)}}>View Application</Button>
        </Card.Body>
    </Card>

    <Modal show={show} onHide={()=> {setShow(false); setShowSchedule(false);setUpdateErr(null);}}>
    {showSchedule ?
        <ScheduleMeeting session={session} student={p} isInterview={true} applicationId={app.id} companyId={app.companyId} jobPostingId={app.jobPostingId}/> :
            <>
            <Modal.Header closeButton>
                <Modal.Title>Application</Modal.Title>
                </Modal.Header>
            <Modal.Body>
                <p className='mb-1 fs-14'><strong>{a.firstName} {a.lastName}</strong></p>
                <div className='mb-2'>
                    <img className='mb-2' style={{width:'70px', height:'70px', borderRadius:'10px', objectFit:'cover'}} src={p.photo || defaultAvi} alt='avi'/>
                    <span>
                        <p className='fs-13 mb-1 trunc'><span className='text-muted'>University:</span> {ids[a.college]}</p>
                        <p className='fs-13 mb-1 trunc'> <span className='text-muted'>Major:</span> {majors[p.major]}</p>
                        <p className='fs-13 mb-1 trunc'><span className='text-muted'>Graduation Year:</span> {a.graduationYear}</p>
                        <p className='fs-13 mb-1 trunc'><span className='text-muted'>Location:</span> {locationString()}</p>
                        <p className='fs-13 mb-1 trunc'><span className='text-muted'>GPA:</span> {a.gpa || '-'}</p>
                    </span>
                </div>
                <Link className='btnLink fs-14' to={'/profile/student/' + a.userName}>View profile <FaArrowAltCircleRight/></Link>
                <hr/>
                <p className='mb-1 fs-14'><strong>Job Status</strong></p>
                <p className='fs-14 text-muted'>{app.jobPosting.active ? 'Accepting applications' : 'Not accepting applications'}</p>
                <p className='mb-1 fs-14'><strong>Application Status</strong></p>
                {statusLoading ? <Spinner style={{color:"rgb(16, 142, 115)", height:'1rem', width:'1rem', borderWidth:'0.2em'}} className='me-2 p-0' animation="border" role="status"/> : <FaEdit className='blue me-2'/>}
                <SelectInput options={statusTypes} errors={{state: updateErr}} name={"state"} onChange={(e)=>{updateStatus(e.target.value)}} placeholder={statusLoading ? 'Loading' : application.state } containerClassName='d-inline-block mb-3'/>
                <p className='mb-1 fs-14'><strong>Position</strong></p>
                <p className='fs-14 text-muted'>{app.jobPosting.title || '-'}</p>

                {app.questionResponses.length > 0 && <p className='mb-1 fs-14'><strong>Questions</strong></p>}
                {app.questionResponses.map((q) => { return <>
                <p className='fs-14 mb-1'>{q.question}</p>
                <p className='fs-14 text-muted'>{q.response || '-'}</p>
                </>})}


                <Link className='btnLink fs-14' to={'/job/' + app.jobPosting.id}>Go to job post <FaArrowAltCircleRight/></Link>
                <hr/>
                <Button className='btnFill mt-2' onClick={()=>setShowSchedule(true)}>Schedule an Interview</Button>
            </Modal.Body>
            </>
        }

    </Modal>
    </>
}

export const StudentCard = ({student, style}) => {
    return <Card className={`xlCard`} style={style}>
        <Card.Body>
            <div className='d-flex mb-2'>
                <img className='me-3' style={{width:'70px', height:'70px', borderRadius:'10px', objectFit:"cover"}} src={student.photo || defaultAvi} alt='avi'/>
                <span>
                    <p style={{maxWidth:'150px'}} className='fs-13 mb-1 trunc'><strong>{student.firstName} {student.lastName} </strong></p>
                    <p style={{maxWidth:'150px'}} className='fs-10 mb-0 trunc'><FaGraduationCap className='purple me-2'/>{ids[student.college]}</p>
                    <p style={{maxWidth:'150px'}} className='fs-10 mb-0 trunc text-muted'>{majors[student.major]}</p>
                    <p style={{maxWidth:'150px'}} className='fs-10 mb-0 trunc text-muted'>Class of {student.graduationYear}</p>
                </span>
            </div>
            <Link className='btnFill wide mt-2' to={`/profile/student/${student.userName}`}>View Profile</Link>
        </Card.Body>
    </Card>
}

export const InviteApply = ({session, studentId}) => {
    const [show, setShow] = useState(false)
    const [job, setJob] = useState(null)
    const [idx, setIdx] = useState(0)
    const template = `Hello, I came across your profile and I think you’d be a great candidate for this open role. I wanted to personally invite you to apply.`
    const [message, setMessage] = useState(template)
    const [jobs, setJobs] = useState(null)
    const [jobError, setJobError] = useState(null)
    const [error, setError] = useState(null)
    const [success, setSuccess] = useState(false)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
      getJobs()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function getJobs() {
      Root.careerApi.getJobsByCompany(session.companyId).then(res => {
          setJobs(res.data)
          setJobError(null)
      }).catch(() => {
          setJobs({})
          setJobError('Unable to get roles, please try again later')
      });
    }

    function sendMessage(){
      setError(null)
      setSuccess(false)
      setLoading(true)

      if(!message) {
        setLoading(false)
        setError("Message is required")
        return
      }

      const link = ` Apply here: https://${window.location.hostname}/job/${job}`

      Root.miscApi.createThread({
          participantIds: [studentId, session.id || session.userId],
          senderId: session.id || session.userId,
          message: message + link,
          subject: "",
          messageType: "Text"
      }).then(()=> {
          setLoading(false)
          setMessage(null)
          setSuccess(true)
      }).catch(()=> {setError('Error sending message, please try again'); setLoading(false);})
  }

    const JobCard = ({j}) => {
        return <Card className={`medCard ${job === j.id && s.active}`} onClick={()=> setJob(j.id)} style={{cursor:"pointer", border: '1px solid #D4DDE6'}}>
            <Card.Body>
               <Card.Title className='fs-16 trunc mb-1'>{j.title}</Card.Title>
               <Card.Text className='text-muted trunc fs-10'>
                {` ${j.isPaid ? 'Paid' : 'Unpaid'} `}
                <VscCircleFilled size={7}/>
                {` ${j.location || '-'} `} </Card.Text>
            </Card.Body>
        </Card>
    }

    return <>
    <Button className='btnFill wide mt-2' onClick={()=>setShow(true)}>Invite to Apply</Button>

    <Modal show={show} onHide={()=> setShow(false)} className={s.recruitModal}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {idx === 0 && "Invite To Apply to Which Position?"}
                    {idx === 1 && "Add a Message"}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {idx === 0 && <> {jobs ?
                <>
                  {jobError && <p className='form-error'>{jobError}</p>}
                  <div className='responsiveGrid'>
                      {jobs.map((j)=> <JobCard j={j}/>)}
                  </div>
                </> :
                  <Spinner style={{color:"rgb(16, 142, 115)"}} animation="border" role="status"/>
                  }
                </>
                }
                {idx === 1 &&
                <TextAreaInput containerClassName='mb-3' errors={{}}  value={message} onChange={(e) => setMessage(e.target.value)} placeholder={""}/>
                }
            </Modal.Body>
            <Modal.Footer className='d-block'>
                { idx === 1 && <div className='justify-content-between d-flex'>
                    <Button className='btnOutline md' onClick={()=>setIdx(0)}>Back</Button>
                    <Button className='btnFill md' disabled={loading} onClick={sendMessage}>Send Request</Button>
                </div>}
                { idx === 0 && <div className='justify-content-between d-flex'>
                    <Button className='btnOutline md' onClick={()=>setShow(false)}>Cancel</Button>
                    <Button className='btnFill md' onClick={()=> setIdx(1)} disabled={!job}>Next</Button>
                </div>}
                <p className='form-error'>{error}</p>
                    {success && <Tile color='purple' className='fs-13'>Message sent</Tile>}
            </Modal.Footer>
        </Modal>
    </>
}

export const InterviewCard = ({i, className}) => {
    const localDateTime = formatIso(i.interviewDate, i.interviewTime)
    const [show, setShow] = useState(false)
    const [key, setKey] = useState('notes')
    const [message, setMessage] = useState(null)
    const [error, setError] = useState(null)
    const [success, setSuccess] = useState(false)
    const {session, refreshSession} =  useContext(SessionContext);

    function sendMessage(){
        setError(null)
        setSuccess(false)
        if(!message) {
            return
        }
        Root.miscApi.createThread({
            participantIds: [i.application.applicant.id , i.employee.id],
            senderId: session.id || session.userId,
            message: message,
            subject: "",
            messageType: "Text"
        }).then(()=> {
            setMessage(null)
            setSuccess(true)
        }).catch(()=> setError('Error sending message, please try again'))
    }

    return <><Card onClick={()=>setShow(true)} className={`medCard ${s.interviewCard} ${className}`} >
    <Card.Body>
    <Card.Text className='mb-0 trunc fs-16'>
      <p className="text-muted fs-13 mb-0 trunc">{i.application.applicant.firstName} & {i.employee.firstName}</p>
    <span className='d-block trunc fs-13 mb-1'><RiBriefcase2Line className='green me-1'/><strong>{i.jobPosting.title}{' '}({i.company.name})</strong></span>
    </Card.Text>
    <p className="text-muted fs-13 mb-0 trunc">{localDateTime}</p>
    </Card.Body>
  </Card>


  <Modal show={show} onHide={()=> setShow(false)}>
    <Modal.Header closeButton>
      <Modal.Title>
         Interview Details
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>

    <p className='mt-4 mb-1'><strong>{isEmployer(session) ? 'Applicant' : 'Interviewer'}</strong></p>
    <div style={{border:'1px solid #EBEBEC', padding:'20px', borderRadius:'20px'}}>
          {(isEmployer(session)) && <><span className='d-flex justify-content-between'>
            <span>
              <img src={i.application.applicant.photo || avi} style={{height:'50px', width:'50px', borderRadius:'50%', objectFit:'cover'}} />
              <span className='ms-2'>{i.application.applicant.firstName}</span>
            </span>
          </span>
          </>}

          {isStudent(session) &&<span className='d-flex justify-content-between'>
            <span>
              <img src={i.employee.photo || avi} style={{height:'50px', width:'50px', borderRadius:'50%', objectFit:'cover'}} />
              <span className='ms-2'>{i.employee.firstName}</span>
            </span>
          </span>}
    </div>

    <p className='mt-4 mb-1'><strong>Interview Info</strong></p>
        <div style={{border:'1px solid #EBEBEC', padding:'20px', borderRadius:'20px'}}>
          <span className='d-flex justify-content-between'>
            <span className='fs-14'>Company</span>
            <span className='fs-14'><Link className='btnLink' to={`/company/${i.company.id}`}>{i.company.name}</Link></span>
          </span>
        <hr/>
          <span className='d-flex justify-content-between'>
            <span className='fs-14'>Position</span>
            <span className='fs-14'><Link className='btnLink' to={`/job/${i.jobPosting.id}`}>{i.jobPosting.title}</Link></span>
          </span>
          <hr/>
          <span className='d-flex justify-content-between'>
            <span className='fs-14'>Time</span>
            <span className='fs-14'>{formatIso(i.interviewDate, i.interviewTime)}</span>
          </span>
          <hr/>
          <span className='d-flex justify-content-between'>
            <span className='fs-14'>Duration</span>
            <span className='fs-14'>{i.duration} min</span>
          </span>
          <hr/>
          <span className='d-flex justify-content-between'>
            <span className='fs-14'>Call Type</span>
            <span className='fs-14'>{i.primaryCommunication}</span>
          </span>
        </div>

        <p className='mt-4 mb-1'><strong>Message {isEmployer(session) ? i.application.applicant.firstName : i.employee.firstName}</strong></p>
        <div style={{border:'1px solid #EBEBEC', padding:'20px', borderRadius:'20px'}}>
          <TextAreaInput containerClassName="mb-3" maxLength={300} name="message" value={message} errors={{}}
                onChange={(e)=> setMessage(e.target.value)} placeholder="" />
          <Button onClick={sendMessage} className='btnOutline me-2'>Send</Button>
          {success && <p className='d-inline-block fs-13 p-0 mt-1 mb-0 green'>Message sent</p>}
          {error && <p className='form-error d-inline-block fs-13 p-0 mt-1 mb-0'>{error}</p>}
        </div>

        <div className='mt-3'>
          <Button onClick={()=> setKey('notes')} className={`btnLink ${key !== 'notes' && 'grey'}`}><strong>Notes</strong></Button>
          {(isEmployer(session)) && <Button onClick={()=> setKey('aboutStudent')} className={`btnLink ps-0 ${key !== 'aboutStudent' && 'grey'}`}><strong>About {i.application.applicant.firstName}</strong></Button>}
          <div style={{border:'1px solid #EBEBEC', padding:'20px', borderRadius:'20px'}}>
            {key === 'notes' && <p className='text-muted fs-14'>{i.description || 'No meeting notes'}</p>}
            {key === 'aboutStudent' && <UserDetails profile={i.application.applicant.profile}/>}
          </div>
        </div>
        <JoinInterview interview={i}/>
    </Modal.Body>
    <Modal.Footer>
        <ManageInterview interview={i}/>
    </Modal.Footer>
  </Modal>
    </>
  }

   const UserDetails = ({profile}) => {
    const viewProfile = () => {return window.location.href = '/profile/student/' + profile.userName}
    return <div>
      <p className='text-muted mb-1 fs-14'>Bio</p>
      <p className='fs-14'>{profile.bio}</p>
      <div className='d-flex justify-content-between align-items-center'>
        <span>
          <p className='text-muted mb-1 fs-14'>GPA</p>
          <p className='green'> {profile.gpa || '-'}</p>
        </span>
      </div>
      <p className='text-muted mb-1 fs-14'>Field of Study</p>
      <p className='trunc fs-14'>{majors[profile.major]}</p>
      {profile.studentType === 'CURRENT' && <Button onClick={viewProfile} className='btnLink d-block ps-0'>View Full Profile</Button>}

    </div>
  };

  const ManageInterview = ({interview}) => {
    const [errorMessage, setErrorMessage] = useState(null)
    const {session, refreshSession} =  useContext(SessionContext);

    function handleConfirm(){
        Root.careerApi.updateInterview(interview.id, {status: 'SCHEDULED', timezone: getTimezone()}).then(rsp => {
          window.location.reload()
        }).catch((err) => {
          if(err.response && err.response.data) {
            setErrorMessage(err.response.data)
          } else {
          setErrorMessage(`Something went wrong, please contact support for assistance`)
          }
      })
    }

    function handleCancel(){
        setErrorMessage(null)
        Root.careerApi.updateInterview(interview.id, {status: 'CANCELED', canceler: session.id || session.userId}).then(rsp => {
            window.location.reload();
        }).catch(error =>
          setErrorMessage("Something went wrong, please try again")
        )
    }


    return <>
    <div>
      <span>
       {interview.status.toUpperCase() === 'PROPOSED' && isMentor(session) &&
        <Button onClick={handleConfirm} className='btnOutline md'>Accept Request</Button>}
        {!['COMPLETED', 'CANCELED', 'CANCELLED'].includes(interview.status.toUpperCase()) && <Button onClick={handleCancel} className='btnFill md red ms-3'>Cancel Meeting</Button>}
      </span>
        <p className='form-error mb-0 mt-1'>{errorMessage}</p>
    </div>
  </>}

const JoinInterview = ({interview}) => {
    const [errorMessage, setErrorMessage] = useState(null)
    const [meetingToken, setMeetingToken] = useState(null)
    const [redirectMeeting, setRedirectMeeting] = useState(null)
    const {session, refreshSession} =  useContext(SessionContext);

    // interviewId, applicantId, employerId
    function handleJoin() {
      return Root.careerApi.joinInterview(interview.id, session.id || session.userId).then(rsp => {
        setMeetingToken(rsp.data)
        setRedirectMeeting(true);
      }).catch(() =>
          setErrorMessage("Unable to join interview")
      )
    }


    if (redirectMeeting) {
        return <Redirect to={{
            pathname: '/interview',
            state: {
            data: {
                token: meetingToken,
                interview: interview,
                timezone: getTimezone()
            }
         }
        }} />
    }

    return <>
         {interview.status.toUpperCase() !== 'PROPOSED' && <Button onClick={handleJoin} disabled={!isActiveMeeting(interview.interviewDate, interview.interviewTime, interview.duration)} className={'btnOutline wide mt-3 md'}>Join Interview</Button>}
        <p className='form-error text-center'>{errorMessage}</p>
    </>
  }

  export const ReviewContractor = ({session, studentId}) => {
    const [show, setShow] = useState(false)
    const [contract, setContract] = useState(null)
    const [idx, setIdx] = useState(0)
    const [contracts, setContracts] = useState(null)
    const [error, setError] = useState(null)
    const [review, setReview] = useState("")
    const [rating, setRating] = useState(null)
    const [success, setSuccess] = useState(false)
    const [loading, setLoading] = useState(false)
    const onHide = () => {setShow(false); setError(null); setReview(""); setRating(null); setIdx(0); setContract(null);}
    useEffect(() => {
      setError(null)
      if(studentId) {
      Root.careerApi.getUserContracts({userId: studentId, companyId: session.companyId})
      .then(res=> setContracts(res.data))
      .catch((err)=> setError("Please try again later"))
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [studentId])

    const JobCard = ({jobId, contractId}) => {
      const [job, setJob] = useState(null)
      const [jobError, setJobError] = useState(null)
      useEffect(()=> {
        if(!job){
          Root.careerApi.getJob(jobId).then(res => {
              setJob(res.data)
              setJobError(null)
          }).catch(err => {
              setJobError({})
              setJobError('Unable to get job details')
          });
        }
      },[])
      return <Card className={`medCard ${contract && contract === contractId && s.active}`} onClick={()=> setContract(contractId)} style={{cursor:"pointer", border: '1px solid #D4DDE6'}}>
          <Card.Body>
            {job ? <Card.Title className='fs-16 trunc mb-1'>{job.title}</Card.Title> : <Spinner style={{color:"rgb(16, 142, 115)"}} animation="border" role="status"/>}
            {jobError && <Card.Text className='form-error'>{jobError}</Card.Text>}
          </Card.Body>
      </Card>
   }

   function submitReview(){
    setError(null)
    setSuccess(false)
    setLoading(true)

    if(!rating) {
      setLoading(false)
      setError("Rating is required")
      return
    }

    const data =  {
      reviewerId: session.id,
      rating: rating,
      review: review
    }

    Root.careerApi.submitReviewByContract(contract, data).then(()=> {
        setLoading(false)
        setRating(null)
        setReview(null)
        setSuccess(true)
    }).catch(()=> {setError('Error submitting review, please try again'); setLoading(false);})
}

    return <>
    <Button className='btnOutline wide mt-2' onClick={()=>setShow(true)}>Leave a Review</Button>

    <Modal show={show} onHide={onHide} className={s.recruitModal}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {idx === 0 && "Leave a review for which contract?"}
                    {idx === 1 && "Leave a review"}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {idx === 0 && <> {contracts ?
                <>
                  <div className='responsiveGrid'>
                      {contracts.map((contract)=> <JobCard jobId={contract.jobId} contractId={contract.id}/>)}
                  </div>
                </> :
                  <Spinner style={{color:"rgb(16, 142, 115)"}} animation="border" role="status"/>
                  }
                </>
                }
                {idx === 1 && <>
                  <SelectInput options={{1:1, 2:2, 3:3, 4:4, 5:5}} containerClassName='mb-3' errors={{}}  value={rating} onChange={(e) => setRating(e.target.value)} label={"* Rating (1-5)"}/>
                  <TextAreaInput containerClassName='mb-3' errors={{}}  value={review} onChange={(e) => setReview(e.target.value)} placeholder={""} label="Comments"/>
                </>}
            </Modal.Body>
            <Modal.Footer className='d-block'>
                { idx === 1 && <div className='justify-content-between d-flex'>
                    <Button className='btnOutline md' onClick={()=>setIdx(0)}>Back</Button>
                    <Button className='btnFill md' disabled={loading} onClick={submitReview}>Submit Review</Button>
                </div>}
                { idx === 0 && <div className='justify-content-between d-flex'>
                    <Button className='btnOutline md' onClick={()=>setShow(false)}>Cancel</Button>
                    <Button className='btnFill md' onClick={()=> setIdx(1)} disabled={!contract}>Next</Button>
                </div>}
                <p className='form-error'>{error}</p>
                    {success && <Tile color='purple' className='fs-13'>Review submitted</Tile>}
            </Modal.Footer>
        </Modal>
    </>
}
