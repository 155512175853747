import React, {useState, useEffect, useContext} from 'react'
import Button from 'react-bootstrap/Button'
import Popover from 'react-bootstrap/Popover'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import {RiNotification2Fill, RiNotification2Line} from 'react-icons/ri'
import Spinner from 'react-bootstrap/esm/Spinner'
import {Root} from 'Root'
import { SessionContext, isStudent } from 'Utils/sessions';
import { Link } from 'react-router-dom'
import { timestampToDiff } from 'Utils/time';
import Modal from 'react-bootstrap/Modal';
import ModalHeader from 'react-bootstrap/esm/ModalHeader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const ImportantNotificationPopup = ({notification, session, ...props}) => {

    const onHide = () => {
      Root.userApi.markNotificationRead(session.id, notification.id).then(() => {
        fetchNotifs();
      }).catch(err => console.log(err))

      props.setShowImportantNotification(false);
    }

    function markAsRead(id) {
      onHide();
    }

    return (
        <Modal show={true} onHide={onHide}>
          <ModalHeader closeButton>
            <Modal.Title>{notification.subject || 'Important Notification'}</Modal.Title>
          </ModalHeader>
          <Modal.Body>
          <p>{notification.message}</p>
          {notification.link && <div>
           <Link className='btnLink fs-13' onClick={() => { markAsRead(notification.id); }} to={notification.link}>{notification.action || 'Click to view'}</Link>
          </div>}
          {!notification.link && <div>
           <Link className='btnLink fs-13' onClick={() => { markAsRead(notification.id); }}>Dismiss</Link>
          </div>}
          </Modal.Body>
        </Modal>
    )
}

const Notifications = ({className}) => {
  const {session, refreshSession} =  useContext(SessionContext);
  const [notifs, setNotifs] = useState(null)
  const [error, setError] = useState(null)
  const [importantNotification, setImportantNotification] = useState(null)
  const [showImportantNotification, setShowImportantNotification] = useState(false)

  // const actionItems = {
  //   ssn: {
  //       message: "In order to process bank account payments, it is legally required that you provide the last four digits of your SSN as well as your address. Follow the link to go to settings to enter your information",
  //       variant: "danger",
  //       link: "/settings",
  //       linkText: "Click Here",
  //       type: "important",
  //       subject: "Payment",
  //       timeOfCreation: Math.floor(Date.now() / 1000)
  //   },
  //   personalityProfile: {
  //       message: "Your NXSTEP profile has not been created! Follow the link to complete your profile.",
  //       variant: "warning",
  //       link: "/onboarding",
  //       linkText: "Click Here",
  //       type: "important",
  //       subject: "Update Your Account",
  //       action: "Complete Profile",
  //       link: "/profile/student/edit",
  //       timeOfCreation: Math.floor(Date.now() / 1000)
  //   },
  //   defaultAccount: {
  //       message: "A default payout method has not been set. Follow the link to go to settings to enter your information in order to recieve payments.",
  //       variant: "warning",
  //       type: "important",
  //       subject: "Payment",
  //       link: "/settings",
  //       linkText: "Click Here",
  //       timeOfCreation: Math.floor(Date.now() / 1000)
  //   },
  //   infoGuide: {
  //       message: "Please read the Information Guide to get an overview of how NXSTEP works!",
  //       variant: "warning",
  //       type: "important",
  //       subject: "Info Guide",
  //       link: "/info",
  //       linkText: "Click Here",
  //       timeOfCreation: Math.floor(Date.now() / 1000)
  //   },
  //   review: {
  //       message: "If you have not done so already, please take the time to rate your school! This helps us recommend schools for NXSTEP mentees.",
  //       variant: "normal",
  //       type: "message",
  //       subject: "University Rating",
  //       link: "",
  //       linkText: "Click Here",
  //       timeOfCreation: Math.floor(Date.now() / 1000)
  //   },
  // }

  function sortMessages(messages){
    return messages.sort((a, b) =>
      a.dispatchTimestamp - b.dispatchTimestamp
    ).reverse()
  }

  function getSortedNotifs(notifications) {
    var messages = []
    messages.push(...notifications)
    // if (session.profile) {
    //   messages.push(actionItems.personalityProfile)
    // }
    return sortMessages(messages)
  }

  function fetchNotifs() {
    Root.userApi.getAllNotifications(session.id)
    .then((rsp) => {
      setNotifs(getSortedNotifs(rsp.data))
      detectImportantMessage(rsp.data)
    })
    .catch((err)=> {
      setImportantNotification(null);
      setShowImportantNotification(false)
      setError('Unable to refresh notifications')})
  }

  function pulseCheck() {
    Root.miscApi.serverPulseCheck()
    .then(() => {})
    .catch(()=> { toast.error("It looks like the server is restarting, please refresh in a few seconds!"); })
  }

  function detectImportantMessage(messages) {
    if (messages) {
      for (let i = 0; i < messages.length; i++) {
        console.log(messages[i].type)
        if (messages[i].type && messages[i].type.toLowerCase() == 'important') {
          setImportantNotification(messages[i]);
          setShowImportantNotification(true)
          return;
        }
      }
    }
  }

  useEffect(()=>{
    pulseCheck()
    var messages = []
    // messages.push(actionItems.personalityProfile)
    setNotifs(messages)
    fetchNotifs()
  },[])

    const popover = (
        <Popover id="popover-basic">
          <Popover.Header as="h3">Notifications</Popover.Header>
          <Popover.Body style={{maxHeight:'500px', overflow:'scroll'}}>
          {notifs ?  <MessageTable session={session} messages={notifs} fetchNotifs={fetchNotifs}/> :
              <Spinner style={{color:"rgb(16, 142, 115)"}} animation="border" role="status">
              </Spinner>
            }
            {error && <p className='text-muted'>{error}</p>}
          </Popover.Body>
        </Popover>
      );

  return      <>
  <OverlayTrigger trigger="click" placement="left" overlay={popover}>
    <Button className={`btnLink ${className}`}><p style={{color:'#23CDAA', verticalAlign: 'sub'}}>{(notifs && notifs.length > 0) ? <RiNotification2Fill fill='#23CDAA' size='20'/> : <RiNotification2Line fill='#23CDAA' size='20'/>}{(notifs && notifs.length > 0) ? '('+ notifs.length + ')' : ''}</p></Button>
  </OverlayTrigger>
  {importantNotification && (showImportantNotification ?
      <>
      <ImportantNotificationPopup notification={importantNotification} session={session} setShowImportantNotification={setShowImportantNotification.bind(this)}/>
      </>
      : <></>)
  }
  <ToastContainer theme="dark" autoClose={false} position="bottom-center" />
</>
}

export default (Notifications);


const MessageTable = ({messages, session, fetchNotifs}) => {

  function markAsRead(id) {
    Root.userApi.markNotificationRead(session.id, id).then(() => {
      fetchNotifs();
    }).catch(err => console.log(err))
  }

  // // Types = ["BANNER", "MENTION", "important"]
  // function createPostLink(input, type){
  //   if(type === "important") {
  //     return input.link;
  //   }
  //
  //   var resource = JSON.parse(input);
  //   if (type.toUpperCase() === "MENTION") {
  //     var instution = "general"
  //     if(resource.institution && resource.institution.length > 1 ){
  //       instution = resource.institution
  //     }
  //     return `/forum/${instution}/${resource.root}`
  //   }
  //   if (type.toUpperCase() === "BANNER") {
  //     return `/${resource.institution}`
  //   }
  //   return null
  // }

  return (
    <>
      {messages && messages.length === 0 && <p className='fs-13 text-muted'>No notifications</p>}
      {messages.map((message, idx) => <> <div key={idx}>
      {!!message.subject ? <p style={{fontSize:'15px', fontWeight:'600', marginBottom:'5px'}}>{message.subject}</p> : <></>}
          <p style={{marginBottom:'5px'}}>{message.message}</p>
          {message.link && <div>
           <Link className='btnLink fs-13' onClick={() => { markAsRead(message.id); }} to={message.link}>{message.action || 'Click to view'}</Link>
          </div>}
          {!message.link && <div>
           <Link className='btnLink fs-13' onClick={() => { markAsRead(message.id); }}>Dismiss</Link>
          </div>}
        </div>
        <hr className='my-3' style={{backgroundColor:'black', height: 3}}/>
      </>)}
    </>
  );
};
