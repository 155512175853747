import React, { useRef } from 'react'
import Button from 'react-bootstrap/Button'

const ImgUploader = (props) => {
  const inputRef = useRef(null)

  const handleClickOutside = event => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  }; 
  return<> 
      <input
    type="file"
    multiple={false}
    onChange={props.onDrop} 
    style={{display:"none"}}
    ref={inputRef}  
    accept={props.accept || "image/*"}   
  />
  <Button style={props.buttonStyles} className={props.buttonClassName} onClick={handleClickOutside}>{props.buttonText}</Button>
</>
}

export default (ImgUploader);