import { states, countries } from './regions'
import { majors, degrees } from './majors'

export const questions = [
  {
    title: 'Personal Info',
    id: 'gender',
    question: 'Gender Identity',
    options: {
      0:'Prefer Not to Say',
      1: 'Male',
      2: 'Female',
      3: 'Non-Binary',
      4: 'Transgender Woman',
      5: 'Transgender Male',
      6: 'Other'
    },
    type: 'select'
  },
  {
    title: 'Personal Info',
    id: 'ethnicity',
    question: 'Race/Ethnicity',
    options: {
      0:'Prefer Not to Say',
      1: 'American Indian or Alaska Native',
      2: 'Asian, Asian American',
      3: 'Black or African American',
      4: 'Mexican or Mexican American',
      5: 'Pacific Islander',
      6: 'Puerto Rican',
      7: 'Other Hispanic, Latino or Latin American',
      8: 'White',
      9: 'Middle Eastern',
      10: 'Other'
    },
    type: 'select'
  },
  {
    title: 'Personal Info',
    id: 'sexuality',
    question: 'Sexuality',
    options: {
      0: 'Prefer Not to Say',
      1: 'Straight',
      3: 'Bisexual',
      4: 'Queer',
      9: 'Gay',
      10: 'Lesbian',
    },
    type: 'select'
  },
  {
    title: 'Personal Info',
    id: 'income',
    question: 'Family Annual Income',
    ordered: 'false',
    options: {
      0: 'Prefer not to say',
      1: '<= $14,100',
      2: '<= $53,700',
      3: '<= $85,500',
      4: '<= $163,300',
      5: '<= $207,350',
      6: '<= $518,400',
      7: '> $518,400'
    },
    type: 'select'
  },
  {
    title: 'Personal Info',
    id: 'country',
    question: 'Country',
    options: countries,
    type: 'select'
  },
  {
    title: 'Personal Info',
    id: 'state',
    question: 'State (Leave blank if not applicable)',
    options: states,
    type: 'select',
  },
  {
    title: 'Personal Info',
    id: 'city',
    question: 'City (Optional)',
    type: 'input'
  },
  {
    title: 'Education',
    id: 'major',
    question: 'Field of Interest',
    options: majors,
    type: 'select'
  },
  {
    title: 'Education',
    id: 'degree',
    question: 'Desired degree',
    options: degrees,
    type: 'select'
  },
  {
    title: 'Education',
    id: 'greekLife',
    question: 'Are you interested or involved in Greek life?',
    options: {
      Y: 'Yes',
      N: 'No'
    },
    type: 'select'
  },
  {
    title: 'Personality',
    id: 'CON:0001',
    question: 'I rarely procrastinate.',
    options: [1, 2, 3, 4, 5],
    type: 'scale'
  },
  {
    title: 'Personality',
    id: 'EXT:0002',
    question: 'I avoid being alone.',
    options: [1, 2, 3, 4, 5],
    type: 'scale'
  },
  {
    title: 'Personality',
    id: 'OPE:0003',
    question: 'I enjoy trying new things.',
    options: [1, 2, 3, 4, 5],
    type: 'scale'
  },
  {
    title: 'Personality',
    id: 'NEU:0004',
    question: "I can't stand when someone doesn't like me.",
    options: [1, 2, 3, 4, 5],
    type: 'scale'
  },
  {
    title: 'Personality',
    id: 'CON:0005',
    question: 'I would rather have a set schedule than go with the flow.',
    options: [1, 2, 3, 4, 5],
    type: 'scale'
  },
  {
    title: 'Personality',
    id: 'EXT:0006',
    question: 'I prefer to go out over staying in and watching TV.',
    options: [1, 2, 3, 4, 5],
    type: 'scale'
  },
  {
    title: 'Personality',
    id: 'OPE:0007',
    question: 'My friends would consider me open-minded.',
    options: [1, 2, 3, 4, 5],
    type: 'scale'
  },
  {
    title: 'Personality',
    id: 'CRE:0008',
    question: 'I like to try to innovate.',
    options: [1, 2, 3, 4, 5],
    type: 'scale'
  },
  {
    title: 'Personality',
    id: 'EXT:0009',
    question: 'Being around lots of people energizes me.',
    options: [1, 2, 3, 4, 5],
    type: 'scale'
  },
  {
    title: 'Personality',
    id: 'NEU:0010',
    question: 'I panic easily.',
    options: [1, 2, 3, 4, 5],
    type: 'scale'
  },
  {
    title: 'Personality',
    id: 'CON:0011',
    question: 'It is easy for me to talk to strangers.',
    options: [1, 2, 3, 4, 5],
    type: 'scale'
  },
  {
    title: 'Personality',
    id: 'AGR:0012',
    question: 'I like to help others with their personal problems.',
    options: [1, 2, 3, 4, 5],
    type: 'scale'
  },
  {
    title: 'Personality',
    id: 'CRE:0013',
    question: 'I enjoy trying to understand complicated ideas.',
    options: [1, 2, 3, 4, 5],
    type: 'scale'
  },
  {
    title: 'Personality',
    id: 'EXT:0014',
    question: 'I enjoy meeting new people.',
    options: [1, 2, 3, 4, 5],
    type: 'scale'
  },
  {
    title: 'Personality',
    id: 'ORG:0015',
    question: 'I start tasks in advance, so that I have plenty of time to finish.',
    options: [1, 2, 3, 4, 5],
    type: 'scale'
  },
  {
    title: 'Personality',
    id: 'OPE:0016',
    question: 'I am always open to new experiences.',
    options: [1, 2, 3, 4, 5],
    type: 'scale'
  },
  {
    title: 'Personality',
    id: 'EXT:0017',
    question: 'I am good at making new friends.',
    options: [1, 2, 3, 4, 5],
    type: 'scale'
  },
  {
    title: 'Personality',
    id: 'EXT:0018',
    question: 'People tell me I am too loud.',
    options: [1, 2, 3, 4, 5],
    type: 'scale'
  },
  {
    title: 'Personality',
    id: 'NEU:0019',
    question: 'I get stressed out easily.',
    options: [1, 2, 3, 4, 5],
    type: 'scale'
  },
  {
    title: 'Personality',
    id: 'AGR:0020',
    question: 'It is important to me to be of service to others.',
    options: [1, 2, 3, 4, 5],
    type: 'scale'
  }
]
