import React, {useContext, useState, useEffect} from 'react'
import s from './styles/Discover.module.scss'
import SideBar from 'Components/Nav/SideBar';
import {SearchBar} from 'Components/Input/Search';
import { SessionContext } from 'Utils/sessions';
import { CollegeCard } from 'Components/Tile/Card';
import { Root } from 'Root';
import Spinner from 'react-bootstrap/esm/Spinner'
import Tabs from 'react-bootstrap/esm/Tabs';
import Tab from 'react-bootstrap/esm/Tab';
import { FaSchool, FaUsers } from 'react-icons/fa';
import { StudentCard } from '../Company/components/Component';

const Search = () => {
  const {session, refreshSession} =  useContext(SessionContext);
  const [query, setQuery] = useState(null)
  const [colleges, setColleges] = useState(null)
  const [collegeError, setCollegeError] = useState(null)
  const [network, setNetwork] = useState(null)
  const [networkError, setNetworkError] = useState(null)
  const [key, setKey] = useState("college")
  const errMsg = "No results found. Please try again later."

  function getParameterByName() {
    var match = RegExp('[?&]' + "query" + '=([^&]*)').exec(window.location.search);
    return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
  }

  useEffect(()=>{
      Root.collegeApi.searchColleges(getParameterByName(), session.id || session.userId)
      .then(res => {setColleges(res.data)})
      .catch(()=> {
          setCollegeError(errMsg)
          setColleges([])
    })
  },[])

  useEffect(()=>{
    Root.userApi.searchMentors(getParameterByName(), session.id || session.userId)
    .then(res => {setNetwork(res.data)})
    .catch(()=> {
        setNetworkError(errMsg)
        setNetwork([])
  })
},[])

  return <div className={s.discover}>
        <SideBar session={session} title={"Search"}>
          <SearchBar containerClassName={`mb-5 ${s.search}`} query={""}/>
          <p className="text-muted fs-13">Results for: "{getParameterByName()}"</p>
          <Tabs activeKey={key} onSelect={(k) => setKey(k)}>
                <Tab eventKey="college" title={<span className='d-flex align-items-center'><span><span className="p-0 m-0">Colleges</span> {' '} <FaSchool size={15} className={`green ml-2`}/></span></span>}>
                    {/* {collegeError && <p className='text-muted fs-13 mt-2'>{collegeError}</p>} */}
                    {colleges ? 
                    <div className={'responsiveGrid mt-4'}>
                       {colleges.map((c)=> <CollegeCard college={c} />)}
                    </div>
                     : <Spinner style={{color:"rgb(16, 142, 115)"}}  className='d-block mt-4' animation="border" role="status"/>}
                    {colleges && colleges.length === 0 && <p className='text-muted fs-13 mt-2'>{errMsg}</p>}
                </Tab>
                <Tab eventKey="network" title={<span className='d-flex align-items-center'><span><span className="p-0 m-0">Network</span> {' '} <FaUsers size={15} className={`green ml-2`}/></span></span>}>
                    {/* {networkError && <p className='text-muted fs-13 mt-2'>{networkError}</p>} */}
                    <div className={'responsiveGrid mt-4'}>
                        {network ? network.map((s)=> <StudentCard student={s} />) : <Spinner style={{color:"rgb(16, 142, 115)"}}  className='d-block mt-4' animation="border" role="status"/>}
                    </div>
                    {network && network.length === 0 && <p className='text-muted fs-13 mt-2'>{errMsg}</p>}
                </Tab>
          </Tabs>

   </SideBar>
</div>
}

export default (Search);
