import React, { useState, useEffect } from 'react';
import { NavBar } from 'Components/Nav/NavBar';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form'
import { validateEmail, push } from 'Utils/validation';
import { Root } from 'Root';
import s from './styles/Login.module.scss';
import {Tile} from 'Components/Tile/Tile'
import Modal from 'react-bootstrap/Modal';
import ModalHeader from 'react-bootstrap/esm/ModalHeader';
import { Link } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { FormErrors, SelectInput, TypeAhead, TextInput, NoAutoFillTextInput } from 'Input/Input'

export const CompanyCodesAlert = ({email, ...props}) => {

    const onHide = () => {
      props.setShowCompany(false);
    }

    return (
        <Modal show={true} onHide={onHide}>
          <ModalHeader closeButton>
            <Modal.Title>Next Steps</Modal.Title>
          </ModalHeader>
          <Modal.Body>
            <p>An email has been sent to {email || 'the one used in this registration'} with
            your password along with further instructions on how to get started.</p>
          </Modal.Body>
        </Modal>
    )
}


const CompanyRegister = () => {
    const [request, setRequest] = useState({})
    const [email, setEmail] = useState(null)
    const [formErrors, setFormErrors] = useState({})
    const [formResponse, setFormResponse] = useState(null)
    const [formResponseError, setFormResponseError] = useState(null)
    const [loading, setLoading] = useState(false)
    const [company, setCompany] = useState(null)
    const [showCompany, setShowCompany] = useState(false)

    function handleChange (event) {
      const { name, value } = event.target
      if (name === 'tos') {
        if (request.tos === 'true') {
          setRequest((prevState) => ({ ...prevState, ['tos']: null }));
        } else {
          setRequest((prevState) => ({ ...prevState, ['tos']: 'true' }));
        }
      } else {
        setRequest((prevState) => ({ ...prevState, [name]: value }));
      }
    }

      function validate (request) {
        var errors = {}
          if (!request.name) {
            push(errors, 'name', 'A company name is required')
          }

          if (!request.email || !validateEmail(request.email)) {
            push(errors, 'email', 'You must provide a valid company email')
          }

          if (!request.description) {
            push(errors, 'description', 'A description of the company is required')
          }

          if (!request.tos) {
            push(errors, 'tos', 'You must read and acknowledge the Terms and Conditions')
          }
        return errors
      }

      function handleSubmit () {
        setFormErrors({});
        setFormResponse(null);
        setFormResponseError(null);
        setEmail(null)
        const errors = validate(request)
            if (Object.keys(errors).length > 0) {
            setFormErrors(errors)
            return
        }
          if(Object.keys(request).length > 0){
            setLoading(true)
            Root.careerApi.createCompany({ 'name': request.name, 'description': request.description, 'size': request.size, 'website': request.website, 'tos': request.tos, 'email': request.email }).then((res) => {
              setEmail(request.email)
              setLoading(false);
              setCompany(res.data)
              setShowCompany(true)
              setRequest({});
              setFormErrors({});
            }).catch(() => {
              setLoading(false);
              setFormResponseError("This email is already registered to an existing user");
            }
          )
        }
      }

      function pulseCheck() {
        Root.miscApi.serverPulseCheck()
        .then(() => {})
        .catch(()=> { toast.error("It looks like the server is restarting, please refresh in a few seconds!"); })
      }

      useEffect(()=>{
        pulseCheck()
      },[])


  return (
    <>
       <NavBar dark={true}/>
      <div className={s.login}>
        <div className={s.form}>
          <h2>Company Registration</h2>
          <p className='text-muted mb-4' style={{textAlign:"left"}}> After you create the account, an ID and Access Code will be displayed below.</p>
            <Form>
                <NoAutoFillTextInput containerClassName='mb-3' errors={formErrors} name={'name'} value={request.name || ''} onChange={handleChange} type={'text'} placeholder={"Company Name"}/>
                <NoAutoFillTextInput containerClassName='mb-3' errors={formErrors} name={'email'} value={request.email || ''} onChange={handleChange} type={'text'} placeholder={"Your Company Email Address"}/>
                <NoAutoFillTextInput containerClassName='mb-3' errors={formErrors} name={'size'} value={request.size || ''} onChange={handleChange} type={'text'} placeholder={"Company Size / Employee Count (Optional)"}/>
                <NoAutoFillTextInput containerClassName='mb-3' errors={formErrors} name={'website'} value={request.website || ''} onChange={handleChange} type={'text'} placeholder={"Website (Optional)"}/>
                <Form.Group>
                    <Form.Control placeholder={'Company Description (600 characters)'} as="textarea" rows="3"  maxLength="600" name={'description'} value={request.description || ''} onChange={handleChange}/>
                    <div className={'form-error'}>{formErrors.description || null}</div>
                </Form.Group>
                <div style={{paddingTop: '10px'}}>
                  <Link className='btnLink mb-3 fs-12' target="_blank" to="/tos">Click to read the Terms of Service</Link>
                </div>
                <Form.Group className="mb-1">
                  <Form.Check inline name="tos" onChange={handleChange} value={'true'} checked={request.tos === 'true'} label="I have read, and I agree to the Terms and Conditions" type='checkbox'/>
                  <div className={'form-error'}>{formErrors.tos || null}</div>
                </Form.Group>
                <Button disabled={loading} className={'btnFill md wide mt-3'} onClick={handleSubmit}>{loading === true ? 'Registering...' : 'Register Company'}</Button>
            </Form>
            <p className='form-error text-center mt-2'>{formResponseError}</p>
            {formResponse &&
              <div>
                <p><strong>Important</strong></p>
                <p style={{textAlign:"left"}}>{formResponse}</p>
              </div>
            }
        </div>
      </div>
      {company && (showCompany ?
          <>
          <CompanyCodesAlert email={email} setShowCompany={setShowCompany.bind(this)}/>
          </>
          : <></>)
      }
      <ToastContainer theme="dark" autoClose={false} position="bottom-center" />
    </>
  );
};

export default (CompanyRegister);
