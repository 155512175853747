import React, {useState, useEffect} from 'react'
import s from '../styles/University.module.scss'
import {AiFillStar} from 'react-icons/ai'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/esm/Card'
import {fields} from '../University'
import {Tile} from 'Components/Tile/Tile'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import ReviewForm from './ReviewForm'
import Modal from 'react-bootstrap/esm/Modal'

const Review = ({session, reviews, averageRating, reducedRating, fetchReviews, ipedsid, isMentor}) => {
    const [stars, setStars] = useState(0)
    const [emptyStars, setEmptyStars] = useState(0)
    // const [ratingCounts, setRatingCounts] = useState({})
    const [key, setKey] = useState('summary')
    const [show, setShow] = useState(false)
    const onHide = () => {setShow(false)}
    const onShow = () => {setShow(true)}

    useEffect(()=>{
        if(averageRating && !isNaN(averageRating)) {
            let s = []
            for (let i = 0; i < Math.round(averageRating); i++) {
                s.push(<AiFillStar key={i} color='#ED9C3C'/>);
            }
            setStars(s)

            let e = []
            let empty = 5 - Math.round(averageRating)
            for (let i = 0; i < empty; i++) {
                e.push(<AiFillStar key={i} color='rgba(237, 156, 60,0.2)'/>);
            }
            setEmptyStars(e)
        }
    },[averageRating])

    // useEffect(()=>{
    //     setRatingCounts(countRatings())
    // },[])

    // function countRatings() {
    //     let res = {}
    //     Object.keys(fields).forEach((field, idx) => {
    //         res[field] = {5: 0, 4: 0, 3: 0 , 2:0, 1:0}       
    //         reviews.forEach((review, i) => {
    //             res[field][review[field]] += 1
    //         })
    //     })
    //     return res
    // }

  return <div className={s.review}>
        
            <div className='mb-4 horScroll'>
                <Button className={`btnFill ${key === 'summary' ? 'purple' : 'l-purple'} me-2`}  onClick={()=>setKey('summary')}>summary</Button>
                {Object.keys(fields).map((f, idx) => <Button className={`btnFill ${key === f ? 'purple' : 'l-purple'} me-2`} onClick={()=>setKey(f)}>{f}</Button>)}
            </div>
            
            {key === 'summary' && <div className={s.summary}>
                    <div>
                        <h1 className='purple text-center'>{averageRating || '-'}</h1>
                        <span className={`d-flex align-items-center justify-content-center`}>
                        {stars}{emptyStars}{' '}<span className='text-muted'>({reviews.length} reviews)</span>
                        </span>
                    </div>

                    <div className={`d-flex align-items-center`}>
                       {isMentor && <Button onClick={onShow} className='btnOutline wide mt-2'>Write Review</Button>}
                    </div>
                </div>}
            {Object.keys(fields).map((f, idx) => <>
               { f === key && <div className={s.summary}>
                    <div>
                        <h1 className='purple text-center'>{reducedRating[f] ? (parseFloat(reducedRating[f])/reviews.length).toFixed(1) : '-'}</h1>
                        <span className={`d-flex align-items-center justify-content-center`}>
                        <AiFillStar color='orange'/>{' '} <span className='text-muted'>({reviews.length} reviews)</span>
                        </span>
                    </div>
                    <div className={`d-flex align-items-center`}>
                        {isMentor && <Button onClick={onShow} className='btnOutline wide mt-2'>Write Review</Button>}
                    </div>
                </div>}
            </>)}


      <h3 className='mt-5 heading'><strong>All Comments</strong></h3>
      <div className={'responsiveGrid'}>
        {reviews.map((review, idx) => {
            if(review.comment)
                return <Card style={{width:'240px'}}>
                    <Card.Body>
                        <Card.Text>{review.comment}</Card.Text>
                    </Card.Body>
                </Card>
        })}
      </div>

      <Modal show={show} onHide={onHide}>
        <ReviewForm closeModal={onHide} session={session} ipedsid={ipedsid} fetchReviews={fetchReviews}/>
      </Modal>
  </div>
}

export default (Review);

                {/* <div>
                        <span className='d-block'>
                            <AiFillStar color='#ED9C3C'/>{' '}5 
                            <span className='text-muted'>({ratingCounts[key] && ratingCounts[key][5]})</span>
                        </span>
                        <span className='d-block'>
                        <AiFillStar color='#ED9C3C'/>{' '}4 
                            <span className='text-muted'>({ratingCounts[key] && ratingCounts[key][4]})</span>
                        </span>
                        <span className='d-block'>
                        <AiFillStar color='#ED9C3C'/>{' '}3 
                            <span className='text-muted'>({ratingCounts[key] && ratingCounts[key][3]})</span>
                        </span>
                        <span className='d-block'>
                        <AiFillStar color='#ED9C3C'/>{' '}2 
                            <span className='text-muted'>({ratingCounts[key] && ratingCounts[key][2]})</span>
                        </span>
                        <span className='d-block'>
                        <AiFillStar color='#ED9C3C'/>{' '}1 
                            <span className='text-muted'>({ratingCounts[key] && ratingCounts[key][1]})</span>
                        </span>

                    </div> */}