import React, { useState, useContext, useEffect } from 'react';
import { NavBar } from 'Components/Nav/NavBar';
import Button from 'react-bootstrap/Button';
import { TextInput } from 'Input/Input';
import { validateEmail, push } from 'Utils/validation';
import { Root } from 'Root';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { setSessionCookie, SessionContext, isMentor, isPartner, isMentee } from 'Utils/sessions';
import s from './styles/Login.module.scss';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Login = () => {
  const [request, setRequest] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const {session, refreshSession} = useContext(SessionContext);
  const [userType, setUserType] = useState(null);
  const [loading, setLoading] = useState(false)

  useDeepCompareEffect(() => {
    if (session.id) {
      if (isPartner(session)){
        window.location.href = '/dashboard/partner';
      } else if (isMentor(session) || isMentee(session)){
        window.location.href = '/dashboard/student';
      }
    }
  }, [session]);

  function validate(req) {
    const errors = {};

    if (!validateEmail(req.email)) {
      push(errors, 'email', 'Valid email is required');
    }
    if (!req.password) {
      push(errors, 'password', 'Password is required');
    }
    return errors;
  }

  function pulseCheck() {
    Root.miscApi.serverPulseCheck()
    .then(() => {})
    .catch(()=> { toast.error("It looks like the server is restarting, please refresh in a few seconds!"); })
  }

  function handleSubmit() {
    setLoading(true)
    setFormErrors({});

    const errors = validate(request);
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      setLoading(false)
      return;
    }

    Root.userApi.authenticateUser(request, userType).then((res) => {
      setLoading(false)
      return setSessionCookie(res.data);
    }).then(()=> {
      setLoading(false)
      if(userType === 'partner') {
        window.location.href = '/dashboard/partner'
      } else if (userType === 'student') {
        window.location.href = '/dashboard/student'
      } else if (userType === 'employer') {
        window.location.href = '/dashboard/employer'
      }
    }).catch((err) => {
      setLoading(false)
      if (err.response && err.response.status >= 400 && err.response.status < 500) {
        setFormErrors({ password: 'Incorrect email or password' });
      } else {
      setFormErrors({ password: 'Unable to login. Please contact support if error persists' });
      }
    });
  }


  useEffect(()=>{
    pulseCheck()
  },[])

  function handleChange(event) {
    const { name, value } = event.target;
    setRequest((prevState) => ({ ...prevState, [name]: value }));
  }

  function handleKeyPress(event) {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  }
  return (
    <>
      <NavBar dark={true}/>

      <div className={s.login}>
        {!userType ?
        <div className='mx-auto' style={{maxWidth:'500px', marginTop:'18%'}}>
          <h3 className='text-center fs-20 mb-2'>I am a ...</h3>
          <Button className='btnFill md my-3 d-block wide' onClick={()=> setUserType('student')}>College Student or Applicant</Button>
          <Button className='btnFill md my-3 d-block wide dark' onClick={()=> setUserType('employer')}>Employer</Button>
          <Button className='btnFill md my-3 d-block wide green' onClick={()=> setUserType('partner')}>School Partner</Button>
        </div>
        :
        <>
          <div className={s.form}>
            <h2>{userType === 'partner' ? 'Partner' : (userType === 'employer' ? 'Employer' : (userType === 'student' ? 'Student' : ''))} Log In</h2>
            <p>
              Dont have an account? {' '}
              <a className='btnLink' href="/register">Register Now</a>
            </p>
            <TextInput containerClassName='mb-1' errors={formErrors} name="email" value={request.email || ''} onKeyPress={handleKeyPress} onChange={handleChange} label="Email" />
            <TextInput containerClassName='mb-3' type="password" errors={formErrors} name="password" onKeyPress={handleKeyPress} value={request.password || ''} onChange={handleChange} label="Password" />
            <p>
              Forgot password? {' '}
              <a className='btnLink' href="/recover">Recover your account</a>
            </p>
            <Button onClick={handleSubmit} disabled={loading} className="btnFill md d-block">{loading ? "Loading..." : "Login"}</Button>
          </div>
        </>}
      </div>
      <ToastContainer theme="dark" autoClose={false} position="bottom-center" />
    </>
  );
};

export default (Login);
