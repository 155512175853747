import React, { useContext, useState, useEffect } from 'react'
import s from '../styles/StudentProfile.module.scss'
import { CareerCard, ScholarshipCard, FeaturedCard, ReviewCard } from '../Components/Components'
import {Tile} from 'Components/Tile/Tile'
import {AiFillStar} from 'react-icons/ai'
import Card from 'react-bootstrap/Card'
import { SessionContext, isMentee, isEmployer, isLoggedIn } from 'Utils/sessions';
import avi from 'Static/default-avi.png'
import {Root} from 'Root'
import {countries} from 'Utils/regions'
import { majors } from 'Utils/majors';
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import backgroundImg from 'Static/backgroundImg.png'
import SideBar from 'Components/Nav/SideBar';
import {useRouteMatch} from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'
import { ids } from 'Utils/idToCollege'
import { ScheduleMeeting } from '../../Discover/Components/BookMeeting'
import { InviteApply, ReviewContractor } from '../../Company/components/Component'
import { Message } from '../Components/Message'
import { Link } from 'react-router-dom'
import { ConnectionRequest } from '../Components/ConnectionRequest'

const MentorAboutMe = ({session, profile, id}) => {
    const [show, setShow] = useState(false)
    const [skills, setSkills] = useState([])
    const [interests, setInterests] = useState([])

    useEffect(()=> {
        Root.userApi.getAttribute(id, "skills")
        .then(res => setSkills(res.data)).catch(
          (err)=> console.log(err))
    },[id])

    useEffect(()=> {
        Root.userApi.getAttribute(id, "interests")
        .then(res => setInterests(res.data)).catch(
          (err)=> console.log(err))
    },[id])

    var stars = []
    for (let i = 0; i < profile.rating; i++) {
        stars.push(<AiFillStar color='orange'/>);
    }


    return <>
    <Card className={`xlCard`}>
    <Card.Img className={s.cardImg} variant="top" src={backgroundImg} />
    <Card.Body>
      <div className={s.avi} style={{backgroundImage: `url(${profile.photo || avi})`, backgroundRepeat:'no-repeat', backgroundSize:'cover'}} />
      {profile && <> <div className='d-flex justify-content-between align-items-center'>
       <Card.Title className='mt-3'>{profile.firstName} {profile.lastName}</Card.Title>
        <div className='d-flex justify-content-between'>
          {stars.map((s, idx) => {return s })}
          <span style={{fontSize:'12px'}}>({profile.rating})</span>
        </div>
      </div>
      <p className='trunc blue'>{profile.city && !profile.city.includes('N/A') && `${profile.city}, `}
      {profile.country ==='US' && profile.state !== 'N/A' && profile.state}
      {profile.country !== 'US' && countries[profile.country]}</p>

      <div className='btnGroup'>
        {profile.userId !== session.id && <ConnectionRequest respondentId={profile.userId}/>}
      </div>
      {isLoggedIn() && profile.userId !== session.id && <Message recipientId={profile.userId}/>}


      <div style={{marginTop: "20px"}}>
      <Card.Text>
          <span className='d-block' ><strong>About Me</strong></span>
          <span className='text-muted fs-13'>{profile.bio || '-'}</span>
      </Card.Text>

      </div>
    </>}
      <Card.Text>
          <span className='d-block'><strong>Skills</strong></span>
          <span className='mt-2 d-block'>{ skills.map((skill, idx) => {
         return <Tile className='me-2 fs-13' color='purple'>{skill.skill} {' '}</Tile>})}</span>
        {skills.length === 0 && <p className='text-muted fs-13'>-</p>}
      </Card.Text>
      <Card.Text>
          <span className='d-block'><strong>Interests</strong></span>
          <span className='mt-2 d-block'>{ interests.map((interest, idx) => {
         return <Tile className='me-2 fs-13' color='green'>{interest.interest} {' '}</Tile>})}</span>
         {interests.length === 0 && <p className='text-muted fs-13'>-</p>}
      </Card.Text>
      <Card.Text>
          <span className='d-block'><strong>School</strong></span>
          <span>{ids[profile.college]}</span>
          <span style={{fontSize:'13px'}} className='text-muted d-block'>{majors[profile.major]} {profile.gpa && `(${profile.gpa} GPA)`}</span>
      </Card.Text>
      {isMentee(session) && <Button onClick={()=>setShow(true)} className='btnFill wide mt-2'>Book Now</Button>}
      {profile.userId === session.id && <Button type='link' className='btnOutline wide' href='/profile/student/edit'>Edit Profile</Button>}
      {isEmployer(session) && <InviteApply session={session} studentId={profile.userId}/>}
      {isEmployer(session) && <ReviewContractor session={session} studentId={profile.userId}/>}
    </Card.Body>
  </Card>

    <Modal show={show} onHide={()=> setShow(false)}>
        <Modal.Body>
            <ScheduleMeeting student={profile} ipedsid={profile.college} session={session} isInterview={false}/>
        </Modal.Body>
    </Modal>
  </>
  };

const MentorProfile = () => {
const {session, refreshSession}  = useContext(SessionContext)
const [profile, setProfile] = useState({});
const match = useRouteMatch();
const userNameOrId = match.params.userName;
const [id, setId] = useState(null)
const [jobs, setJobs] = useState(null)
const [experiences, setExperiences] = useState(null)
const [featured, setFeatured] = useState(null)
const [awards, setAwards] = useState(null)
const [awardError, setAwardError] = useState(null)
const [featuredError, setFeaturedError] = useState(null)
const [jobError, setJobError] = useState(null)
const [reviews, setReviews] = useState(null)
const [reviewsError, setReviewsError] = useState(null)
const [experienceError, setExperiencesError] = useState(null)

useEffect(()=> {
  if (id == null) {
    return
  }
    Root.userApi.getAttribute(id, "featured_work")
    .then(res => setFeatured(res.data)).catch(
      ()=> {setFeatured([]); setFeaturedError('Error, please try again later')})
},[id, setId])

useEffect(()=> {
  Root.userApi.getAttribute(id, "job_positions")
  .then(res => setJobs(res.data)).catch(
    ()=> {setJobs([]); setJobError('Error, please try again later')})

},[id, setId])

useEffect(()=> {
  if (id == null) {
    return
  }
    Root.userApi.getAttribute(id, "experiences")
    .then(res => setExperiences(res.data)).catch(
        ()=> {setExperiences([]); setExperiencesError('Error, please try again later')})
},[id, setId])

useEffect(()=> {
  if (id == null) {
    return
  }
    Root.userApi.getAttribute(id, "accomplishments")
    .then(res => setAwards(res.data)).catch(
        ()=> {setAwards([]); setAwardError('Error, please try again later')})
},[id, setId])

useEffect(()=>{
  if (id == null) {
    return
  }
    Root.careerApi.getReviewsByContractor(id).then(res => setReviews(res.data)).catch(
        ()=> {setReviews([]); setReviewsError('Error, please try again later')})
},[id, setId])

useEffect(()=>{
  if (isNaN(userNameOrId)) {
    Root.userApi.getUserProfileByUserName(userNameOrId).then((res) => {
      setProfile(res.data)
      setId(profile.userId)
    }).catch(() => {
      setProfile({})
    })
  } else {
    Root.userApi.getUserProfile(userNameOrId).then((res) => {
      setProfile(res.data)
      setId(profile.userId)
    }).catch(() => {
      setProfile({})
    })
  }
},[id, setId])

  return <div className={s.mentorProfile}>

    <SideBar title={"Profile"} session={session}>
      {id ?
        <>
        <div  className={s.profileGrid}>
            <div className={s.col}>
                <div>
                    <h3 className='heading'><strong>Information</strong></h3>
                    <MentorAboutMe session={session} profile={profile} id={id}/>
                </div>
            {profile.resume && (profile.userId === session.id || isEmployer(session)) && <>
                <div className='mt-4'>
                    <h3 className='heading'><strong>Resume</strong></h3>
                     <Tile className='xlCard' color={'purple'} size={'lg'}>
                      { profile.resume ?
                       <a className='btnLink fs-14' target="_blank" style={{color:'rgb(118, 120, 237)'}} href={profile.resume}>{profile.firstName}'s Resume</a> : <>
                        {profile.userId === session.id ? <a className='btnLink fs-14' style={{color:'rgb(118, 120, 237)'}} href={"/profile/student/edit"}>Upload Resume</a>
                        : <span className='fs-14' style={{color:'rgb(118, 120, 237)'}}>No resume</span>}
                      </>}

                    </Tile>
                </div>
                </>}
            </div>
            <div className={s.col}>
                <div>
                    <h3 className='heading'><strong>Career History</strong></h3>
                    <div className={s.gridTwoCol}>
                        {jobs ? jobs.map((j)=> {return <CareerCard type='job_positions' job={j}/>})
                            : <Spinner style={{color:"rgb(16, 142, 115)"}} animation="border" role="status"/>}
                        {jobs && jobs.length === 0 && ! jobError && <p className='text-muted fs-13'>No experiences</p>}
                    </div>
                </div>
                <div className='mt-5'>
                    <h3 className='heading'><strong>Accomplishments</strong></h3>
                    <div className={s.gridTwoCol}>
                    {awards ? awards.map((a)=> {return <ScholarshipCard award={a}/>})
                            : <Spinner style={{color:"rgb(16, 142, 115)"}} animation="border" role="status"/>}
                    {awards && awards.length === 0 && ! awardError && <p className='text-muted fs-13'>No accomplishments</p>}
                    </div>
                </div>
                <div className='mt-5'>
                    <h3 className='heading'><strong>Extracurriculars</strong></h3>
                    <div className={s.gridTwoCol}>
                        {experiences ? experiences.map((e)=> {return <CareerCard type='experiences' job={e}/>})
                            : <Spinner style={{color:"rgb(16, 142, 115)"}} animation="border" role="status"/>}
                        {experiences && experiences.length === 0 && ! experienceError && <p className='text-muted fs-13'>No more experiences</p>}
                    </div>
                </div>
                <div className='mt-5'>
                    <h3 className='heading'><strong>Featured Work</strong></h3>
                    <div className={s.gridTwoCol}>
                        {featured ? featured.map((f)=> {return <FeaturedCard type='featured' work={f}/>})
                            : <Spinner style={{color:"rgb(16, 142, 115)"}} animation="border" role="status"/>}
                        {featured && featured.length === 0 && ! featuredError && <p className='text-muted fs-13'>No featured work</p>}
                    </div>
                </div>
               {(isEmployer(session) || profile.userId === session.id) && <div className='mt-5'>
                    <h3 className='heading'><strong>Reviews</strong></h3>
                    <div className={s.gridTwoCol}>
                        {reviews ? reviews.map((r)=> {return <ReviewCard review={r}/>})
                            : <Spinner style={{color:"rgb(16, 142, 115)"}} animation="border" role="status"/>}
                        {reviews && reviews.length === 0 && ! reviewsError && <p className='text-muted fs-13'>No professional reviews</p>}
                    </div>
                </div>}
        </div>
    </div>
        </>
      : <Spinner style={{color:"rgb(16, 142, 115)"}} animation="border" role="status"/>}
    </SideBar>
</div>
}

export default (MentorProfile);
