import React from 'react';
import { NavBar } from 'Components/Nav/NavBar';
import s from './styles/HowItWorks.module.scss';
import Footer from './Footer';
import Button from 'react-bootstrap/Button';
import money_icon from './static/money_icon.svg'
import star_icon from './static/star_icon.svg'
import lightbulb_icon from './static/lightbulb_icon.svg'

const Applicant = () => (
  <>
  <NavBar style={{backgroundColor:'#82DBC8'}}/>
  <div className={`${s.howItWorks} ${s.applicant}`}>
    <div className={s.sectionOne}>
      <div className={s.grid}>
        <div>
          <h1>
            <strong>How NXSTEP can help you choose the right college</strong>
          </h1>
          <h2 className={`${s.subtitle} my-4`}>Don’t know where to start looking or just trying to narrow down your college list/make a decision? Don’t stress- we got you!</h2>
          <Button className='btnFill dark lg' href='/register'>Join the NXSTEP Community</Button>
        </div>
        <img src={"https://cdn.nxstep.co/landing-student-graphic.svg"}/>
      </div>
    </div>

  <div className={s.sectionTwo}>
        <div className={s.container}>
            <h2 className='text-center'>Why NXSTEP</h2>
            <h3 className='text-center'>Offering you a personalized and innovative college exploration experience</h3>
            <div className={s.grid}>
                <div className='mb-4'>
                    <img src={money_icon}/>
                    <h4 className='text-center fs-20'><strong>Most Reliable Source</strong></h4>
                    <p className='text-center fs-14'>It’s impossible to gauge what a school is really like through a quick google search. Luckily, algorithmically matched 1-on-1 messaging and meetings allow you to directly contact and learn what a college is really like from someone, like you, who currently goes there.</p>
                </div>
                <div className='mb-4'>
                    <img src={star_icon}/>
                    <h4 className='text-center fs-20'><strong>Curated Content</strong></h4>
                    <p className='text-center fs-14'>With college students having the ability to upload videos, pictures, short blogs and other content highlighting their school, you will be able to gauge different college environments at in a raw  and engaging way.</p>
                </div>
                <div className='mb-4'>
                    <img src={lightbulb_icon}/>
                    <h4 className='text-center fs-20'><strong>Inside Scoop</strong></h4>
                    <p className='text-center fs-14'>NXSTEP allows you to access student profiles at a University and learn a little bit more about them (from the information they’ve made public). This  allows you to gain an idea of the types of students that the University tends to accept.</p>            
                </div>

            </div>
        </div>
    </div>

  <div className={`${s.toggleGrid}`}>
    <div className={s.grid}>
        <span className={s.step}>
          <span>
            <h4><strong>So...what are you waiting for?</strong></h4>
            <p>Trust us, we know how stressful the college search process is. You might be tempted to only appy to schools based off of friends & family recommendations, acceptance rates or what sounds “familiar” but we can assure you that this not the way to go! No amount of prestige is worth feeling like you don’t belong.</p>
            <p>Let us help you find the school that’s right for you, not someone else. </p>
          </span>
        </span>
        <span><img src="https://cdn.nxstep.co/landing_how_it_works_mockup.svg"/></span>
    </div>
    <div className={s.grid}>
        <span className={`${s.stagger}`}>
          <span>
            <h4><strong>Pricing</strong></h4>
            <p>Access to college stats and reviews are <strong>FREE</strong>! College applicants pay only for their meetings with college students.</p> 
            <p>30-minute meetings are $9 while 1-hour meetings are $15. (Relatively cheap compared to the thousands of dollars in school transfer costs upon making the wrong college decision).</p>
          </span>
        </span>
        <span><img src="https://cdn.nxstep.co/landing_college_stat_mockup.svg"/></span>
    </div>
    </div>

    <div className={`${s.preFooter}`}>
      <h2 className='text-center mb-2'>Want to see more?</h2>
      <Button className='btnOutline orange lg d-block mx-auto' onClick={()=> window.location.href='/register'}>Join the NXSTEP Community</Button>
    </div>

    </div>
    <Footer />
</>
);

export default (Applicant);
