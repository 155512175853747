import React, {useContext, useState, useEffect} from 'react'
import { SessionContext } from 'Utils/sessions';
import SideBar from 'Components/Nav/SideBar';
import { Root } from 'Root';
import { StudentCard } from './components/Component';
import { CustomPagination } from 'Components/Pagination';
import Button from 'react-bootstrap/esm/Button';
import { FaFilter } from 'react-icons/fa';
import Modal from 'react-bootstrap/Modal'
import {SelectInput, TextInput } from 'Input/Input';
import Spinner from 'react-bootstrap/esm/Spinner';
import { ids } from 'Utils/idToCollege'
import { majors } from 'Utils/majors';

const Students = () => {
  const {session, refreshSession} =  useContext(SessionContext);
  const [selected, setSelected] = useState(1)
  const [show, setShow] = useState(false)
  const [error, setError] = useState(null)
  const [students, setStudents] = useState(null)
  const [studentsCount, setStudentsCount] = useState(100)
  const [limit, setLimit] = useState(200)
  const [request, setRequest] = useState({})

  const itemsPerPage = 20;
  const gpaOptions = {
    2: "2.0+",
    2.5: "2.5+", 
    3: "3.0+", 
    3.5 : "3.5+",
    4 : "4.0+" 
  }

  const gradYearOptions = {
    2020 : 2020,
    2021 : 2021,
    2022 : 2022,
    2023 : 2023,
    2024 : 2024,
    2025 : 2025,
    2026 : 2026,
    2027 : 2027,
    2028 : 2028,
    2029 : 2029,
    2030 : 2030,
  }

 function getTalent(params) {
     setStudents(null)
     setError(null)
    Root.careerApi.getTalent(params).then((res)=> {  
        setStudents(res.data) 
    }).catch(()=> {
        setStudents([])
        setError("Error getting search results, please try again later")})


    Root.careerApi.getTalentCount(params).then((res)=> {  
        setStudentsCount(res.data) 
    }).catch(()=> {
        setStudentsCount(100)
    })
 }

    useEffect(()=>{
        getTalent({limit:limit, page: selected, perPage:itemsPerPage})
    },[])

    useEffect(()=>{
        handleApply()
    },[selected])

    function handleChange(event) {
        const { name, value } = event.target;
        setRequest((prevState) => ({ ...prevState, [name]: value }));
    }


    function handleApply() {
        const params = {limit: limit, page: selected, perPage:itemsPerPage}
        if(request.major) {
            params.major = request.major
        }
        if(request.college) {
            params.college = request.college
        }
        if(request.gpa) {
            params.gpa = request.gpa
        }
        if(request.gradYear) {
            params.gradYear = request.gradYear
        }
        if(request.interests) {
            params.interests = request.interests
        }
        if(request.skills) {
            params.skills = request.skills
        }
        getTalent(params)
    }
    

  return <div>
   <SideBar session={session} title={"Browse Students"} type={"company"}>
       <Button className='btnLink' onClick={()=> setShow(true)}><FaFilter className='me-1'/>Filter Search Results</Button>
       {error && <p className='form-error'>{error}</p>}
       {students ? <> 
            <div className={'responsiveGrid'}>
                {students.map((s) => <StudentCard student={s}/>)}
            </div>
            {studentsCount > itemsPerPage && <CustomPagination setSelected={setSelected} selected={selected} numPages={Math.min(Math.ceil(studentsCount/itemsPerPage), Math.ceil(limit/itemsPerPage))}/>}
        </> : <Spinner style={{color:"rgb(16, 142, 115)"}}  className='d-block' animation="border" role="status"/> }
        <Modal show={show} onHide={()=> setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Filter Search Results
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <SelectInput ordered={'true'} containerClassName='mb-3' options={ids} errors={{}} name={"college"} value={request.college || ''} onChange={handleChange} label="College" placeholder={'Any'}/>
                <SelectInput ordered={'true'} containerClassName='mb-3' options={majors} errors={{}} name={"major"} value={request.major || ''} onChange={handleChange} label="Major" placeholder={'Any'}/>
                <SelectInput containerClassName='mb-3' options={gpaOptions} errors={{}} name={"gpa"} value={request.gpa || ''} onChange={handleChange} label="GPA" placeholder={'Any'}/>
                <SelectInput containerClassName='mb-3' options={gradYearOptions} errors={{}} name={"gradYear"} value={request.gradYear || ''} onChange={handleChange} label="Graduation Year" placeholder={'Any'}/>
                <TextInput containerClassName='mb-3' options={gradYearOptions} errors={{}} name={"skills"} value={request.skills || ''} onChange={handleChange} label="Skills (Enter as comma separated list)" placeholder={'Skill #1, Skill #2...'}/>
                <TextInput options={gradYearOptions} errors={{}} name={"interests"} value={request.interests || ''} onChange={handleChange} label="Interests (Enter as comma separated list)" placeholder={'Interest #1, Interest #2...'}/>
            </Modal.Body>
            <Modal.Footer className='justify-content-between'>
                <Button onClick={()=> { setRequest({}); getTalent({limit:limit, page: selected, perPage:itemsPerPage})}} className='btnOutline md'>Clear Filters</Button>
                <Button onClick={handleApply} className='btnFill md'>Apply Filters</Button>
            </Modal.Footer>
        </Modal>
   </SideBar>
</div>
}

export default (Students);
