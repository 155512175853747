import React, { useState } from 'react';
import { NavBar } from 'Components/Nav/NavBar';
import Button from 'react-bootstrap/Button';
import { TextInput } from 'Input/Input';
import { validateEmail, push, validatePassword } from 'Utils/validation';
import { Root } from 'Root';
import s from './styles/Login.module.scss';
import Form from 'react-bootstrap/Form'
import { Link } from 'react-router-dom'
import { FaCheckCircle } from 'react-icons/fa'

const Recover = () => {
  const [request, setRequest] = useState({})
  const [idx, setIdx] = useState(0)
  const [formErrors, setFormErrors] = useState({})
  const [errorMessage, setErrorMessage] = useState(null)

  function handleSendCode () {
    setFormErrors({})
    const errors = validate(request)
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors)
      return
    }
    Root.userApi.sendPasswordRecoveryCode(request.email).then(res => {
      if (res.status === 200) {
        setIdx(1)
      } else {
        setErrorMessage('Error sending code, please try again.')
      }
    }).catch(function (err) {
      err.response ? setErrorMessage(err.response.data) : setErrorMessage('Error sending code, please contact support.')
    })
  }

  function handleValidateCode () {
    setFormErrors({})
    const errors = validate(request)
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors)
      return
    }
    Root.userApi.validatePasswordRecoveryCode(request.email, request.code).then(res => {
      setIdx(2)
    }).catch(function (err) {
      setErrorMessage('Unable to validate code')
    })
  }

  function handleResetPassword () {
    setFormErrors({})
    const errors = validate(request)
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors)
      return
    }
    Root.userApi.setNewPasswordRecovery(request.email, request.password).then(res => {
      if (res.status === 200) {
        setIdx(3)
        setRequest({})
      } else {
        setErrorMessage('Error resetting password, please try again.')
      }
    }).catch(function (err) {
      setErrorMessage('Unable to reset password')
    })
  }

  function validate (request) {
    var errors = {}

    if (idx === 0 && !validateEmail(request.email)) {
      push(errors, 'email', 'Valid email is required ')
    }

    if (idx === 1 && !request.code) {
      push(errors, 'code', 'Recovery code is required ')
    }

    if (idx === 2 && !request.password) {
      push(errors, 'password', 'Password is required ')
    }

    if (idx === 2 && request.password && !validatePassword(request.password)) {
      push(errors, 'password', 'Password must be at least 8 digits ')
    }

    if (idx === 2 && request.password && (request.password !== request.confirmPassword)) {
      push(errors, 'confirmPassword', 'Passwords do not match ')
    }

    return errors
  }

  function handleChange (event) {
    var { name, value } = event.target
    if (name === 'email') {
      value = value.trim()
    }
    setRequest(prevState => ({ ...prevState, [name]: value }))
  }

  return <div className={s.login}>
      <NavBar />
      <div className={s.form}>
      <h2>Reset Password</h2>
      {idx === 0 && <>
        <p>A recovery code will be sent your email address if you have an existing account</p>
        <Form>
          <TextInput containerClassName='mb-3' errors={formErrors} name={'email'} value={request.email || ''} onChange={handleChange} label={'Email'}/>
            <Button onClick={handleSendCode} className={`btnFill wide md`}>Send Recovery Code</Button>
        </Form>
      </>
      }
      {idx === 1 &&
        <Form>
          <p>Enter the 6 digit code sent to your email</p>
          <TextInput containerClassName='mb-3' errors={formErrors} name={'code'} value={request.code || ''} onChange={handleChange} placeholder={'Recovery Code'}/>
          <Button onClick={handleValidateCode} className={`btnFill wide md`}>Validate Code</Button>
        </Form>
      }
      {(idx === 2 || idx === 3) &&
        <Form>
          <p>Enter your new password. Password must be at least 8 characters.</p>
          <TextInput containerClassName='mb-3' errors={formErrors} name={'password'} value={request.password || ''} onChange={handleChange} placeholder={'New Password'} type={'password'}/>
          <TextInput containerClassName='mb-3' errors={formErrors} name={'confirmPassword'} value={request.confirmPassword || ''} onChange={handleChange} placeholder={'Confirm Password'} type={'password'}/>
          <Button onClick={handleResetPassword} className={`btnFill wide mb-2 md`}>Reset Password</Button>
        </Form>
      }
      {errorMessage && <p className={`form-error`}>{errorMessage}</p>}
      {idx === 3 &&
              <p className='text-center'><FaCheckCircle size="30" className={'green me-2'}/>Your password has been reset
                <Link className='btnLink' to="/login"> Log In</Link>
              </p>
      }
      {idx !== 3 && <div className={"d-flex justify-content-center mt-3"}>
        Already have an account?
        <Link className="ms-2 btnLink" to="/login"> Log In</Link>
      </div>}
  </div>
</div>
};

export default (Recover);
