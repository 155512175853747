import React, { useState, useContext, useEffect } from 'react'
import styles from './styles/Ethically.module.scss'
import { Link, useLocation } from 'react-router-dom'
import { TextInput, SelectInput, ScaleInput } from 'Input/Input'
import Button from 'react-bootstrap/Button'
import { IoMdArrowForward, IoMdArrowBack } from 'react-icons/io'
import { questions } from 'Utils/questions'
import logo from 'Static/Logo/nxstep-white.svg';
import ethicallyLogo from 'Static/ethically_logo.png';
import { Root } from 'Root'
import { SessionContext } from 'Utils/sessions';

const Ethically = (props) => {
  const [personality, setPersonality] = useState({})
  const [basic, setBasic] = useState({})
  const [ipAddress, setIpAddress] = useState({})
  const [num, setNum] = useState(0)
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(false)
  const {session, refreshSession} =  useContext(SessionContext);
  const useQuery = () => { return new URLSearchParams(useLocation().search) }
  const reference = useQuery().get('reference')

  function redirect() {
    window.location.href = "https://chrome.google.com/webstore/detail/ethically/jhblmggmbbdkhffdkjfolpmpphfnofla";
  }

  useEffect(() => {
    fetch('https://ifconfig.me/all.json')
      .then(res => res.json())
      .then(data => data.ip_addr)
      .then(ip => {
        setIpAddress(ip)
      })
      .catch(err => {
        console.log(err);
        setIpAddress("127.0.0.1");
      });
  }, []);

  useEffect(() => {
    Root.miscApi.logPageLanding("Ethically", reference)
    .then(() => {})
    .catch(() => {})
  }, [reference]);

  function downloadEthically() {
    setError(null)
    Root.miscApi.downloadEthically(reference, ipAddress).then(res => {
      if (res.status === 200) {
        redirect()
      }
    }).catch(error => {
      setError("There was an issue processing the download.")
    })
  }

  function markTaskDone(){
    Root.userApi.getAllNotifications(session.id)
    .then((rsp) => {
      let alerts = rsp.data
      alerts.unread.forEach(alert => {
        if(alert.subject.includes('NXSTEP Profile')){
          Root.userApi.markNotificationRead(session.id, alert.id)
        }
      })
    }).catch((err)=> {
      console.log(err)
    })
  }

  function handleSubmit () {
    const request = {
      userId: session.id,
      studentType: session.studentType,
      basicInformation: { ...basic, college: session.college || null },
      personality: personality
    }

    Root.userApi.createUserProfile(request).then(res => {
        setSuccess(true)
        markTaskDone()
    }).catch(() =>
      setError('Something went wrong, please try again or reach out to administrators if problem persists')
    )
  }

  function handlePersonalityChange (event) {
    const { name, value } = event.target
    setPersonality(prevState => ({ ...prevState, [name]: value }))
  }

  function handleBasicChange (event) {
    const { name, value } = event.target
    setBasic(prevState => ({ ...prevState, [name]: value }))
  }

  function validate (num) {
    if (typeof questions[num] === 'undefined') {
      return false
    }
    var key = questions[num].id
    if (!(key in basic) || key in personality) {
      if (['city', 'state'].includes(key)) {
        basic[key] = 'N/A'
        return true
      }
      return false
    }
    return true
  }

  function isFinalQuestion (num) {
    return num === questions.length - 1
  }

  return   <div className={`${styles.onboarding}`}>
    <Button className={`${styles.exit} btnLink white`} href='/dashboard/student'>Return</Button>
    <div className={styles.content}>
      <div className={styles.question}>
      <div>
      <img className={styles.ethicallyLogo} src={ethicallyLogo}/>
      <h5 className='mb-2 text-center d-green' style={{lineHeight:"30px"}}>Ethically uses hundreds of data sources to help you shop with the brands that give you that warm fuzzy feeling. Say you’re scrolling through a website and click on a product, their chrome extension will instantly provide you with a more ethical, sustainable and environmentally-friendly alternative brand or product. They also present an ‘ESG’ grade for each brand.</h5>
      </div>
     <div >
         <Button className={'btnFill md mt-3 green'} onClick={downloadEthically}>Download Ethically</Button>
     </div>
     <div>
         <p style={{textAlign:"center", paddingTop:"15px", color: "grey", fontSize: "14px"}}>(You will redirected to the Ethically Chrome Page)</p>
     </div>
     {error && <p className='form-error text-center fs-13'>{error}</p>}
        </div>
        </div>
        <img className={styles.logo} src={logo}/>
     </div>
}

export default (Ethically)
