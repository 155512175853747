import React, { useState, useEffect } from 'react';
import { NavBar } from 'Components/Nav/NavBar';
import Button from 'react-bootstrap/Button';
import { TextInput } from 'Input/Input';
import Form from 'react-bootstrap/Form'
import { validateEmail, push } from 'Utils/validation';
import { Root } from 'Root';
import s from './styles/Login.module.scss';
import {Tile} from 'Components/Tile/Tile'

const Contact = () => {
    const [request, setRequest] = useState({})
    const [formErrors, setFormErrors] = useState({})
    const [formResponse, setFormResponse] = useState(null)
    const [formResponseError, setFormResponseError] = useState(null)

    function handleChange (event) {
      const { name, value } = event.target
        setRequest(prevState => ({ ...prevState, [name]: value }))
    }
  
      function validate (request) {
        var errors = {}
         if(!request.email || !validateEmail(request.email)) {
            push(errors, 'email', 'Valid email is required ')
          }
          if (!request.firstName) {
            push(errors, 'firstName', 'First name is required')
          }
          if (!request.subject) {
            push(errors, 'subject', 'Subject is required ')
          }
          if ( !request.message) {
            push(errors, 'message', 'Message is required ')
          }
        return errors
      }
  
      function handleSubmit () { 
        setFormErrors({});
        setFormResponse(null);
        setFormResponseError(null);
        const errors = validate(request)
            if (Object.keys(errors).length > 0) {
            setFormErrors(errors)
            return
        }
          if(Object.keys(request).length > 0){
            Root.miscApi.contactSupport(request.email, request.firstName, request.subject, request.message).then(() => {
                setFormResponse("Message sent");
                setRequest({});
                setFormErrors({});
            }).catch(() =>
            setFormResponseError("Something went wrong, please try again later")
          )
        }
      }

  return (
    <>
       <NavBar dark={true}/>
      <div className={s.login}>
        <div className={s.form}>
          <h2>Contact Us</h2>
          <p className='text-muted mb-4'> We will get back to you ASAP. You may also contact us at support@nxstep.co</p>
            <Form>
                <TextInput containerClassName='mb-3' errors={formErrors} name={'firstName'} value={request.firstName || ''} onChange={handleChange} type={'text'} placeholder={"First Name"}/>
                <TextInput containerClassName='mb-3' errors={formErrors} name={'email'} value={request.email || ''} onChange={handleChange} type={'text'} placeholder={"Email"}/>
                <TextInput containerClassName='mb-3' errors={formErrors} name={'subject'} value={request.subject || ''} onChange={handleChange} type={'text'} placeholder={"Subject"}/>
                <Form.Group>
                    <Form.Control placeholder={'Message (600 characters)'} as="textarea" rows="3"  maxLength="600" name={'message'} value={request.message || ''} onChange={handleChange}/>
                    <div className={'form-error'}>{formErrors.message || null}</div>
                </Form.Group>
                <Button className={'btnFill md wide mt-3'} onClick={handleSubmit}>Submit</Button>    
            </Form>
            <p className='form-error text-center mt-2'>{formResponseError}</p>
            {formResponse && <p style={{textAlign:"center", marginTop:"1em"}}><Tile color='purple'>{formResponse}</Tile></p>}
        </div>
      </div>
    </>
  );
};

export default (Contact);
