import * as Cookies from "js-cookie";
import React from 'react'

// max cookie size is 4096 bytes so keep this to a min
export const setSessionCookie = (session) => {
  const truncSession = {
    id: session.id,
    userId: session.userId,
    profile: session.profile,
    photo: session.photo,
    firstName: session.firstName,
    userName: session.userName,
    lastName: session.lastName,
    studentType: session.studentType,
    college: session.college,
    organization: session.organization,
    email: session.email,
    dateOfBirth: session.dateOfBirth,
    phone: session.phone,
    companyId: session.companyId,
    referralLink: session.referralLink
  }
  Cookies.set("session", truncSession, { expires: 14 });
  return
};

export const updateSessionCookie = (session) => {
  // Cookies.remove("session");
  const truncSession = {
    id: session.id,
    userId: session.userId,
    profile: session.profile,
    photo: session.photo,
    firstName: session.firstName,
    userName: session.userName,
    lastName: session.lastName,
    studentType: session.studentType,
    college: session.college,
    organization: session.organization,
    email: session.email,
    dateOfBirth: session.dateOfBirth,
    phone: session.phone,
    companyId: session.companyId,
    referralLink: session.referralLink
  }
  Cookies.set("session", truncSession, { expires: 14 });
  console.log(getSessionCookie())
 return
};

export const clearSessionCookie = () => {
    Cookies.remove("session");
};

export const isMentor = (session) => {
  return session.studentType === "CURRENT"
};

export const isMentee = (session) => {
    return session.studentType === "PROSPECTIVE"
};

export const isStudent = (session) => {
  return isMentor(session) || isMentee(session)
}

export const isPartner = (session) => {
  return session.organization && session.studentType !== "PROSPECTIVE" && session.studentType !== "CURRENT"
};

export const isEmployer = (session) => {
  return session.companyId && session.studentType == "EMPLOYEE" && session.studentType !== "PROSPECTIVE" && session.studentType !== "CURRENT" && !session.organization
};

export const isLoggedIn = () => {
  const sessionCookie = Cookies.get("session");
  if (sessionCookie === undefined || sessionCookie === 'undefined' || sessionCookie === {}) {
    return false;
  }
  return true
}

export const getSessionCookie = () => {
  const sessionCookie = Cookies.get("session");
  if (sessionCookie === undefined || sessionCookie === 'undefined') {
    return {};
  } else {
    return JSON.parse(sessionCookie);
  }
};

export const SessionContext = React.createContext({
  session: getSessionCookie(),
  refreshSession:  ()=>{}
});
