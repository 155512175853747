import React from 'react';
import logo from 'Static/Logo/logo-white.svg';
import {SearchBar} from 'Components/Input/Search';
import Button from 'react-bootstrap/Button';
import s from './styles/Footer.module.scss';
import iphone from './static/iphone_mockup.svg'
import {AiFillFacebook, AiFillInstagram, AiFillLinkedin } from 'react-icons/ai'

const Footer = () => (
  <>
    <div className={s.footer}>

        <div className={`${s.grid}`}>
          <div>
            <span>
              <img
                alt=""
                src={logo}
                height="25"
                className="d-inline-block align-center"
              />
            </span>
            <p className="mt-4">Redefining college exploration through 1-on-1 live info sessions for college applicants.</p>
          </div>
          {/* <div>
            <p className={s.header}>For Students and Parents</p>
            <Button variant="link">Colleges</Button>
            <Button variant="link">Colleges By State</Button>
            <Button variant="link">College Discovery Center</Button>
            <Button variant="link">Test Prep</Button>
          </div> */}
          <div>
            <Button style={{color:'white'}} variant="link" className='d-block text-start fs-13' href="/about">About Us</Button>
            <Button style={{color:'white'}} variant="link" className='d-block text-start fs-13' href='/contact'>Contact Us</Button>
            <Button style={{color:'white'}} variant="link" className='d-block text-start fs-13' href="/blog">Blog</Button>
            <Button style={{color:'white'}} variant="link" className='d-block text-start fs-13' href="/jobs?pg=1">Jobs</Button>
            <Button style={{color:'white'}} variant="link" className='d-block text-start fs-13' href="/tos">Terms and Conditions</Button>
            <Button style={{color:'white'}} variant="link" className='d-block text-start fs-13' href="/privacy">Privacy Policy</Button>
          </div>
          <div>
            <SearchBar color="orange" text="Subscribe" placeholder="Email" />
            <div className="d-flex mt-2">
              <Button style={{color:'white'}} variant="link" href='https://www.linkedin.com/company/nxstepco/'><AiFillLinkedin size='23'/></Button>
              <Button style={{color:'white'}} variant="link" href="https://www.instagram.com/nxstepco"><AiFillInstagram size='23'/></Button>
              <Button style={{color:'white'}} variant="link" href="https://www.facebook.com/nxstepco"><AiFillFacebook size='23'/></Button>
            </div>
          </div>
        </div>
      </div>
  </>
);

export default (Footer);
