import React, { useState, useEffect } from 'react'
import styles from './styles/Lightbox.module.scss'
import Button from 'react-bootstrap/esm/Button';
import Modal from 'react-bootstrap/Modal'
import {FaChevronLeft, FaChevronRight} from 'react-icons/fa'
import {Tile} from 'Components/Tile/Tile'
import {Root} from 'Root'
import { deleteImageCDN } from 'Utils/media'
import { isEmployer } from 'Utils/sessions'

export const Lightbox = ({idx, contentList, onHide, setIdx, manage, session, refreshContent}) => {
    const isVideo = (content) => {return content.type === ("VIDEO")} 
    const [content, setContent] = useState(null)
    const [error, setError] = useState(null)

    useEffect(()=> {
        if(idx != null) {
            setContent(contentList[idx])
        }
    },[idx])

    const left = () => {
        setError(null)
        if(idx-1 >= 0){
            setIdx(idx-1)
        }
    }
    // right key press
    const right = () => {
        setError(null)
        if(idx+1 < contentList.length){
            setIdx(idx+1)
        }
    }

    function handleDelete() {
        setError(null)
        var toDelete = content.url.split('/')[2]
        deleteImageCDN(toDelete)
        Root.userApi.deleteUserContent(session.id, content.id)
        .then(() => {refreshContent(); onHide();}).catch((err) => setError('Unable to delete content, please try again'))
    }

    return <Modal className={styles.modal} style={{backgroundColor:'transparent', display:"flex", alignItems:'center'}}
     onHide={() => { setError(null); onHide();}} show={idx !=null}>
            <Modal.Header className='justify-content-between' closeButton>
                {manage && session && <Button onClick={handleDelete} className='btnOutline red'> Delete</Button>}
            </Modal.Header>
            <Modal.Body>
                <div className={styles.container}>
                    {content && isVideo(content) ? 
                        <video  alt={"User content"} src={content && content.url} controls/> :
                        <img alt={"User content"} src={content && content.url} ></img>
                        }
                </div>
                {content && content.caption && <p style={{margin:'1em'}}>{content.caption}</p>}
                {content && manage && <Tile className='fs-13' color='purple'>{content.approved ? 'Approved' : 'Not Approved'}</Tile>}
                {error && <p className='form-error text-center'>{error}</p>}
            </Modal.Body>
        <Modal.Footer className={styles.footer} style={{dispay:'flex', justifyContent:'space-between'}}>
            <Button className={'btnLink'} onClick={left}><FaChevronLeft onClick={left}/></Button>
                <p>{idx+1}/{contentList.length}</p>
            <Button className={'btnLink'} onClick={right}><FaChevronRight onClick={right} /></Button>
        </Modal.Footer>
    </Modal>
}

// We have a separate one for the College Image lightbox because the above one was acting weird
// when trying to set state of parent from child. not spending the time to figure it out
export const CollegeImageLighbox = ({contentList, ...props}) => {
    const [show, setShow] = useState(null)
    const [idx, setIdx] = useState(0)
    const [error, setError] = useState(null)

    const left = () => {
        if(idx-1 >= 0){
            setIdx(idx-1)
        }
    }
    // right key press
    const right = () => {
        if(idx+1 < contentList.length){
            setIdx(idx+1)
        }
    }

    return <><Modal className={styles.modal} style={{backgroundColor:'transparent', display:"flex", alignItems:'center'}} 
    onHide={()=> setShow(false)} show={show}>
        <Modal.Header closeButton/>
            <div className={styles.container}>
                {contentList && contentList.length > 0 && <img alt={"User content"} src={contentList[idx]} ></img>}
            </div>
        <Modal.Footer className={styles.footer} style={{dispay:'flex', justifyContent:'space-between'}}>
            <Button className={'btnLink'} onClick={left}><FaChevronLeft onClick={left}/></Button>
                <p>{contentList.length === 0 ? 0 : idx+1}/{contentList.length}</p>
            <Button className={'btnLink'} onClick={right}><FaChevronRight onClick={right} /></Button>
        </Modal.Footer>
    </Modal>
    <div onClick={()=>setShow(true)}>
        {props.children}
    </div>
    </>
}

// We have a separate one for the College Image lightbox because the above one was acting weird
// when trying to set state of parent from child. not spending the time to figure it out
export const CompanyImageLightbox = ({session, contentList, companyId, getCompany, ...props}) => {
    const [show, setShow] = useState(null)
    const [idx, setIdx] = useState(0)
    const [error, setError] = useState(null)

    const left = () => {
        if(idx-1 >= 0){
            setIdx(idx-1)
        }
    }
    // right key press
    const right = () => {
        if(idx+1 < contentList.length){
            setIdx(idx+1)
        }
    }

    function handleDelete() {
        setError(null)
        var toDelete = contentList[idx].url.split('/')[2]
        deleteImageCDN(toDelete)
        Root.careerApi.deleteCompanyMedia(companyId, contentList[idx].id)
        .then(() => {getCompany();}).catch((err) => setError('Unable to delete content, please try again'))
    }

    return <><Modal className={styles.modal} style={{backgroundColor:'transparent', display:"flex", alignItems:'center'}} 
    onHide={()=> setShow(false)} show={show}>
            <Modal.Header className='justify-content-between' closeButton>
                {session && companyId && isEmployer(session) && session.companyId == companyId && <Button onClick={handleDelete} className='btnOutline red'> Delete</Button>}
            </Modal.Header>
            <Modal.Body>            
                <div className={styles.container}>
            {contentList && contentList.length > 0 && (contentList[idx].mediaType === 'IMAGE' ?
                <img alt={"User content"} src={contentList[idx].url} ></img> :
                <video  alt={"User content"} src={content[idx].url} controls/>
                )}
            </div>
            {error && <p className='form-error text-center'>{error}</p>}
            </Modal.Body>
        <Modal.Footer className={styles.footer} style={{dispay:'flex', justifyContent:'space-between'}}>
            <Button className={'btnLink'} onClick={left}><FaChevronLeft onClick={left}/></Button>
                <p>{contentList.length === 0 ? 0 : idx+1}/{contentList.length}</p>
            <Button className={'btnLink'} onClick={right}><FaChevronRight onClick={right} /></Button>
        </Modal.Footer>
    </Modal>
    <div onClick={()=>setShow(true)}>
        {props.children}
    </div>
    </>
}