import React, {useContext, useState, useEffect} from 'react'
import s from './Jobs.module.scss'
import SideBar from 'Components/Nav/SideBar';
import { SessionContext } from 'Utils/sessions';
import Card from 'react-bootstrap/Card'
import {CompanyImageLightbox} from 'Components/Lightbox'
import Button from 'react-bootstrap/esm/Button';
import { CustomPagination } from 'Components/Pagination';
import {JobCard} from './Jobs'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import Alert from 'react-bootstrap/Alert'
import Form from 'react-bootstrap/Form'
import { useRouteMatch } from 'react-router-dom'
import {Root} from 'Root'
import {Tile} from 'Components/Tile/Tile'
import { push, validateEmail } from 'Utils/validation'
import {TiDelete} from 'react-icons/ti'
import { NoAutoFillTextInput, TextInput, TextAreaInput, SelectInput, FormErrors, DayInput } from 'Input/Input';
import Spinner from 'react-bootstrap/esm/Spinner';
import { FaEdit } from 'react-icons/fa';
import { states, countries } from 'Utils/regions';
import placeholder from 'Static/placeholder.jpeg'
import { urlToHostname } from '../Discover/utils';
import Modal from 'react-bootstrap/Modal';
import { deleteImageCDN, uploadImageCDN, validateFiles, makeId } from 'Utils/media'
import ImgUploader from 'Components/Input/ImgUploader';
import {BsCloudUpload} from 'react-icons/bs'
import {AiOutlineCamera} from 'react-icons/ai'
import { Link } from 'react-router-dom'
import MetaTags from 'react-meta-tags';

const Company = () => {
  const {session, refreshSession} =  useContext(SessionContext);
  const [selected, setSelected] = useState(1)
  const [key, setKey] = useState('about')
  const [company, setCompany] = useState(null)
  const [companyError, setCompanyError] = useState(null)
  const [jobs, setJobs] = useState(null)
  const [jobError, setJobError] = useState(null)
  const [request, setRequest] = useState({})
  const [formErrors, setFormErrors] = useState({})
  const [formResponse, setFormResponse] = useState(null)
  const [formResponseError, setFormResponseError] = useState(null)
  const [codesVisible, setCodesVisible] = useState(false)
  const match = useRouteMatch();
  const companyId = match.params.id;
  const itemsPerPage = 6;

  function handleChange(event) {
    const { name, value } = event.target;
    setRequest((prevState) => ({ ...prevState, [name]: value }));
  }


  function validate (request) {
    var errors = {}
      if (!validateEmail(request.email)) {
        push(errors, 'email', 'You must provide a valid email for your invitation')
      }
    return errors
  }

  function createEmployeeReferral() {
    setFormErrors({});
    setFormResponse(null);
    setFormResponseError(null);
    const errors = validate(request)
        if (Object.keys(errors).length > 0) {
        setFormErrors(errors)
        return
    }
      if(Object.keys(request).length > 0){
        Root.careerApi.referEmployee(companyId, { 'email': request.email, 'employeeId': session.id, 'referral': true}).then((res) => {
          setFormResponse("Success! Your invitation has been sent.")
          setRequest({});
          setFormErrors({});
        }).catch((res) => {
          setFormResponseError("This email is already associated with an existing account.");
        }
      )
    }
  }

  function getJobs() {
    Root.careerApi.getJobsByCompany(companyId).then(res => {
        setJobs(res.data)
        setJobError(null)
    }).catch(() => {
        setJobs({})
        setJobError('Unable to get roles, please try again later')
    });
  }

  function getCompany() {
    Root.careerApi.getCompany(companyId).then(res => {
        setCompany(res.data)
        setCompanyError(null)
    }).catch((err) => {
        setCompany({})
        setCompanyError('Unable to get company info, please try again later')
    });
  }

  function logCompanyView() {
    Root.careerApi.logCompanyView(companyId, {viewerId: session.id})
    .then(res => {}).catch((err) => {});
  }

    useEffect(() => {
        getJobs()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        getCompany()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        logCompanyView()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

  return <div>
  <MetaTags>
    {company && company.name && <title>NXSTEP | {company.name}</title>}
    {company && company.description && <meta name="description" content={company.description} />}
    {company && company.name && <meta property="og:title" content={'NXSTEP | ' + company.name} />}
    {company && company.name && <meta id="og-title" property="og:title" content={'NXSTEP | ' + company.name} />}
    {company && company.logo && <meta property="og:image" content={company.logo} />}
    {company && company.logo && <meta id="og-image" property="og:image" content={company.logo} />}
  </MetaTags>
        <SideBar session={session} title={company && company.name}>
        {companyError && <p className='form-error'>{companyError}</p>}
        {company ? <div className={s.grid}>

            <Card className={`xlCard mb-5`}>
                <Card.Body>
                    <CompanyImageLightbox session={session} contentList={company.media || []} getCompany={getCompany} companyId={company.id}>
                        <div className={s.thumbnailGrid}>
                            <img src={company.media && company.media[0] && company.media[0].url || placeholder} />
                            <img src={company.media && company.media[1] && company.media[1].url || placeholder} />
                            <img src={company.media && company.media[2] && company.media[2].url || placeholder} />
                        </div>
                    </CompanyImageLightbox>
                    <UploadMedia company={company} getCompany={getCompany} session={session}/>
                    <Card.Title className='trunc d-flex align-items-center'>
                        <div className={s.logo} style={{backgroundImage: `url(${company.logo || placeholder})`, backgroundSize:'cover'}} />
                        {company.name}  {(companyId == session.companyId) && <EditCompany company={company} getCompany={getCompany}/>}
                    </Card.Title>
                    <Card.Text className='fs-13'>
                        {/* <span className='d-block green my-1'><AiFillStar/>{' '}({5 || '-'})</span> */}
                        <span className='d-block my-1'>{company.city} {company.city && company.state && ', '} {company.state} {company.country && countries[company.country]}</span>
                        {company.website && <a className='btnLink p-0' href={`https://${urlToHostname(company.website)}`}>{urlToHostname(company.website)}</a>}
                    </Card.Text>

                    <div className='d-flex align-items-center justify-content-between'>
                        <div>
                            <h3 className={'green fs-18'}><strong>{jobs && jobs.length || '-'}</strong></h3>
                            <p className='text-muted fs-13'>Open Roles</p>
                        </div>
                        <div className='ml-3'>
                            <h3 className={'green fs-18'}><strong>{company.yearFounded || '-'}</strong></h3>
                            <p className='text-muted fs-13'>Founded</p>
                        </div>
                        <div className='ml-3'>
                            <h3 className={'green fs-18'}><strong>{company.employeeCount || '-'}</strong></h3>
                            <p className='text-muted fs-13'># Employees</p>
                        </div>
                    </div>
                    <h3 className='green'>Mission</h3>
                    <p className='fs-14'>{company.mission}</p>

                    {(companyId == session.companyId) && <AddJob getJobs={getJobs} companyId={company.id} className='btnFill wide mt-3 md'/>}
                    {(companyId == session.companyId) &&
                    <div style={{paddingTop:"25px"}}>
                    <h3 className='green'>Invite an Employee</h3>
                    <Form>
                        <NoAutoFillTextInput containerClassName='mb-3' errors={formErrors} name={'email'} value={request.email || ''} onChange={handleChange} type={'text'} placeholder={"Employee's Email"}/>
                        <Button className={'btnFill md wide mt-3'} onClick={createEmployeeReferral}>Submit Invitation</Button>
                        <div className={'form-error'}>{formErrors.description || null}</div>
                        <p style={{textAlign:"left", paddingTop:"15px"}}>{formResponse}</p>
                        <div className={'form-error'}>{formResponseError || null}</div>
                    </Form>
                    </div>
                    }
                </Card.Body>
            </Card>

           <div>
            {/* <Tabs className='mb-4 spaced'
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                >
                <Tab eventKey="about" title={<span className='d-flex align-items-center'><span><span className={s.text}>About</span> {' '} <TiDocument size={20} className={`green ml-2`}/></span></span>}> */}
                    <div>
                        <h3 className='heading'>Description {(companyId == session.companyId) && <EditCompany company={company} getCompany={getCompany}/>}</h3>
                    <div className={s.row}>
                        <p>{company.description}</p>
                    </div>

                        <h3 className='heading'>Message from Representative {(companyId == session.companyId) && <EditCompany company={company} getCompany={getCompany}/>}</h3>
                    <div className={s.row}>
                        <p>{company.message}</p>
                    </div>

                        <h3 className='heading'>Roles </h3>
                    {jobError && <p className='form-error'>{jobError}</p>}
                    <div className='responsiveGrid'>
                    {jobs && jobs.slice((selected*itemsPerPage)-itemsPerPage, ((selected-1)*itemsPerPage) + itemsPerPage).map((job, idx) => {
                        return <JobCard job={job} logo={company.logo}/>
                    })}
                    </div>
                    {jobs && jobs.length > itemsPerPage && <CustomPagination setSelected={setSelected} selected={selected} numPages={Math.ceil(jobs.length/itemsPerPage)}/>}

                </div>
{/*
                </Tab>
                <Tab eventKey="photos" title={<span className='d-flex align-items-center'><span><span className={s.text}>Media</span> {' '} <AiOutlineCamera size={20} className={`green ml-2`}/></span></span>}>
                    {company && <>
                        <p className='text-muted fs-13'>{company.media && company.media.length === 0 && 'No media'}</p>
                        {company.media && company.media.map((m, idx) => {
                        return (<div key={idx} >
                                    <img alt={"University"} src={m} />
                                </div>
                            )}
                        )}
                        </>}
                </Tab>
                <Tab eventKey="review" title={<span className='d-flex align-items-center'><span><span className={s.text}>Reviews</span> {' '} <AiOutlineLike size={20} className={`green ml-2`}/></span></span>}>
                    <p className='text-muted fs-13'>{company && company.reviews && company.reviews.length === 0 && 'No reviews'}</p>
                    <div className={'responsiveGrid'}>
                        {company && company.reviews && company.reviews.map((review, idx) => {
                            if(review.comment)
                                return <Card className='xlCard'>
                                    <Card.Body>
                                        <Card.Text>{review.comment}</Card.Text>
                                    </Card.Body>
                                </Card>
                        })}
                    </div>
                </Tab>
                </Tabs>  */}
            </div>
        </div> :  <Spinner style={{color:"rgb(16, 142, 115)"}} animation="border" role="status"/>}
   </SideBar>
</div>
}

export default (Company);

const UploadMedia = ({company, getCompany, session}) => {
    const [show, setShow] = useState(false)
    const [content, setContent] = useState(null)
    const [success,setSuccess] = useState(false)
    const [error,setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const [uploadErrors, setUploadErrors] = useState([])

    const handleHide = () => {setShow(false); setContent(null); setSuccess(false); setError(null); setUploadErrors([])}

    function onError(){
        setError('Error saving changes, please try again')
        setLoading(false)
      }

      function onDrop (event) {
        setSuccess(false)
        if (event.target.files.length > 0) {
            setError(null)
          setUploadErrors([])
          const errors = validateFiles(event.target.files, 5242880, ['.jpg', '.png', '.jpeg', '.mp4'])
          if (Object.keys(errors).length > 0) {
            setUploadErrors(errors)
            return
          } else {
              setContent(event.target.files[0])
          }
        }
      }

      function handleSubmit() {
        setError(null)
        setLoading(true)
        const mediaId = makeId(10)

        function submitContent() {
          let url = `company-${company.id}-${mediaId}`
          if (!url.startsWith("https://cdn.nxstep.co/")) {
            url = "https://cdn.nxstep.co/" + url;
          }
          let data = {
            url: url,
            mediaType: content.type.includes('video') ? 'VIDEO' : 'IMAGE',
          }
          Root.careerApi.uploadCompanyMedia(company.id, data)
          .then(()=> {
              getCompany()
              setLoading(false);
              setContent(null);
              setSuccess(true);
            }).catch(()=> onError())
        }

        uploadImageCDN(content, `company-${company.id}-${mediaId}`, onError, submitContent)
      }

    return <>
    {session.companyId == company.id &&
        <Button className='btnLink p-0 fs-13 mb-2' onClick={()=> setShow(true)}><AiOutlineCamera/>{' '}Upload Media</Button>}
     <Modal show={show} onHide={handleHide}>
    <Modal.Header closeButton>
        <Modal.Title>Upload Media</Modal.Title>
    </Modal.Header>
    <Modal.Body>
        <div>
        <div className='d-flex justify-content-center align-items-center p-3'
        style={{backgroundColor:'#D4DDE5', borderRadius:'10px', height: '250px'}}>
            <span>
        {content ? <>
            {content.type.includes('video') ?
                <video  className='d-block mx-auto mt-3' style={{maxHeight:'100px', maxWidth:'100%'}} src={URL.createObjectURL(content)}/>
               : <img  className='d-block mx-auto mt-3' style={{maxHeight:'100px', maxWidth:'100%'}} src={URL.createObjectURL(content)}/>}
               </>
            :  <p className='fs-13 text-center'>
                <BsCloudUpload size={50}/>
                <br/>
                Max file size: 2GB, Max video length: 5min, Accepted: jpg, png, mp4
            </p>
            }
            <ImgUploader accept={["image/*", "video/*"]} buttonClassName='wide btnLink' buttonText={loading ? 'Uploading...' : 'Browse Media'} onDrop={onDrop}/>
            <p style={{textAlign:'center', color:'grey', marginBottom:0, fontSize:'0.8rem', marginTop:'1rem'}}>
    * Please note uploading larger files may take several minutes. Please stay on page as the content is uploading *</p>
            </span>
        </div>
        <Button disabled={!content} onClick={ content && handleSubmit} className='btnFill md mt-3'>Upload Media</Button>
        {success && <p className='mt-1 fs-13 green'>Upload complete</p>}
        {error && <p className='form-error'>{error}</p>}
            {uploadErrors.map((error, idx) => {
            return <p key={idx} className='form-error text-center m-0' >{error.type}</p>
        })}
        </div>
    </Modal.Body>
</Modal>
</>
}

const EditCompany = ({company, getCompany}) => {
    const [show, setShow] = useState(false)
    const [request, setRequest] = useState({})
    const [error, setError] = useState(null)
    const [formErrors, setFormErrors] = useState({})
    function handleChange(event) {
        const { name, value } = event.target;
        setRequest((prevState) => ({ ...prevState, [name]: value }));
      }

    function submit() {
        Root.careerApi
        .updateCompany(company.id, request).then(()=> {
            getCompany()
            setRequest({})
            setShow(false)
        })
        .catch(()=> {
            setError("Something went wrong, please try again later")
        })
    }
    return <>
    <Button onClick={()=>setShow(true)} className='btnLink py-0'><FaEdit/></Button>
    <Modal show={show} onHide={()=>setShow(false)} >
        <Modal.Header closeButton>
            <Modal.Title >Edit Company</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className='d-flex justify-content-center'>
                <div className={s.avi} style={{backgroundImage: `url(${company.logo || placeholder})`, backgroundSize:'cover'}} />
            </div>
            <EditLogo company={company} getCompany={getCompany}/>
            <TextInput containerClassName='mb-2' errors={formErrors} name="name" value={request.name || ''} onChange={handleChange} label="Company Name" placeholder={company.name || ''}/>
            <TextAreaInput containerClassName='mb-2' errors={formErrors} name="description" value={request.description || ''} onChange={handleChange} label="Company Description" placeholder={company.description || ''} />
            <TextAreaInput containerClassName='mb-2' errors={formErrors} name="message" value={request.message || ''} onChange={handleChange} label="Message From Representative" placeholder={company.message || ''}/>
            <TextAreaInput containerClassName='mb-2' errors={formErrors} name="mission" value={request.mission || ''} onChange={handleChange} label="Company Mission" placeholder={company.mission || ''}/>
            <TextInput containerClassName='mb-2' errors={formErrors} name="yearFounded" value={request.yearFounded || ''} onChange={handleChange} label="Year Founded" placeholder={company.yearFounded || 'ex. 2012'} type='number'/>
            <TextInput containerClassName='mb-2' errors={formErrors} name="employeeCount" value={request.employeeCount || ''} onChange={handleChange} label="Employee Count" placeholder={company.employeeCount || 'ex. 20'} type='number'/>
            <TextInput containerClassName='mb-2' errors={formErrors} name="website" value={request.website || ''} onChange={handleChange} label="Website" placeholder={company.website || 'www.example.com'}/>
            <TextInput containerClassName='mb-2' errors={formErrors} name="city" value={request.city || ''} onChange={handleChange} label="City" placeholder={company.city || 'City'}/>
            <SelectInput containerClassName='mb-2' ordered={'true'} options={states} errors={formErrors} name="state" value={request.state || ''} onChange={handleChange} label="State" placeholder={states[company.state] || 'State'}/>
            <SelectInput containerClassName='mb-2' ordered={'true'} options={countries} errors={formErrors} name="country" value={request.country || ''} onChange={handleChange} label="Country" placeholder={countries[company.country] || 'Country'}/>
            <Button className='btnFill mt-3 md' onClick={submit}>Save</Button>
            {error && <p className='mt-1 form-error'>{error}</p>}
        </Modal.Body>
    </Modal>
    </>
}


const EditLogo = ({company, getCompany}) => {
    const [errorMessage, setErrorMessage] = useState(null)
    const [uploadErrors, setUploadErrors] = useState([])
    const [loading, setLoading] = useState(false)

    function onError(){
      setErrorMessage('Error saving changes, please try again')
      setLoading(false)
    }

    function onDrop (event) {
      setLoading(true)
      if (event.target.files.length > 0) {
        setErrorMessage(null)
        setUploadErrors([])
        const errors = validateFiles(event.target.files, 5242880, ['.jpg', '.png', '.jpeg'])
        if (Object.keys(errors).length > 0) {
          setUploadErrors(errors)
          return
        }

        if (company.logo) {
          var prevImage = company.logo.split('/')[2]
          deleteImageCDN(prevImage)
        }

        const mediaId = makeId(10)

        function updateCompany() {
            let url = `logo-${company.id}-${mediaId}`
            if (!url.startsWith("https://cdn.nxstep.co/")) {
                url = "https://cdn.nxstep.co/" + url;
            }
            Root.careerApi.updateCompany(company.id, {logo: url}).then(()=> {
                getCompany()
                setLoading(false)
            })
            .catch(()=> {
                setErrorMessage("Something went wrong, please try again later")
                setLoading(false)
            })
        }

        uploadImageCDN(event.target.files[0], `logo-${company.id}-${mediaId}`, onError, updateCompany)
      }
    }

    return <div>
          <ImgUploader buttonClassName='wide btnLink' buttonText={loading ? 'Uploading...' : 'Set Company Logo'} onDrop={onDrop}/>
          {errorMessage && <p style={{textAlign:'center', margin: 0}} className='form-error'>{errorMessage}</p>}
          {uploadErrors.map((error, idx) => {
          return <p key={idx} className='form-error' style={{textAlign:'center', margin: 0 }}>{error.type}</p>
        })}
  </div>
  }


export const AddJob = ({companyId, getJobs, className, company}) => {
    const [show, setShow] = useState(false)
    const [request, setRequest] = useState({})
    const [formResponse, setFormResponse] = useState(null)
    const [formError, setFormError] = useState(null)
    const [formErrors, setFormErrors] = useState({})
    const [questions, setQuestions] = useState([])
    const [types, setTypes] = useState(null)
    const [subtypes, setSubtypes] = useState(null)
    const [loading, setLoading] = useState(false)
    const [question, setQuestion] = useState(null)
    const [companyData, setCompanyData] = useState(null)

    // avoid fetching company info if the parent has the data
    useEffect(()=> {
        if (company) {
            setCompanyData(company)
        }
        if(!company && companyId) {
            Root.careerApi.getCompany(companyId)
            .then((res)=> setCompanyData(res.data))
            .catch((err)=>console.log(err))
        }
    },[])

    const handleHide = () => {setShow(false); setQuestion(null); setQuestions([]); setFormErrors({})}
    function handleChange(event) {
        const { name, value } = event.target;
        setRequest((prevState) => ({ ...prevState, [name]: value }));
      }

    function handleQuestionChange(event) {
        const { name, value } = event.target;
        setQuestion((prevState) => ({ ...prevState, [name]: value }));
    }

    function handlePayChange(event) {
        const { name, value } = event.target;
        setRequest((prevState) => ({ ...prevState, isPaid: name === 'true'}));
    }

    function handleChangeStartDate (selectedDay, modifiers, dayPickerInput) {
        setRequest(prevState => ({ ...prevState, startDate: dayPickerInput.getInput().value }))
      }

    function addQuestion() {
        if(!question.question) {
            return
        }
        setQuestions([...questions, question])
        setQuestion(null)
    }

    function deleteQuestion(idx) {
        if(questions.length <=1) {
            setQuestions([]);
            return
        }
        const arr = [...questions]
        arr.splice(idx, 1)
        setQuestions(arr)
    }

    useEffect(()=> {
        Root.careerApi.getJobTypes().then(res => {
            var typeMap = {}
            res.data.split(',').forEach(t => typeMap[t] = t.replaceAll('_', ' '))
            setTypes(typeMap)
        })
    },[])

    useEffect(()=> {
        if(request.type){
            Root.careerApi.getJobSubTypes(request.type).then(res => {
                var typeMap = {}
                res.data.split(',').forEach(t => typeMap[t] = t.replaceAll('_', ' '))
                setSubtypes(typeMap)
            })
        }
    },[request.type])

    function validate () {
        var errors = {}
        if (!request.timeCommitment) {
            push(errors, 'timeCommitment', 'This field is required ')
          }
        if (!request.title) {
            push(errors, 'title', 'This field is required ')
          }
        if (!request.location) {
          push(errors, 'location', 'This field is required ')
        }
        if (request.isPaid == null || request.isPaid == undefined) {
            push(errors, 'isPaid', 'This field is required ')
        }
        if (!request.description) {
            push(errors, 'description', 'This field is required ')
        }
        if (!request.qualifications) {
            push(errors, 'qualifications', 'This field is required ')
        }
        if (!request.responsibilities) {
            push(errors, 'responsibilities', 'This field is required ')
        }
        return errors
      }

    function handleSubmit() {
        setLoading(true)

        const errors = validate()
        if (Object.keys(errors).length > 0) {
            setFormErrors(errors)
            setLoading(false)
            return
        }
        const data = {
            ...request,
            questions: questions,
            companyId: companyId
        }
        Root.careerApi.createJob(data).then(()=>{
            setFormError(null)
            setRequest({})
            getJobs()
            setFormResponse('Job position created')
            setFormResponse(null)
            setLoading(false)
        }).catch(()=> {
            setLoading(false)
            setFormError('Something went wrong, please try again later')
        })
    }

    function companyProfileComplete() {
        if(companyData && (!companyData.description || !companyData.logo || !companyData.name )) {
            return false
        }
        return true
    }

    return <>
    <Button onClick={()=>setShow(true)} className={className}>Add New Job Posting</Button>
    <Modal show={show} onHide={handleHide} >
        <Modal.Header closeButton>
            <Modal.Title >Add Job Posting</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {!companyProfileComplete() && <Alert variant="warning" className='fs-13'>Please complete your company profile <a href={'/company/' + companyId}>here</a> before creating a new job posting
            </Alert>}
            <TextInput containerClassName='mb-2' errors={formErrors} name="title" value={request.title || ''} onChange={handleChange} label="*Job Title" />
            <TextInput containerClassName='mb-2' errors={formErrors} name="location" value={request.location || ''} onChange={handleChange} label="*Job Location" />
            <TextInput containerClassName='mb-2' errors={formErrors} name="timeCommitment" value={request.timeCommitment || 'ex. 40 hrs/week, Part-Time, Full-Time'} onChange={handleChange} label="*Time Commitment" />
            <DayInput containerClassName='mb-2' errors={formErrors} name="startDate" value={request.startDate || ''} onChange={handleChangeStartDate} label="Start Date" />

            <div className="mb-3">
            <Form.Label>*Paid or Unpaid</Form.Label>
                <div>
                    <Form.Check inline label="Paid" name="true" type={'checkbox'} onChange={handlePayChange}
                        checked={request.isPaid == true} />
                    <Form.Check inline label="Unpaid" name="false" type={'checkbox'} onChange={handlePayChange}
                        checked={request.isPaid == false}/>
                </div>
                <FormErrors errors={formErrors} name={'isPaid'}/>
            </div>
            <TextInput containerClassName='mb-2 pb-2' errors={formErrors} name="compensationDescription" value={request.compensationDescription || ''} onChange={handleChange} label="Compensation Description ($USD)" placeholder={'ex. $20/hour, $100 Contract, Not Paid'} />

            {types && <SelectInput containerClassName='mb-2' ordered={'true'} options={types} errors={formErrors} name="type" value={request.type || ''} onChange={handleChange} label="Job Type" placeholder={''}/>}

            <TextAreaInput containerClassName='mb-2' errors={formErrors} name="description" value={request.description || ''} onChange={handleChange} label="*Job Description" placeholder={''} maxLength={2000}/>
            <TextAreaInput containerClassName='mb-2' errors={formErrors} name="responsibilities" value={request.responsibilities || ''} onChange={handleChange} label="*Responsibilities" placeholder={''} maxLength={2000}/>
            <TextAreaInput containerClassName='mb-2' errors={formErrors} name="qualifications" value={request.qualifications || ''} onChange={handleChange} label="*Suggested Skills" placeholder={''} maxLength={2000}/>
            <TextAreaInput containerClassName='mb-2' errors={formErrors} name="benefits" value={request.benefits || ''} onChange={handleChange} label="Benefits" placeholder={''} maxLength={2000}/>
            <p className='mt-3 mb-0'><strong>Questions for Applicants</strong>  <Button onClick={()=> setQuestion({})} className='btnLink'>+ Add Question</Button></p>
            <p className='fs-13 text-muted'>Max response length is 600 characters</p>
            {questions.map((q, idx) => {
                return <div className='d-flex align-items-center'>
                <Button className='btnLink red py-0' onClick={()=>deleteQuestion(idx)}><TiDelete size={20}/></Button>
                <span className='fs-14'>{q.question}</span>
                </div>
            })}
            {question && <>
                <TextInput containerClassName='mb-2' errors={{}} name='question' value={question.question || ''} onChange={handleQuestionChange} label='*Question'/>
                <Button onClick={addQuestion} className='btnLink'>Save</Button>
            </>}
        </Modal.Body>
        <Modal.Footer>
        {!companyProfileComplete() && <Alert variant="warning" className='fs-13'>Please complete your company profile <a href={'/company/' + companyId}>here</a> before creating a new job posting
            </Alert>}
          <Link className='btnLink fs-14 pt-1' target="_blank" to="/independent-contractor-service-contract">All students who apply to this project will have agreed to the Independent Contractor Service Agreement. Click to view more.</Link>
            {formResponse && <Tile color='purple' className='fs-14'>{formResponse}</Tile>}
            {formError && <p className='form-error'>{formError}</p>}
            <Button className='btnFill mt-3 md' onClick={handleSubmit} disabled={!companyProfileComplete()}> Post Job</Button>
        </Modal.Footer>
    </Modal>
    </>
}
