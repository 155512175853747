import React, {useEffect, useState, useContext} from 'react'
import { Root } from 'Root';
import s from '../styles/StudentProfile.module.scss';
import Modal from 'react-bootstrap/Modal';
import { SessionContext } from 'Utils/sessions';
import Button from 'react-bootstrap/Button'
import { MentorCard } from 'Components/Tile/Card';
import { getAllConnections, respondToConnection } from '../../../Api/Utils/connection';


export const ConnectionRequest = ({respondentId}) => {
    const [error, setError] = useState(null)
    const [pending, setPending] = useState(true)
    const [success, setSuccess] = useState(false)
    const [connection, setConnection] = useState(null)
    const {session, refreshSession} =  useContext(SessionContext);

    useEffect(() => {
        Root.userApi.getConnection(session.id, respondentId).then(res => {
            setConnection(res.data)
            setPending(false)
        }).catch(()=> {
            setPending(false)
        })
    }, [])

    function requestConnection() {
        Root.userApi.proposeExistingUserConnection(session.id, respondentId).then(res => {
            setConnection(res.data)
        }).catch(()=> console.log('Failed to request conntection.'))
    }

    function deleteConnection(connectionId) {
        Root.userApi.deleteExistingConnection(connectionId, session.id).then(res => {
            setConnection(null)
        }).catch(()=> setError('Error deleting conntection.'))
    }

    function respondToConnection(newState) {
        Root.userApi.respondToConnection(connection.id, connection.respondentId, newState).then(res => {
            setConnection(res.data)
        }).catch( console.log('Error responding to request.'))
    }

    function ConnectionButton() {
        // Check for proposed connections
        var result = (
            <div></div>
        )
        if (!pending) {
            if (connection && connection.state == "PROPOSED") {
                if (connection.respondentId == session.id) {     
                        result = (
                            <div className={s.connectionBtnGrp}>
                                <Button onClick={() => respondToConnection('ACCEPTED')} style={{marginRight: '10px'}} className={`btnFill wide green`}>Accept</Button>
                                <Button onClick={() => respondToConnection('REJECTED')} className={`btnFill wide red`}>Reject</Button>
                            </div>)
                } else {
                    result = (
                        <Button onClick={() => deleteConnection(connection.id)} style={{marginBottom: '10px'}} className={`btnFill wide purple`}>Pending Connection</Button>
                    )
                }
            } else if (connection && connection.state == "ACCEPTED") {
                result =  (
                    <Button onClick={() => deleteConnection(connection.id)} style={{marginBottom: '10px'}} className={`btnFill wide purple`}>Remove Connection</Button>
                )
            } else {
                result = (
                    <Button onClick={() => requestConnection()} style={{marginBottom: '10px'}} className={`btnFill purple wide`}>Connect</Button>
                )
            }
        }
        return result
    }

    return <ConnectionButton/>
};


export const ConnectionResponseAlert = ({connection, ...props}) => {

    const [show, setShow] = useState(null)
    const [connectionMessage, setConnectionMessage] = useState(null)

    useEffect(() => {
        var message = ""
        var name = connection.initiator.firstName + ' ' + connection.initiator.lastName
        if (connection.state == "ACCEPTED") {
            message = "Accept connection from " + name + '?'
        } else {
            message = "Reject connection from " + name + '?'
        }
        setConnectionMessage(message)
        setShow(true)
    }, [])

    const onHide = () => { 
        setShow(false); 
        setConnectionMessage(null); 
        props.setSelectedConnection(null);
    } 

    const respondToConnection = () => {
        Root.userApi.respondToConnection(connection.id, connection.respondentId, connection.state).then(res => {
            var name = connection.initiator.firstName + ' ' + connection.initiator.lastName
            if (res.data.state == "ACCEPTED") {
                setConnectionMessage('Accepted connection from ' + name)
            } else {
                setConnectionMessage('Rejected connection from ' + name)
            }
            props.updateConnections()
            setShow(true)
        }).catch( 
            setConnectionMessage('Failed to respond to connection request.')
        )
        props.setSelectedConnection(null)
        
    }
    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Body>{connectionMessage}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={respondToConnection}>
                    Confirm
                </Button>
            </Modal.Footer>
        </Modal>
    )
}


export const ConnectionRequestList = ({connections, ...props}) => {
    const [selectedConnection, setSelectedConnection] = useState(null)

    const respondToConnection = (newState, connection) => {
        connection.state = newState
        setSelectedConnection(connection)
    }

    return (
          <div>
            <div className={'horScroll py-2'}></div>
            <div className='horScroll'>
            {connections && connections.length > 0 ?
                <>
                {connections.map((c)=> {return <MentorCard key={c.id} mentor={c.initiator} connection={c} respondToConnection={respondToConnection.bind(this)} className={`me-4 my-4 Discover_blogspan__HYLJA`} onClick={()=> window.location.href="/profile/student/" + c.initiator.id}/>})}
                </> :
                <p className='text-muted fs-13'>No pending connections</p>}
            </div>  
            {selectedConnection ? 
                <>
                <ConnectionResponseAlert connection={selectedConnection} updateConnections={props.updateConnections.bind(this)} setSelectedConnection={setSelectedConnection.bind(this)}/>
                </>
                : <></>
            }
          </div>
    )
}