import React, { useState, useRef } from 'react'
import { Root } from 'Root'
import Button from 'react-bootstrap/Button'
import Dropdown from 'react-bootstrap/Dropdown'
import { BsFilter} from 'react-icons/bs'
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import { Typeahead } from 'react-bootstrap-typeahead'
import { collegeIds } from 'Utils/colleges'
import s from './styles/Search.module.scss'
import { TextInput } from 'Input/Input';
import { AiOutlineSearch } from 'react-icons/ai'

export const SearchBar = ({className, containerClassName, height}) => {
    const [query, setQuery] = useState(null)
    
      function handleRedirect () {   
        if (query && query.length > 0) {
          const params = new URLSearchParams({
            query: query,
          })
          window.location.href = '/search?' + params
        }
      }

    return <div className={`${containerClassName}`}>
        <InputGroup>
          <FormControl style={{height:height}}  maxLength={"255"} placeholder={"Search a school or student..."} className={`${className}`} value={query || ''} name={"text"} onChange={(e)=>{setQuery(e.target.value)}}/>
          <Button style={{height:height, borderTopRightRadius: '10px', borderBottomRightRadius: '10px'}} className='btnFill green' onClick={handleRedirect}>
            <AiOutlineSearch/>
          </Button>
        </InputGroup>
      {/* <InputGroup className={`${className}`}>
    <Dropdown>
        <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
            <Button style={{height:height}} className={s.filter}><BsFilter/></Button>
        </Dropdown.Toggle>
        <Dropdown.Menu>
            <Dropdown.Item style={schoolsWithMentors ? { backgroundColor: 'lightgrey' } : {}} onClick={handleFilter}>Schools with Mentors</Dropdown.Item>
            <Dropdown.Item style={!schoolsWithMentors ? { backgroundColor: 'lightgrey' } : {}} onClick={handleFilter}>All Schools</Dropdown.Item>
        </Dropdown.Menu>
    </Dropdown>
    <Typeahead style={{height:height}} className={s.search} options={getOptions()} ref={typeahead}  errors={{}} name={'college'}
    placeholder={'Search a school...'} id={'college'} onChange={handleRedirect}/>
</InputGroup> */}
</div>
} 