import React from 'react';
import { NavBar } from 'Components/Nav/NavBar';
import s from './styles/Blog.module.scss';
import Footer from './Footer';
import blog from './static/blog_1.png'

export const NYCCollegeLife = () => (
  <>
    <div className={s.blogPost}>
    <NavBar />
      <div className={`${s.sectionOne} pt-5`}>
        <div className={`${s.container} `}>
          <div className={`${s.article}`}>
            <h1><strong>College Life in New York City</strong></h1>
            <h3 className='fs-16'>Samantha Steff | Columbia University</h3>
            <h2 className='mt-5 mb-3'><strong>INTRODUCTION</strong></h2>
            <p>Life as a student in New York City has been so darn peachy that I decided to write an article all about it. </p>
            <p>Like many students who wind up at Columbia University, I did not want to wait until after I finished my undergraduate education to experience life in a major city. The pace, availability of opportunity, and general excitement of life in New York City is unmatched. That being said, many students considering a city school are understandably concerned about the efficacy of student life in one of the cultural capitals of the world. </p>
            <p>How can I fully delve into my studies if I’m in the middle of a major city? Won’t I get distracted? Or feel like I’m being pulled in a million different directions? Do city schools even have real campuses? Won’t New York City overshadow my college experience?</p>
            <p>My admittedly not-super-unique take is that going to school in New York City is what you make of it. As a Columbia student, you can elect to spend four years between 110th and 120th Street. You can live off campus, and only touch Ivy League quad on your way to class. Or, like most every Columbia student I’ve ever met, you can figure out how to juggle academics with robust city life.</p>
            <p>By going to school in New York City, you sign up for a cultural education as well as an implied academic one. I do not feel that I’ve sacrificed my personal or academic growth by going to a city school… in fact, I’d assert much the opposite. I think that going to Columbia has taught me something that I didn’t/couldn’t completely grasp in high school: an undergraduate education is about way more than just academics. I’ve learned to juggle work with some semblance of a life, and I do my best to live fully/be present on campus and off. Which feels awfully adult to me.</p>
            
            <img style={{width:'100%', borderRadius:'20px'}} className='my-5' src={"https://cdn.nxstep.co/Skyline_AdobeStock_270697557.jpeg"}/>

            <p>I transferred to Columbia, and effectively experienced life as a first-year college student all over again. This, I expected. However, I was surprised to find solace in the vastness of New York City as I attempted to acclimate to my new university.</p>
            <p>While a student who “belongs” at a small liberal arts school might be made uneasy by New York City’s endless offerings, I felt only relief knowing that I was no longer trapped in my hometown of Las Vegas.</p>
            <p>Essentially, I think that because I so desperately wanted out of what I perceived as small-town Nevada--which might ring true to some incoming first-years or prospective transfer students--I was an ideal candidate for a city school. When I felt caught up in the social and academic hullabaloo of Columbia’s campus culture, I sought out a new solo brunch spot, or walked one of New York City’s many iconic museums with friends. I personally found comfort in the endless possibilities of city life.</p>
            <p>I feel I should offer a disclaimer: though I’m from Las Vegas, I have lots of family in New York City, and as such was never really deprived of hometown comfort. That being said, I believe that even if I had come to Manhattan completely on my own, I would’ve been fine.</p>
            <p>In my opinion, anyone can become comfortable at a city school in due time, but some students might be more predisposed to/eager for the experience than others.</p>

            <h2 className='mt-5 mb-3'><strong>OPPORTUNITY IN NEW YORK CITY</strong></h2>
            <p>The obvious draw of going to school in New York City is instant access… to food, to the arts, to everything. Some might take advantage of Manhattan’s extensive nightlife scene, or spend weekends walking the island from tip to toe. For me, being a student in New York City meant that I could spend every free minute engaging with New York’s peerless theater classes and events.</p>
            <p>I am an English major, but living in New York City has allowed me the chance to study theater outside of a university setting. (This doesn’t really seem to be a thing in other places.) I’ve had the opportunity to take classes from some of America’s preeminent theater practitioners--from Broadway actors, to professors at top-tier drama schools, to seasoned improv artists--at various well-known studios across the city.</p>
            <p>Whether alone or with friends, I saw Broadway or Off Broadway shows nearly every weekend. I sought out affordable tickets through the service TodayTix as well as Columbia’s own student ticket distributor. I also gained invaluable work experience as an intern for a wonderful theater company. (Many Columbia students intern with incredible New York companies during their time as undergraduates. This is a huge perk of studying at a school in a major city!)</p>
            <p>Transportation is relatively affordable by subway. You can walk virtually anywhere. You’ll never run out of restaurants to try, shows to see, funky coffee shops to sample, parks through which to meander, magic to do… this, to me, is the crux of going to school in any city.</p>

            <h2 className='mt-5 mb-3'><strong>CAMPUS CULTURE IN NEW YORK CITY</strong></h2>
            <p>While I may not have emphasized my academic experience much in this article (because that’s not really what this piece is supposed to be about), it’s been nothing short of stellar. I’ve been academically challenged and, in true college student fashion, have spent many long nights tinkering away in Columbia’s various libraries (I’d like to imagine not unlike a happy, industrious lil’ elf in Santa’s workshop).</p>
            <p>Columbia has grassy quads, majestic brick buildings, active Greek life, and secret societies--all the fixings of any small-town college, I think. However, it would be remiss of me to imply that every college or university in New York City has this sense of readily-available community.</p>
            <p>While Columbia has a relatively tight, six-block campus, many of our neighboring schools--like New York University, Baruch College, and The New School--are spread across their respective neighborhoods. I can’t speak to what this experience is like first-hand, but that’s what this website is for--right? Go hit up some students at NYU!</p>
            
            <h2 className='mt-5 mb-3'><strong>CLOSING THOUGHTS</strong></h2>
            <p>I’ve thrown a lot of ideas out there. If I had to boil this article down to a couple of points, they’d be:</p>
            <ul>
              <li>Going to school in New York City is not a one-size-fits-all experience. It’s customizable. You get to determine how much time you spend off campus.</li>
              <li>I try to balance on/off campus activities to achieve the fullest possible city college experience™.</li>
              <li>Take advantage of awesome opportunities on campus and off!</li>
            </ul>

            <p>If these points seem vague, I’ll say that’s intentional. I made my way to Columbia for very specific reasons, and likewise have enjoyed my time there because this university accommodates my personal affinities.</p>
            <p>Going to school in New York City might not be for everyone (though I’m pretty convinced that it could be). I didn’t think that I wanted to go to a city school when I was applying to colleges. It took actually attending a small school to understand that, at least during my #brightcollegeyears, I needed a bigger pond.</p>
            <p>All this is to say that you could go to a city school and loathe the vastness at first, then grow to love it. You could also instantly fall in love with life as a student in New York City. Like the Big Apple, there’s infinite possibilities. It’s just about how willing you are to roll with the punches.</p>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </>
);

export const CollegeEssentials = () => (
  <>
    <div className={`${s.blogPost} ${s.essentials} `}>
    <NavBar />
      <div className={`${s.sectionOne} pt-5`}>
        <div className={`${s.container}  `}>
          <div className={`${s.article}`}>
            <h1 ><strong>12 College Essentials</strong></h1>
              <h3 className='fs-16'>Anonymous | University of California, San Diego</h3>
                <p className='mb-5'>As a third-year, out-of-state student at the University of California, San Diego (UCSD), I remember feeling extremely overwhelmed with what to pack for my first year of college. Naturally, I ended up over-packing. Because of this, I found myself finding a lot of the items that I brought to be either not really that useful or things that I couldn't have lived without. This being said, here are some items I brought that helped me out a lot more than I expected them to!</p>
                <div className={s.grid}>
                  <span>
                    <h2 className='mt-3'>1) Printer</h2>
                    <p>Instead of scrambling to find a place to print out a paper at the last minute, I highly recommend you or a roommate bring a printer. Being able to print out study guides or homework in the comfort of your dorm room makes for a lot less stress and a lot more convenience instead of having to ask others to print or having to find a place on campus that has free printing.</p>
                  </span>
                  <img  src="https://mcusercontent.com/9e909094c1e228634b6c348c2/images/cdfd27a2-f6dc-424a-96f8-0860e4e89bf2.jpeg" alt="Printer"/>
                </div>
                <div className={s.grid}>
                  <span>
                    <h2 className='mt-5 mb-3'>2) Febreze</h2>
                    <p>Febreze spray or scented candles are a necessity to mask the musty smell of dorms and bathrooms. Having a room that smells good and fresh will always put you in a better mood and make your dorm room feel a little more homey.</p>
                  </span>
                  <img src="https://mcusercontent.com/9e909094c1e228634b6c348c2/images/28b673f8-0b12-4bb4-9ded-0313eec61f56.jpg" alt="HydroFlask/Reusable Water Bottle"/>
                </div>
                <div className={s.grid}>
                  <span>
                    <h2 className='mt-5 mb-3'>3) HydroFlask/Reusable Water Bottle</h2>
                    <p>This one might be a given, but when I went to college, I didn’t even think about getting a reusable water bottle. In my first week, I bought two Hydro Flasks and <i>they. are. life-savers</i>. Luckily, there’s always water bottle refilling stations around campus, so you should always have a reusable water bottle wherever you go. You’ll save money and the earth by not buying plastic bottles!</p>
                  </span>
                  <img  src="https://mcusercontent.com/9e909094c1e228634b6c348c2/images/e6150fb8-00b3-4102-b39a-cc5eca3fafb1.jpeg" alt="Febreze"/>
                </div>
                <div className={s.grid}>
                  <span>
                    <h2 className='mt-5 mb-3'>4) Sleep Eye Mask</h2>
                    <p>If you are able to sleep while wearing an eye mask, I recommend bringing one with you. Sometimes the blinds on the windows aren’t the best, and I remember the sun waking me up way earlier than I wanted to some days. An eye mask will definitely help you sleep through the night and have you waking up feeling more refreshed.</p>
                  </span>
                  <img src="https://mcusercontent.com/9e909094c1e228634b6c348c2/images/905a6c61-535d-4803-a8d2-66276ce85e7e.jpeg" alt="Sleep Eye Mask"/>
                </div>
                <div className={s.grid}>
                  <span>
                    <h2 className='mt-5 mb-3'>5) Power Strip</h2>
                    <p>An electric power strip is one item that students often forget to bring, but they are a necessity! With only having a few power outlets in every dorm room, but tens of cords and cables, power strips help you stay organized and allow you to connect everything that you to power.</p>
                  </span>
                  <img src="https://mcusercontent.com/9e909094c1e228634b6c348c2/images/102768d5-1aaa-4c45-8f59-e7bcf5220271.jpeg" alt="Power Strip"/>
                </div>
                <div className={s.grid}>
                  <span>
                    <h2 className=''>6) Hanging Closet Organizer</h2>
                    <p>With having a small closet in your dorm room, hanging closet organizers are essential. I also recommend buying space-saving hangers for inside a closet, and also drawers for underneath your bed.</p>
                  </span>
                  <img src="https://mcusercontent.com/9e909094c1e228634b6c348c2/images/e3c91fed-8d6c-42d6-bd1f-d81a0cb4be59.jpeg" alt="Hanging Closet Organizer"/>
                </div>
                <div className={s.grid}>
                  <span>
                    <h2 className='mt-5 mb-3'>7) Door Mirror</h2>
                    <p>Instead of needing to walk to a bathroom to see a mirror, a hanging door mirror will help out immensely (as well as a small desk mirror). Whether it’s for makeup, or just to take in the full scope of your fit, having a full-length mirror in your room is super helpful.</p>
                  </span>
                  <img src="https://mcusercontent.com/9e909094c1e228634b6c348c2/images/75ef2e27-f71e-4da9-a3f8-4e393747665a.jpg" alt="Door Mirror"/>
                </div>
                <div className={s.grid}>
                  <span>
                    <h2 className='mt-5 mb-3'>8) Shower Caddy</h2>
                    <p>If you are sharing a bathroom with multiple people, a shower caddy is a necessity. Having all of your shower bottles in an organized caddy will make showering much easier and less of a hassle.</p>
                  </span>
                  <img src="https://mcusercontent.com/9e909094c1e228634b6c348c2/images/a7a0ca18-7011-4a6b-a7ac-1b168a2a08fa.jpeg" alt="Shower Caddy"/>
                </div>
                <div className={s.grid}>
                  <span>
                    <h2 className='mt-5 mb-3'>9) Brita</h2>
                    <p>A Brita or any other water filter is a must-have. In my dorm, there were no water filling stations throughout the whole building. Having a Brita in my room was super convenient and nice when I wanted filtered water. It was a much better alternative to walking outside of the building at night just to fill up my water bottle.</p>
                  </span>
                  <img src="https://mcusercontent.com/9e909094c1e228634b6c348c2/images/db1ff006-a3c9-4b63-88ac-d566c9ce9625.jpg" alt="Brita"/>
                </div>
                <div className={s.grid}>
                  <span>
                    <h2 className='mt-5 mb-3'>10) Mini-Fridge</h2>
                    <p>This one might seem obvious, but you should always have at least one mini-fridge in your room. Whether it’s to store your Brita, leftovers, or Uncrustables, mini refrigerators are a necessity for a midnight snack, small meal, and/or cold beverages.</p>
                  </span>
                  <img src="https://mcusercontent.com/9e909094c1e228634b6c348c2/images/7b44c1c9-8219-4891-95d8-81836708fde2.jpeg" alt="Mini-Fridge"/>
                </div>
                <div className={s.grid}>
                  <span>
                    <h2 className='mt-5 mb-3'>11) Computer Desk for Bed</h2>
                    <p>Especially with having classes online, a portable desk for your computer is extremely nice when you want to go to class while laying in your bed. It’s also good for homework or watching movies as well! It’ll help with posture and prevent your laptop from overheating.</p>
                  </span>
                  <img src="https://mcusercontent.com/9e909094c1e228634b6c348c2/images/cc7c770c-2531-43e6-be96-2a5da5ddfb8b.jpg" alt="Computer Desk for Bed"/>
                </div>
                <div className={s.grid}>
                  <span>
                    <h2 className='mt-5 mb-3'>12) Fan</h2>
                    <p>Believe it or not, many dorm rooms do not have fans or AC, so bringing a fan will help a lot with circulation and with the heat. I would recommend having a small desk fan as well as a large standing fan. If you are going to school where it is extremely hot, a portable AC unit would be a wise investment as well.</p>
                  </span>
                  <img src="https://mcusercontent.com/9e909094c1e228634b6c348c2/images/ff81c9ab-fd9c-41e8-a909-c64d68697261.jpeg" alt="Fan"/>
                </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </>
);

export const Transfer = () => (
  <>
    <div className={s.blogPost}>
    <NavBar />
      <div className={`${s.sectionOne} pt-5`}>
        <div className={`${s.container}`}>
          <div className={`${s.article}`}>
            <h1><strong>Community College to Four Year University</strong></h1>
            <h3 className='fs-16'>Anonymous | Unknown University</h3>
            <img style={{width:'100%', borderRadius:'20px', maxHeight:'300px', objectFit:'cover'}} className='my-5' src="https://cdn.nxstep.co/transfer-application.jpg" alt="Transfer Application"/>
            <h2 className='mb-3'><strong>COMMUNITY COLLEGE</strong></h2>
            <p>During my junior year of high school, I was told that it was time to start applying for colleges and plan my
                future. I did not have any specific field of study in mind that I wanted to declare as my major, so choosing a college became
                even more difficult. After all, deciding what you want to do for the rest of your life is not an easy task. That’s when
                I decided to pursue dual enrollment and take courses at a local community college.</p>
            <p>As I look back on my decision, I realize that I was able to adjust well to the college
                lifestyle, and now I even have a head start in the next phase of the college career.
                Choosing to go to a community college is a great way to save money and give yourself more preparation for a
                four-year college. Having the opportunity to adjust to adulthood and handling college courses, while staying in your home
                area, has its benefits. Many students want to move far away from home the moment they graduate high school.
                However, they eventually end up realizing that the world is not as easy as they thought it would be.</p>
            <h2 className='mt-5 mb-3'><strong>TRANSFERRING</strong></h2>
            <p>Now that I am applying to universities to transfer to, the process feels much easier than if I would have
                applied as a freshman. The process is much simpler since I have a better idea as to where I want to go in
                life. While I not only saved tens of thousands of dollars by choosing to start a community college, I also
                became more prepared for everything else that is ahead in my college career. As I enter a new and larger
                college environment, having mastered how everything works in a smaller environment will pay off.</p>
            <p>Most four-year colleges will accept transfer credits from community colleges without any hassle, and some
                will even guarantee your entry if you maintain a certain GPA.</p>
            <p>During this application process, I find it much easier to decide and narrow my selections down to a college
                that fits me based on how I have felt over the past two years. Choosing to start at a community college is
                a perfect choice for many reasons, and these colleges are more than willing to help you in the process of
                transferring.</p>
            </div>
        </div>
      </div>
    </div>
    <Footer />
  </>
);

export const GradSchool = () => (
  <>
    <div className={s.blogPost}>
    <NavBar />
      <div className={`${s.sectionOne} pt-5`}>
        <div className={`${s.container}  `}>
          <div className={`${s.article}`}>
            <h1><strong>Is Grad School Right for Me?</strong></h1>
            <h3 className='fs-16'>Brianna Buckley | Rice University</h3>
              <p className='mt-3 mb-3'>My name is Brianna Buckley and I am a 2020 graduate from the University of Notre Dame, as well as a 2022 MBA
                candidate at Rice University. Graduate school was not something I ever envisioned or planned for myself.
                It really just happened, and in this blog post, I will reflect on that decision.</p>

              <h2 className='mt-3 mb-3'><strong>THE GOOD</strong></h2>
              <p>With cities and companies hitting the pause button in the wake of COVID-19, I applied
              to business graduate school with the hopes of a backup option since the company with which I accepted my full-time offer
              wasn’t fairing too well. Now, almost a full year into the program, I can say it is one of the best decisions
              I have ever made. If you are considering graduate school, specifically business school, I would personally
              recommend it if you are:<br/>
              <img style={{ borderRadius:'20px', maxHeight:'300px', objectFit:'cover', float:'left', margin: '20px 30px 10px 0px'}} 
              src="https://cdn.nxstep.co/bri-head-shot.jpeg" alt="Verified"/>

              <ol>
                <li><strong>Switching career paths</strong><br/>MBA school is the perfect opportunity to switch career paths if you are not
                  enjoying your current job function. I personally fall into this category. I was an engineer trying to
                  transition to a product management/marketing role. One of my peers transitioned from engineering
                  to finance, and another from accounting to operations management. In essence, MBA gives you a clean slate.
                  You can recruit in any industry, at any company, for any job function. Recruiters understand that a lot of
                  people pursue their MBA to switch career paths so recruiters do not expect you to have a specific background. You
                  only need to demonstrate your willingness to learn.</li>
                <li><strong>Trying to be an entrepreneur</strong><br/>The curriculum at business schools basically teaches you how businesses operate.
                  Thus, it is very easy to take this knowledge and apply it to your own business. A lot of MBA candidates leave
                  their program with a fantastic job, as well as fairly successful side businesses. <i>Shameless plug, Rice has the
                    #1 entrepreneurial program in America!</i></li>
              </ol>
            </p>
            <h2 className='mt-5 mb-3'><strong>BE CAUTIOUS...</strong></h2>
            <p >As much as I love my experience, there are a few things I do not recommend. Of course, these recommendations
            are my personal opinions so please do not let this be your deciding factor if you are considering your MBA.
            Nonetheless, I would not recommend it if you are:<br/>
            <ol>
              <li>
                <strong>Trying to stay within the same career path</strong><br/>If you are trying to learn more about a field you are currently
                in, I would not recommend business school. Even though you can focus on a concentration, school is still
                school and it won’t teach you as much as being in the real world will. The professors are not all-knowing either.
                I’ve had previous accountants correct my accounting professor while he was teaching, previous marketers teach
                my marketing professor something she didn’t know, etc. People who come into business school thinking they will
                learn something new about their career domain typically end up dropping out. They claim it is not worth
                their time.</li>
              <li><strong>Straight from undergrad</strong><br/>I know this is ironic since this is exactly what I did, but I have my reasons for
                saying this. First, assuming you want to go to graduate school, I am also assuming you want to go to a top-tier program.
                However, most top universities do not accept students straight from undergrad. They won’t even look at your
                application without 2-3 years of work experience. Even Rice University, ranked in the mid-20s among all business
                schools, rarely accepts students straight out of undergrad. In my class, there are only two of us who fit that criterion. If you
                apply and don’t get in, when you reapply to a graduate program, you will have to check a box that says you’ve
                applied before and weren’t admitted, which I’ve heard isn’t the best look. Instead, a lot of schools offer a
                deferred program (which I wish I would’ve done). This is when you apply to a graduate school your senior
                year of undergrad to attend 2 years later. For example, if you are graduating undergrad in 2021, you apply to a deferred
                program that year and (hopefully) get your acceptance letter to attend in 2023. After you graduate, you work
                for 2 years and then begin your MBA experience in 2023! The benefit of this is that you can apply to top universities
                your senior year of undergrad, know whether you have been accepted or not, and plan accordingly!</li>
            </ol>
            </p>
            <p><strong>I hope this helps! If you have any more questions, need your graduate essay proofread, or just want to chat,
            schedule a meeting with me by finding me on the list of the University of Notre Dame Mentors.</strong></p>
            </div>
          </div>
      </div>
    </div>
    <Footer />
  </>
);

export const BeatTheOdds = () => (
  <>
    <div className={s.blogPost}>
    <NavBar />
      <div className={`${s.sectionOne} pt-5`}>
        <div className={`${s.container} `}>
          <div className={`${s.article}`}>
            <h1><strong>How to Beat the Odds</strong></h1>
            <p>Steps to take in high school to help get into your dream school</p>
            <h3 className='fs-16'>Anonymous | Unknown University</h3>
            <img style={{width:'100%', borderRadius:'20px', maxHeight:'300px', objectFit:'cover'}} className='my-5' src="https://cdn.nxstep.co/decision-cloud.jpeg" alt="DECISIONS"/>
            <p>Throughout my entire high school career, I always had my eyes set on college. I dreamt of going to college
            and majoring in some discipline of engineering for as long as I can remember. During middle school, I
            prepared myself for high school, and during high school, I prepared myself for college. Through this article,
            I will tell you what I did to prepare for college.</p>
        <h2 className='mt-5 mb-3'><strong>DECIDE WHAT YOU WANT</strong></h2>
        <p>The first step I needed to take in preparation for college was making the decision as to where exactly I
            wanted to go, and I decided I wanted to go to a top tier university. I then looked at the requirements to get
            in, the SAT/ACT scores that were required,
            and the school's overall acceptance rate. It’s important to know where you want to go and how competitive
            it is to get there. I set my sights high so that even if I missed, after all the preparation, I would have
            had a high chance of getting into a college that was not far from the goal, in terms of prestige.</p>
        <h2 className='mt-5 mb-3'><strong>UNDERSTAND WHERE YOU ARE</strong></h2>
        <p>Once you know where you want to go, truly evaluate yourself. Understand your current position and the chances
            of getting into that university currently. Be honest with yourself, but don’t remove all odds.
            Don’t lose hope, but understand that there is work to be done.</p>
        <h2 className='mt-5 mb-3'><strong>GOAL , PLAN & DISCIPLINE</strong></h2>
        <p>Now that you understand your starting point and where the finish line is, you have a clearly defined goal, and you
            understand what it takes to reach it. The next step is to create the plan. For the most part, your
            classes and the course load is predetermined and created without your input, but you do have the opportunity
            to take honors and AP courses.</p>
        <p>Talk to your counselor to figure out which combination of honors and AP courses you should take in order to
            improve your chances of achieving your goal. Taking AP courses can only help you if you perform well in them.
            If you overload yourself with these high-level courses and end up failing, you essentially are telling the
            college that you cannot handle a higher level course load. What you want to do with your course selection is prove that
            you enjoy challenging yourself and always seek to learn more. However, at the same time, you want to prove
            that you can handle a healthy challenge. The key is finding the right balance.</p>
        <p>It would also be useful to find which AP courses and credits are accepted at the universities you have your
            eyes set on. It would be terrible to put all your effort into a course to have your dream college not even
            recognize your earned credits. This can happen, so be mindful.</p>
        <p>Find the right clubs and organizations to join that are related to your field of study or your general
            interests. Join a sports team, a music group, and/or an honors society, to show interest outside of the
            classroom. That being said, don’t pick a random group to join just for the sake of joining. You want to show the
            university that you are a student with many interests and that you partake in as many as you can while
            balancing your time and being selective.</p>
        <p>When joining these clubs, try to take a leadership position. Gaining leadership experience is impressive to
            colleges as it shows that you have the ability to look out for other’s well-being while maintaining on the path
            to achieving your goal.</p>
        <p>The most important thing you must remember as you take on these new roles and courses is that you need
            to maintain focus and discipline. The easiest part is signing up for the opportunities, but the hardest part
            is continuing to work for it and blocking out the noise. There will be numerous distractions that will try
            to compete for your attention and distract you from achieving your goal, but if you stay focused and maintain
            discipline, it will become easier to ignore them, and you will continue to move closer to the finish line.</p>
        <h2><strong>THE EXCEPTIONAL FRAME</strong></h2>
        <p>Colleges look for exceptional students to bring into their realm of academia. As they read applications and
            filter out students, they are ultimately looking for students who are exceptional and would add a unique
            perspective and personality to the student body...and ultimately the alumni network. Become that exceptional
            student, and become that unique personality.</p>
            </div>
        </div>
      </div>
    </div>
    <Footer />
  </>
);