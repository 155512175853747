
export const fields = {
    academics: 'Academics',
    diversity: 'Diversity',
    campus: 'Campus Scenery',
    athletics: 'Athletics',
    safety: 'Safety',
    partyScene: 'Party Scene',
    professors: 'Professors',
    location: 'Campus Location',
    dorms: 'Dorms',
    campusFood: 'Campus Food Options',
    studentLife: 'Overall Student Life',
    value: 'Overall value',
}

export function commafy (num) {
  if (!num) {
    return
  }
  var str = num.toString().split('.')
  if (str[0].length >= 4) {
    str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,')
  }
  if (str[1] && str[1].length >= 5) {
    str[1] = str[1].replace(/(\d{3})/g, '$1 ')
  }
  return str.join('.')
}

export function urlToHostname (url) {
  return url.replace('http://','').replace('https://','').replace('www.','').split(/[/?#]/)[0];
}

export function reduceRatings(ratings){
  // need to clone first bc when you reduce on ratings, it refs the same obj each time and alters vals
    const cloneRatings = ratings.map(a => Object.assign({}, a));
    if(cloneRatings.length < 1){
        return {}
    }
    const reducer = (accumulator, currentValue) => { 
        Object.keys(fields).forEach( key => {
        accumulator[key] += currentValue[key]
        }
    )
        return accumulator;
    };
    return cloneRatings.reduce(reducer);
}

export function getAverageRating(ratings){
  if(ratings.length < 1){
      return null
  }
  var reducedRatings = reduceRatings(ratings)
  var total = 0

  Object.keys(reducedRatings).forEach(key => {
    if(Object.keys(fields).includes(key)){
      total += (reducedRatings[key]/ratings.length)
    }
  })
  return (total / 12).toFixed(1)
}

export function toPercent(value){
    if(value){
      return Number(Number(parseFloat(value) * 100).toFixed(1))
    }
    return 0
  }

  export  function getGenderChartData(data){
    return [{name:'Men (%)', value: toPercent(data.men)},
    {name:'Women (%)', value: toPercent(data.women)}]
}

export function getRaceChartData(data){
    return [
    {name:'White',value: toPercent(data.white)},
    {name:'Black', value: toPercent(data.black)}, 
    {name:'Asian', value: toPercent(data.asian)},
    {name:'American Indian/Alaska Native', value: toPercent(data.aian)}, 
    {name: 'Hispanic', value: toPercent(data.hispanic)},
    {name:'Pacific', value: toPercent(data.pacific)}, 
    {name:'Multiracial', value: toPercent(data.multiracial)},
    {name:'Non Resident Alien', value: toPercent(data.nonResidentAlien)},
    {name:'Unknown Race', value:toPercent(data.unknownRace)},
    ]
} 
