import React from 'react';
import { NavBar } from 'Components/Nav/NavBar';
import s from './styles/Blog.module.scss';
import Footer from './Footer';

const IndependentContractorServiceContract = () => (
  <>
    <div className={s.blog}>
      <div className={`${s.sectionOne}`}>
        <NavBar/>
        <div className={`${s.container} mt-5`}>
          <h1><strong>Independent Contractor Agreement</strong></h1>
        </div>
      </div>
      <div className={`${s.sectionTwo} ${s.container}  `}>
      <p style={{paddingTop:"15px"}}>This <strong>Independent Contractor Service Contract</strong> (hereinafter to be referred as <strong>“the Contract”</strong>)
is entered into by and between the Company that has registered its account as an employer on
Company’s website i.e. https://nxstep.co/ (hereinafter to be referred as <strong>“the Company”</strong>) and the
student/freelancer that has registered an account Nxstep website as a student/freelance (hereinafter to be
referred as <strong>“the Contractor”</strong>) (each individually known as <strong>“Party”</strong> and collectively <strong>“the
Parties”</strong>) with respect to the provision of the Contract.</p>
<p><strong>WHEREAS</strong>, the Company desires to enter into a Contract with the Contractor to perform Services
in accordance with terms of this Contract; and</p>
<p><strong>WHEREAS</strong>, the Contractor desires to perform certain Services for the Company on an
independent contractor basis as set forth in this Contract and the project description.</p>
<p><strong>NOW THEREFORE</strong>, in consideration of the above recitals, which are hereby incorporated into
the below Contract, and in consideration of the mutual promises made herein, the receipt and
sufficiency of which are hereby acknowledged, the Company and the Contractor further agree as
follows:</p>
      <ul>
        <li>
          <h2 style={{paddingTop:"15px"}}>1. INTRODUCTION</h2>
          <p>The Parties wish to enter into a contractual arrangement, whereby, the Contractor shall act
in the capacity of an independent contractor on behalf of the Company, while fulfilling the
traditional tasks and activities usually conducted by the Company and the Contractor
shall also be responsible for providing Services on behalf of the Company.</p>
        </li>
        <li>
          <h2 style={{paddingTop:"15px"}}>2. SERVICES</h2>
          <p>The Contractor agrees to perform the Services (the “Services”) in a timely, expeditious and
professional manner and in accordance with all applicable provisions of the Contract. The
details of the Services to be provided under this Contract have been enlisted in AnnexureA of this Contract. In the event of any conflicts between the Contract and any of the terms
of the Annexure-A, the terms of this Contract shall prevail.</p>
        </li>
        <li>
          <h2 style={{paddingTop:"15px"}}>3. DUTIES AND OBLIGATIONS</h2>
          <p>The Parties mutually agree to the below mentioned terms & conditions:</p>
          <ol>
            <li>All Services are to be performed solely at the risk of the Contractor and the Contractor
shall take all precautions necessary for the proper performance thereof.</li>
            <li>The Contractor agrees to proceed with diligence and promptness and hereby warrants that
such Services shall be performed in accordance with the highest professional workmanship
and service standards in the field to the satisfaction of the Company.</li>
            <li>The Contractor shall return and provide to the Company such equipment, documents, data
that belongs to the Company, by the completion date and before full payment of the
compensation herein.</li>
            <li>The Company shall pay the remuneration of the Contractor electronically via NXSTEP
(www.nxstep.co), and the same shall be used as a power of attorney to seek any refunds
from the Contractor for the periodthat was not serviced to the Company as well as for any
loss or damage caused to the equipment provided by the Company.</li>
            <li>The Contractor is solely responsible for all taxes, withholdings, and other similar statutory
obligations; and he/she agrees to defend, indemnify and hold the Company harmless from
any and all claims made by any entity on account of an alleged failure by Contractor to
satisfy any such tax or withholding obligations.</li>
            <li>Contractor will indemnify and hold the Company harmless from, and will defend it against,
any and all loss, liability, damage, claims, demands, or suits and related costs and expenses
to persons or property that arise directly or indirectly, from acts or omissions of Contractor,
or from the breach of any term or condition of this Contract attributable to the Contractor
or his/her agents, or from Contractors failure to comply with any applicable law.</li>
          </ol>
        </li>
        <li>
          <h2 style={{paddingTop:"15px"}}>4. COMPENSATION</h2>
          <p>For satisfactory performance of the Services hereunder, the Company shall pay the
Contractor for the performance of the services detailed in the project description.The
payment shall be either paid as lump sum after the completion of the Contract or it shall
be divided in equal parts and shall be paid to the Contractor upon completion and
delivering of the Services.</p>
          <p>Payment may also be made on a salary or hourly rate. The Company shall have no
obligation to make any payments until such time as it accepts the performance of the
Contractor as satisfactory.</p>
        </li>
        <li>
          <h2 style={{paddingTop:"15px"}}>5. CONFIDENTIAL INFORMATION</h2>
          <p>In the course of performing its obligations hereunder, Contractor may come into possession
of “Confidential Information” of the Company. For purposes of this Contract Confidential
Information shall include any and all technical and business information of the Company
or its clients, whether written, graphic or oral, including but not limited to, product and
service specifications and offerings, methods of doing business, design plans, drawings,
prototypes, inventions (whether patentable or not), computer software programs (including
source code, object code, descriptions of functions and features thereof and
documentation), data, trade secrets, business strategies and opportunities, pricing policies,
financial information, information regarding current and/or prospective clients, customers,
vendors, employees and contractors, information which is received in confidence by or for
the Company from any third person and the terms and conditions of this Contract
(including all originals, copies, digests and summaries in any form or format, whether
prepared by the Company or Contractor or either parties’ agents or advisors).</p>
          <p>At all times, both during the term of this Contract and after its termination, Contractor will
keep in confidence and trust, and will not use or disclose, any Confidential Information
without the prior written consent of the Company.</p>
          <p>Contractor understands that the Company has received and in the future will receive from
third parties confidential or proprietary information (“Third Party Information”) subject to
a duty on Company’s part to maintain the confidentiality of such information and to use it only for certain limited purposes. During the term of this Contract and thereafter,
Contractor will hold Third Party Information confidential except as permitted by the
Contract between the Company and such third party or unless expressly authorized in
writing to act otherwise by an officer of the Company.</p>
          <p>Contractor acknowledges that unauthorized disclosure or use of Confidential Information
would cause irreparable harm and significant injury that may be difficult to ascertain and
that cannot be compensated by money damages and therefore the Company will not have
an adequate remedy at law. Accordingly, Contractor agrees that the Company will have the
right to seek immediate injunctive relief, specific performance, or other equitable remedy
from a court of competent jurisdiction as may be necessary or appropriate to prevent or
curtail any such disclosure or use, threatened or actual. This right is in addition to and
without prejudice to other rights the Company may have in law or in equity.</p>
        </li>
        <li>
          <h2 style={{paddingTop:"15px"}}>6. TERM AND TERMINATION OF CONTRACT</h2>
          <p><strong>Term</strong>: This Contract is effective as of the date presented on the project posting
(<strong>“Effective Date”</strong>), and will terminate when services are completed, unless
terminated earlier pursuant to the terms and conditions of this Contract.</p>
          <p><strong>Termination</strong>: Notwithstanding anything in this Contract to the contrary, either Party may
terminate this Contract without cause thirty (30) days prior written notice to
the other Party. The Company shall have the right to immediately terminate
this Contract “for cause”. The Company shall have “cause” to terminate
Contractor for any of the following reasons:</p>
          <ol>
            <li>for any breach of this Contract by Contractor, provided the Contractor has
not cured the breach within ten (10) days after written notice of such breach
by Company has been sent to Contractor;</li>
            <li>for any breach by Contractor which by its nature is not curable; including
but not limited to, acts of dishonesty, threat of physical harm to others or
other conduct disrupting a client event;</li>
            <li>total disability of Contractor for a cumulative period of thirty (30) or more
days during any consecutive twelve (12) month period;</li>
            <li>Contractor’s loss of licensure, if any, to carry out his/her Services on behalf
of the Company;</li>
            <li>any part of the representations made by Contractor in this Contract are
found to be false;</li>
            <li>breach of confidentiality of Confidential Information</li>
          </ol>
        </li>
        <li>
          <h2 style={{paddingTop:"15px"}}>7. INDEPENDENT CONTRACTOR STATUS</h2>
          <p>It isthe express intention of the Parties to this Contract that the Contractor is an independent
contractor, and is not an employee, associate, agent, joint-venture, or partner of the
Company. Nothing in this Contract shall be interpreted or construed as creating or
establishing an employment relationship between the Company and the individual
Contractor. The Contractor understands and agrees that Contractor is not entitled to
workers' compensation benefits and that Contractor is obligated to pay Federal and State
Income Tax on any moneys earned pursuant to this Contract</p>
          <p>The Contractor has no power or authority to act for, represent, or bind the Company or any
company affiliated with it in any manner. If the Company is required to pay or withhold
any taxes or make any other payment with respect to fees payable to the Contractor, then
such Contractor will reimburse the Company in full for taxes or payment made, and permit
the Company to make deductions for taxes or payments required to be withheld from any
sum dues to the Contractor</p>
        </li>
        <li>
          <h2 style={{paddingTop:"15px"}}>8. WARRANTIES</h2>
          <p>The Contractor warrants that he/she has provided similar Services as those provided to the
Company under this Contract and further warrants that the Contractor has the skills and
expertise to perform the services as mentioned under this Contract.</p>
        </li>
        <li>
          <h2 style={{paddingTop:"15px"}}>7. DISPUTE SETTLEMENT</h2>
          <p>Any controversy or claim arising out of or relating to this Contract, or the breach thereof,
shall be settled by arbitration administered by the International Arbitration Rules, and
judgment on the award rendered by the arbitrator(s) may be entered in any court having jurisdiction thereof.</p>
        </li>
        <li>
          <h2 style={{paddingTop:"15px"}}>8. ENTIRE UNDERSTANDING</h2>
          <p>This Contract, including the recitals, and the attached Exhibits, or any other attachment
incorporated herein by reference, set forth the entire Contract and understanding of the
parties relating to its subject matter, and supersede and merge all prior and
contemporaneous Contracts, negotiations and understandings between the parties, both
oral and written and can only be modified by a subsequent written Contract executed by
the Contractor and a duly authorized officer of the Company.</p>
        </li>
        <li>
          <h2 style={{paddingTop:"15px"}}>9. SEVERABILITY</h2>
          <p>Should any portion of this Contract be judicially determined to be illegal or unenforceable,
the remainder of the Contract shall continue in full force and effect, and either party may
renegotiate the terms affected by the severance.</p>
        </li>
        <li>
          <h2 style={{paddingTop:"15px"}}>10. NOTICES</h2>
          <p>Any notices to be given hereunder by either Party to the other shall be effected in writing
either by email, certified or registered mail postage prepaid, overnight courier or personal
delivery. Certified and registered mail and overnight courier shall be sent with return
receipt requested.</p>
        </li>
        <li>
          <h2 style={{paddingTop:"15px"}}>11. APPLICABLE LAWS</h2>
          <p>The laws and court of the State of Indiana, USA are applicable in case of dispute nonsolved inthis Agreement.</p>
        </li>
      </ul>
      </div>
    </div>
    <Footer />
  </>
);

export default (IndependentContractorServiceContract);
