import React, {useState, useEffect} from 'react'
import Card from 'react-bootstrap/Card'
import {Tile} from 'Components/Tile/Tile'
import ScrollMenu from 'react-horizontal-scrolling-menu';
import {IoIosArrowForward, IoIosArrowBack} from 'react-icons/io'
import avi from 'Static/default-avi.png';
import s from '../styles/Dashboard.module.scss'
import { CollegeCard, MentorCard } from 'Components/Tile/Card';
import { ids } from 'Utils/idToCollege'
import {isMentor, isMentee, isPartner } from 'Utils/sessions'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { Redirect } from 'react-router-dom'
import awardIcon from 'Static/award_icon.svg'
import circleIcon from 'Static/circle.svg'
import circleOutlineIcon from 'Static/circle_outline.svg'
import {countries} from 'Utils/regions'
import { majors } from 'Utils/majors';
import { TextInput, TextAreaInput } from 'Input/Input';
import { calculateTimeUntilDuration, isActiveMeeting, getTimezone } from 'Utils/time'
import PaymentDropIn from './PaymentDropIn'
import { Root } from 'Root';
import {AiFillStar} from 'react-icons/ai'
import {FaGraduationCap, FaLink} from 'react-icons/fa'
import ModalHeader from 'react-bootstrap/esm/ModalHeader';
import {VscCircleFilled} from 'react-icons/vsc'

export const ContractCard = ({contract}) => {
  return <Card className={``} >
  <Card.Body>
    <Card.Title className='fs-16 trunc' onClick={()=> window.location.href = '/job/' + contract.jobPosting.id} style={{cursor:'pointer'}}>{contract.jobPosting.title}</Card.Title>
      <Card.Text className='text-muted trunc fs-13'>
      {contract.jobPosting.compensationDescription} 
      <VscCircleFilled size={7}/>
       {` ${contract.jobPosting.location || '-'} `} 
       <VscCircleFilled size={7}/>
        {` ${contract.jobPosting.timeCommitment || (contract.jobPosting.fullTime ? 'Full Time' : 'Part Time')} `}</Card.Text>
        <Button>Request Payment</Button>
  </Card.Body>
  </Card>
}

const UserDetails = ({profile}) => {
  const viewProfile = () => {return window.location.href = '/profile/student/' + profile.userId}
  return <div>
    <p className='text-muted mb-1 fs-14'>Bio</p>
    <p className='fs-14'>{profile.bio}</p>
    <div className='d-flex justify-content-between align-items-center'>
      <span>
        <p className='text-muted mb-1 fs-14'>Rating</p>
        <p className='green'><AiFillStar/> {profile.rating}</p>
      </span>
      <span>
        <p className='text-muted mb-1 fs-14'>Location</p>
        <p className='trunc blue fs-14'>{profile.city && profile.city !== 'N/A'  && `${profile.city}, `}
        {profile.country ==='US' && profile.state !== 'N/A' && profile.state} {profile.country && countries[profile.country]}</p>
      </span>
    </div>
    <p className='text-muted mb-1 fs-14'>Field of Study</p>
    <p className='trunc fs-14'>{majors[profile.major]}</p>
    {profile.studentType === 'CURRENT' && <Button onClick={viewProfile} className='btnLink d-block ps-0'>View Full Profile</Button>}
  </div>
};

const JoinMeetingProspect = ({session, meeting}) => {
  const [hasPayed, setHasPayed] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null)
  const [discount, setDiscount] = useState(0);
  const [promo, setPromo] = useState(null);
  const [meetingToken, setMeetingToken] = useState(null)
  const [redirectMeeting, setRedirectMeeting] = useState(null)
  const [promoFormErrors, setPromoFormErrors] = useState({})
  const countdown = calculateTimeUntilDuration(meeting.date, meeting.startTime);
  const moment = require('moment-timezone');

  useEffect(() => {
      const updateHasPayed = () => {
        Root.collegeApi.hasPayed(session.id, meeting.id).then(rsp => {
            setHasPayed(rsp.data)
          }
        ).catch(() =>
          setErrorMessage("Unable to determine payment of meeting")
        )
      }
      updateHasPayed()
  }, [])

  useEffect(() => {
      if(meeting.price - discount <=0){
        setHasPayed(true);
      }
  }, [discount])

  function handleValidatePromo (event) {
      var { name, value } = event.target
      setPromoFormErrors({});
      setPromo(value);

      if (value) {
        Root.collegeApi.validatePromoCode(session.id, value, meeting.id).then(rsp => {
            setDiscount(rsp.data)
            if (rsp.data <= 0) {
              setPromoFormErrors({promo: 'Invalid code'})
            }
        }).catch(() =>
            setPromoFormErrors({promo: 'Error validating code, please contact support for assistance'})
        )
      }
    }

  function joinMeeting(ip){
      return Root.collegeApi.joinMeeting(session.id , meeting.id, ip).then(rsp => {
            setMeetingToken(rsp.data)
            setRedirectMeeting(true);
        }).catch(() =>
            setErrorMessage("Unable to join meeting")
        )
  }

  function handleJoin() {
      fetch('https://geoip-db.com/json/')
          .then(res => res.json())
          .then(data => data.IPv4)
          .then(ip => {
          return joinMeeting(ip)
          }).catch(err => {
          return joinMeeting("127.0.0.1")
          });
  }

  if (redirectMeeting) {
      return <Redirect to={{
          pathname: '/meeting',
          state: {
            data: {
                token: meetingToken,
                meeting: meeting,
                timezone: getTimezone()
            }
          }
      }} />
  } else {

  return <>
      {!hasPayed && countdown <=  moment.duration(10, "minutes") && meeting.status === 'SCHEDULED' && <>
          <p className='mt-3'><strong>Payment</strong></p>
              <TextInput errors={promoFormErrors} name={'promo'} value={promo} onChange={handleValidatePromo} label={'Promo Code'}/>
              <PaymentDropIn session={session} meetingId={meeting.id} setHasPayed={setHasPayed} promo={promo} />
      </> }
      {meeting.status !== 'PROPOSED' && <Button
          disabled={!isActiveMeeting(meeting.date, meeting.startTime, meeting.duration) || !hasPayed}
          onClick={handleJoin}
          className='btnFill wide mt-3 md'> Join Meeting </Button>}
      {errorMessage && <p className='form-error'>{errorMessage}</p>}
      </>
  }
}

const JoinMeetingMentor = ({session, meeting}) => {
  const [errorMessage, setErrorMessage] = useState(null)
  const [meetingToken, setMeetingToken] = useState(null)
  const [redirectMeeting, setRedirectMeeting] = useState(null)

  function joinMeeting(ip){
    return Root.collegeApi.joinMeeting(session.id , meeting.id, ip).then(rsp => {
          setMeetingToken(rsp.data)
          setRedirectMeeting(true);
      }).catch(() =>
          setErrorMessage("Unable to join meeting")
      )
  }

  function handleJoin() {
    fetch('https://ifconfig.me/all.json')
      .then(res => res.json())
      .then(data => data.ip_addr)
      .then(ip => {
        return joinMeeting(ip)
      }).catch(err => {
        return joinMeeting("127.0.0.1")
      });
    }


  if (redirectMeeting) {
      return <Redirect to={{
          pathname: '/meeting',
          state: {
          data: {
              token: meetingToken,
              meeting: meeting,
              timezone: getTimezone()
          }
       }
      }} />
  }

  return <>
       {meeting.status !== 'PROPOSED' && <Button onClick={handleJoin} disabled={!isActiveMeeting(meeting.date, meeting.startTime, meeting.duration)} className={'btnOutline wide mt-3 md'}>Join Meeting</Button>}
      <p className='form-error text-center'>{errorMessage}</p>
  </>
}

const ManageMeeting = ({meeting, session}) => {
  const [errorMessage, setErrorMessage] = useState(null)

  function handleConfirm(){
      setErrorMessage(null)
      let data = {
          prospectiveStudent: meeting.prospectiveStudent,
          currentStudent: meeting.currentStudent,
          date: meeting.date,
          time: meeting.startTime,
          meetingId: meeting.id
      }

      Root.collegeApi.confirmMeeting(data).then(rsp => {
        window.location.reload()
      }).catch((err) => {
      if (err.response && err.response.data){
        setErrorMessage(err.response.data)
      } else{
        setErrorMessage("Unable to confirm meeting")
      }
    })
  }

  function handleCancel(){
      setErrorMessage(null)
      Root.collegeApi.cancelMeeting(meeting.id, session.id, '').then(rsp => {
          window.location.reload();
      }).catch(error =>
        setErrorMessage("Something went wrong, please try again")
      )
  }


  return <>
  <div>
    <span>
     {meeting.status === 'PROPOSED' && isMentor(session) &&
      <Button onClick={handleConfirm} className='btnOutline md'>Accept Request</Button>}
      {meeting.status !== 'COMPLETED' && <Button onClick={handleCancel} className='btnFill md red ms-3'>Cancel Meeting</Button>}
    </span>
      <p className='form-error mb-0 mt-1'>{errorMessage}</p>
  </div>
</>}

export const MeetingModal = ({show, onHide, meeting, session}) => {
  const [details, setDetails] = useState({});
  const [error, setError] = useState();
  const [applicant, setApplicant] = useState({})
  const [student, setStudent] = useState({})
  const [key, setKey] = useState('notes')

  useEffect(()=> {
      Root.collegeApi.getMeetingById(meeting.id).then((rsp) => {
          setDetails(rsp.data)
      }).catch(()=>{setError('Unable to get meeting details')})
  },[])

  useEffect(()=> {
      Root.userApi.getUserProfile(meeting.currentStudent).then((rsp) => {
          setStudent(rsp.data)
      }).catch((err)=> {console.log(err)})

      Root.userApi.getUserProfile(meeting.prospectiveStudent).then((rsp) => {
        setApplicant(rsp.data)
    }).catch((err)=> {console.log(err)})
  },[])

  return <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
      <Modal.Title>Meeting Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{border:'1px solid #EBEBEC', padding:'20px', borderRadius:'20px'}}>
          {(isMentee(session) || isPartner(session)) && <><span className='d-flex justify-content-between'>
            <span>
              <img src={student.photo || avi} style={{height:'50px', width:'50px', borderRadius:'50%', objectFit:'cover'}} />
              <span className='ms-2'>{details.currentStudentName}</span>
            </span>
          </span>
          </>}
          {isPartner(session) && <hr/>}
          {(isMentor(session) || isPartner(session)) &&<span className='d-flex justify-content-between'>
            <span>
              <img src={applicant.photo || avi} style={{height:'50px', width:'50px', borderRadius:'50%', objectFit:'cover'}} />
              <span className='ms-2'>{details.prospectiveStudentName}</span>
            </span>
          </span>}
        </div>

        <p className='mt-4 mb-1'><strong>Meeting Info</strong></p>
        <div style={{border:'1px solid #EBEBEC', padding:'20px', borderRadius:'20px'}}>
          {(isMentee(session) || isPartner(session)) && <><span className='d-flex justify-content-between'>
            <span className='fs-14'>School</span>
            <span className='fs-14'>{ids[meeting.ipedsid] || '-'}</span>
          </span>
          <hr/></>}
          <span className='d-flex justify-content-between'>
            <span className='fs-14'>{isMentor(session) ? 'Rate' : 'Price'}</span>
            <span className='fs-14'>{isMentor(session) ? (`$${details.compensation || ' -'}`) : (`$${details.price || ' -'}`)}</span>
          </span>
          <hr/>
          <span className='d-flex justify-content-between'>
            <span className='fs-14'>Time</span>
            <span className='fs-14'>{formatIso(meeting.date, meeting.startTime)}</span>
          </span>
          <hr/>
          <span className='d-flex justify-content-between'>
            <span className='fs-14'>Duration</span>
            <span className='fs-14'>{details.duration} min</span>
          </span>
          <hr/>
          <span className='d-flex justify-content-between'>
            <span className='fs-14'>Call Type</span>
            <span className='fs-14'>{details.primaryCommunication}</span>
          </span>
        </div>
        <div className='mt-3'>
          <Button onClick={()=> setKey('notes')} className={`btnLink ${key !== 'notes' && 'grey'}`}><strong>Notes</strong></Button>
          {(isMentor(session) || isPartner(session)) &&<Button onClick={()=> setKey('aboutApplicant')} className={`btnLink ps-0 ${key !== 'aboutApplicant' && 'grey'}`}><strong>About {applicant.firstName}</strong></Button>}
          {(isMentee(session) || isPartner(session)) && <Button onClick={()=> setKey('aboutStudent')} className={`btnLink ps-0 ${key !== 'aboutStudent' && 'grey'}`}><strong>About {student.firstName}</strong></Button>}
          <div style={{border:'1px solid #EBEBEC', padding:'20px', borderRadius:'20px'}}>
            {key === 'notes' && <p className='text-muted fs-14'>{details.description || 'No meeting notes'}</p>}
            {key === 'aboutApplicant' && <UserDetails profile={applicant}/>}
            {key === 'aboutStudent' && <UserDetails profile={student}/>}
          </div>
        </div>
        {error && <p className='form-error mt-2'>{error}</p>}
        {isMentee(session) && <JoinMeetingProspect session={session} meeting={meeting}/>}
        {isMentor(session) && <JoinMeetingMentor session={session} meeting={meeting}/>}
      </Modal.Body>
      <Modal.Footer>
        {!isPartner(session) && <ManageMeeting session={session} isMentor={isMentor(session)} meeting={meeting}/>}
      </Modal.Footer>
  </Modal>
}

function formatIso(date, startTime){
  var moment = require('moment-timezone');
  const dateString = date + 'T' + startTime + ':00.000'
  const res = moment.utc(dateString).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).locale('en').format("dddd, MMMM Do YYYY, h:mm a");
  return res
}

export const MeetingCard = ({meeting, setActiveMeeting, session, className}) => {
  const localDateTime = formatIso(meeting.date, meeting.startTime)

  return <Card className={`medCard ${s.meetingCard} ${className}`} onClick={()=>{setActiveMeeting(meeting)}}>
  <Card.Body>
  <Card.Text className='mb-0 trunc fs-16'>
    {isPartner(session) && <span className='d-block trunc fs-13 mb-1'>{meeting.currentStudentName} & {meeting.prospectiveStudentName}</span>}
    {isMentor(session) && <span className='d-block trunc fs-13'> {meeting.prospectiveStudentName}</span>}
    {isMentee(session) && <span className='d-block trunc fs-13'>{meeting.currentStudentName}</span>}
    <span className='d-block trunc fs-13 mb-1'><strong><FaGraduationCap className='purple me-1'/>{ids[meeting.ipedsid] || 'Unknown University' }</strong></span>
  </Card.Text>
  <p className="text-muted fs-13 mb-0 trunc">{localDateTime}</p>
  </Card.Body>
</Card>
}

export const MeetingList = ({meetings, session, setActiveMeeting}) => {
    return meetings.length > 0 ? <div className='horScroll py-2'>
      {meetings.map((m, idx) => {
        return <MeetingCard className={s.meetingListCard} meeting={m} session={session} setActiveMeeting={setActiveMeeting}/>
      })}
    </div>
     : <p className='text-muted fs-13'>No Meetings</p>
};

export const GroupScrollList = ({groups}) => {
    const [selected, setSelected] = useState(1);
    const groupList = groups.map((g, idx) => {
        return <Card key={idx} className={'medCard mr-3'}>
        <Card.Body>
          <Card.Title className='trunc'>{g.type}</Card.Title>
          <Card.Text className='trunc'>{g.school}</Card.Text>
          <div className='d-flex justify-content-between'>
            <Card.Subtitle className="text-muted">{g.members} members</Card.Subtitle>
            <Card.Subtitle className="text-muted">{g.posts} posts</Card.Subtitle>
          </div>
        </Card.Body>
      </Card>
      });

    return groupList.length > 0 ? <ScrollMenu
    scrollToSelected
        hideSingleArrow
        data={groupList}
        arrowLeft={<IoIosArrowBack/>}
        arrowRight={<IoIosArrowForward/>}
        selected={selected}
        onSelect={(e)=>setSelected(e)}
    /> : <p className='text-muted'>No Groups</p>
};

export const MentorScrollList = ({mentors}) => {
    const [selected, setSelected] = useState(1);
    const mentorList = mentors.map((m, idx) => {
        return <MentorCard key={idx} mentor={m}/>
      });

    return <ScrollMenu
        scrollToSelected
        hideSingleArrow
        data={mentorList}
        arrowLeft={<IoIosArrowBack/>}
        arrowRight={<IoIosArrowForward/>}
        selected={selected}
        onSelect={(e)=>setSelected(e)}
    />
};

export const ClassList = ({mentors}) => {
    const [selected, setSelected] = useState(1);
    const mentorList = mentors.map((m, idx) => {
        return <Card key={idx} className={`smCard mr-4 ${s.card}`}>
        <Card.Body>
          <img className={s.avi} src={avi} alt='avi'/>
          <Card.Text className={'mt-2 mb-1'}>{m.name}</Card.Text>
          <Card.Text className={'mb-0'}>{m.rating}</Card.Text>
          <Card.Text className='trunc'>{m.school}</Card.Text>
          <Card.Text className='trunc'><Tile color={'green'}>{m.major}</Tile></Card.Text>
        </Card.Body>
      </Card>
      });

    return <ScrollMenu
        scrollToSelected
        hideSingleArrow
        data={mentorList}
        arrowLeft={<IoIosArrowBack/>}
        arrowRight={<IoIosArrowForward/>}
        selected={selected}
        onSelect={(e)=>setSelected(e)}
    />
};

export const ClubList = ({mentors}) => {
    const [selected, setSelected] = useState(1);
    const mentorList = mentors.map((m, idx) => {
        return <Card key={idx} className={`smCard mr-4 ${s.card}`}>
        <Card.Body>
          <img className={s.avi} src={avi} alt='avi'/>
          <Card.Text className={'mt-2 mb-1'}>{m.name}</Card.Text>
          <Card.Text className={'mb-0'}>{m.rating}</Card.Text>
          <Card.Text className='trunc'>{m.school}</Card.Text>
          <Card.Text className='trunc'><Tile color={'green'}>{m.major}</Tile></Card.Text>
        </Card.Body>
      </Card>
      });

    return <ScrollMenu
        scrollToSelected
        hideSingleArrow
        data={mentorList}
        arrowLeft={<IoIosArrowBack/>}
        arrowRight={<IoIosArrowForward/>}
        selected={selected}
        onSelect={(e)=>setSelected(e)}
    />
};

export const CollegeScrollList = ({colleges}) => {
    const [selected, setSelected] = useState(1);
    const collegeList = colleges.map((c, idx) => {
        return <CollegeCard key={idx} college={c}/>
      });
    return <ScrollMenu
        scrollToSelected
        hideSingleArrow
        data={collegeList}
        arrowLeft={<IoIosArrowBack/>}
        arrowRight={<IoIosArrowForward/>}
        selected={selected}
        onSelect={(e)=>setSelected(e)}
    />
};

export const CareerCard = ({job, type}) => {
  const [show, setShow] = useState(false)
  return <><Card className={`mt-2 xlCard`} onClick={()=> setShow(true)} style={{cursor:'pointer'}}>
    <Card.Body>
    <div className='d-flex'>
      <img src={type === 'experiences' ? circleIcon : circleOutlineIcon}/>
      <div className='ms-2 d-grid'>
        <Card.Title className='fs-16 mb-0 trunc'>{job.role} {job.companyName && `at ${job.companyName}`}</Card.Title>
        <Card.Text className='text-muted fs-13 mb-0'>{job.startDate.split('-')[0]} to {job.endDate ? job.endDate.split('-')[0] : 'present'}</Card.Text>
        {job.details && <Card.Text className='fs-13 trunc'>{job.details}</Card.Text>}
      </div>
    </div>
    </Card.Body>
  </Card>

  <Modal show={show} onHide={()=> setShow(false)}>
    <Modal.Header closeButton>
      <Modal.Title>
        {job.role} {job.companyName && `at ${job.companyName}`}
        <span className='d-block fs-13 text-muted mt-1'>{job.startDate} to {job.endDate || 'present'}</span>
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p>{job.details || 'No description'}</p>
    </Modal.Body>
  </Modal>
</>
};

export const FeaturedCard = ({work}) => {
  const [show, setShow] = useState(false)
  return <><Card className={`mt-2 xlCard`}>
    <Card.Body>
    <div className='d-flex'>
      <div className='ms-2 d-grid'>
        <Card.Title className='fs-16 mb-0 trunc'><a href={work.url} className="btnLink" target="_blank">{work.title} <FaLink/> </a></Card.Title>
        {work.description && <Card.Text style={{cursor:'pointer'}} onClick={()=> setShow(true)} className='fs-13 trunc'>{work.description}</Card.Text>}
      </div>
    </div>
    </Card.Body>
  </Card>

  <Modal show={show} onHide={()=> setShow(false)}>
    <Modal.Header closeButton>
      <Modal.Title>
        {work.title}
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p><a className='btnLink fs-14' target="_blank" href={work.url}><FaLink/> Link</a> - {work.description || 'No description'}</p>

    </Modal.Body>
  </Modal>
</>
};

export const ScholarshipCard = ({award}) => {
  return <Card className={`mt-2 xlCard`}>
  <Card.Body>
    <div className='d-flex'>
      <img src={awardIcon}/>
      <div className='ms-2 d-grid'>
        <Card.Title className='fs-16 trunc mb-0'>{award.title}</Card.Title>
        <Card.Text className='fs-13 trunc mb-0'>{award.program} </Card.Text>
        <Card.Text className='text-muted mb-0 fs-13 trunc'>{award.month} {award.year}</Card.Text>
      </div>
    </div>
  </Card.Body>
</Card>
};

export const ReviewCard = ({review}) => {
  const [show, setShow] = useState(false)
  return <><Card className={`mt-2 xlCard`} onClick={()=> setShow(true)} style={{cursor:'pointer'}}>
  <Card.Body>
    <div className='d-flex'>
    <img src={circleOutlineIcon}/>
      <div className='ms-2 d-grid'>
        <Card.Text className='text-muted mb-0 fs-13 trunc'>Rating: {review.rating}/5</Card.Text>
        {review.review.length >0 ? <Card.Text className='fs-13 trunc mb-0'>{review.review}</Card.Text> :
        <p className='text-muted fs-13 mb-0'>No additional comment</p>}
      </div>
    </div>
  </Card.Body>
</Card>

<Modal show={show} onHide={()=> setShow(false)}>
    <Modal.Body>
      <p>Rating: {review.rating}/5</p>
      <p>{review.review || 'No comment'}</p>
    </Modal.Body>
  </Modal>

</>
};

export const RequestPaymentCard = ({contract, className, callback}) => {
  const [request, setRequest] = useState({})
  const [error, setError] = useState(null)
  const [formErrors, setFormErrors] = useState({})
  const [loading, setLoading] = useState(false)
  const [show, setShow] = useState(false)
  const [showCompleted, setShowCompleted] = useState(false)
  const [contractError, setContractError] = useState(null)
  const [contractLoading, setContractLoading] = useState(false)
  const [success, setSuccess] = useState(false)

  function handleChange(event) {
    const { name, value } = event.target;
    setRequest((prevState) => ({ ...prevState, [name]: value }));
  }

  function handleSubmit() {
    setError(null)
    setFormErrors({})
    setLoading(true)
    setSuccess(false)

    if(!request.amountRequested) {
      setFormErrors({amountRequested: 'This value is required'})
      setLoading(false)
      return
    }

    Root.collegeApi.sendInvoice(contract.id, request).then(()=>{
      setSuccess(true); setLoading(false); setRequest({});
    }).catch(()=> {setError("Error requesting payment. Please try again."); setLoading(false);})
  }

  function handleComplete() {
    setContractLoading(true)
    Root.careerApi.completeContract(contract.id)
    .then(()=> {callback(); setContractLoading(false);})
    .catch(()=> {setContractError("An error has occured, please try again later"); setContractLoading(false);})
  }
  
  return <><Card className={`mt-2 ${className || 'xlCard'}`} style={{cursor:'pointer'}}>
  <Card.Body>
    <Card.Text className="d-flex fs-14 justify-content-between mb-1">
      <span>{contract.job.company.name}</span>
      <span className='purple'>{contract.job.title}</span>
    </Card.Text>
    <Card.Text className='trunc fs-13 mb-1 text-muted d-flex fs-14 justify-content-between'>
      <span>{contract.job.compensationDescription}</span>
      <span>{contract.state}</span>
    </Card.Text>
      {contract.job.isPaid && <Button className='btnFill wide mt-1' onClick={()=> setShow(true)}>Request Payment</Button>}
      {<Button className='btnOutline wide mt-1' disabled={contract.state === 'COMPLETED'} onClick={()=> setShowCompleted(true)}>Mark As Completed</Button>}
  </Card.Body>
</Card>

  <Modal show={show} onHide={()=> {setShow(false); setRequest({}); setSuccess(false);}}>
    <ModalHeader closeButton>
        <Modal.Title>Request Payment</Modal.Title>
      </ModalHeader>
    <Modal.Body>
      <TextInput containerClassName='mb-2' errors={formErrors} name="amountRequested" value={request.amountRequested || ''} onChange={handleChange} label="* Amount ($USD) There will be a 5% service fee applied." type="number" />
      <TextInput containerClassName='mb-2' errors={formErrors} name="hoursWorked" value={request.hoursWorked || ''} onChange={handleChange} label="Hours Worked (If paid per hour)" type="number" />
      <TextAreaInput containerClassName='mb-2 pb-2' errors={formErrors} name="memo" value={request.memo || ''} onChange={handleChange} label="Notes" placeholder={'Any notes that could help the employer understand the requested amount and/or scope of work'} maxLength={2000}/>
    </Modal.Body>
    <Modal.Footer>
      {error && <p className='form-error'>{error}</p>}
      {success && <Tile className='fs-13' color='purple'>Invoice Sent</Tile>}
      <Button className='btnFill lg' disabled={loading} onClick={handleSubmit}>Send Invoice</Button>
    </Modal.Footer>
  </Modal>

  <Modal show={showCompleted} onHide={()=> {setShowCompleted(false);}}>
    <ModalHeader closeButton>
        <Modal.Title>Complete Contract</Modal.Title>
      </ModalHeader>
    <Modal.Body>
      <p>Are you sure you would like to mark this contract as completed? Marking a contract as completed indicates that all contracted work has been completed and payment has been received.</p>
    </Modal.Body>
    <Modal.Footer>
      {contractError && <p className='form-error'>{contractError}</p>}
      <Button className='btnFill lg purple' disabled={contractLoading} onClick={handleComplete}>Mark Completed</Button>
    </Modal.Footer>
  </Modal>

</>
};

export const InvoiceCard = ({invoice, getInvoices}) => {
  const [rejectError, setRejectError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [showReject, setShowReject] = useState(false)

  function handleReject() {
      setLoading(true)
      setRejectError(null)
      Root.collegeApi.updateInvoiceStatus(invoice.id, 'VOID')
      .then(()=> {getInvoices(); setShowReject(false); setLoading(false)})
      .catch((err)=>{console.log(err); setLoading(false); setRejectError("Something went wrong, please try again later.");})
  }

  return <><Card className={`mt-2 xlCard`}>
  <Card.Body>
    <Card.Text className="d-flex fs-14 justify-content-between mb-0">
      <span>{invoice.contractedProject.job.company.name} </span>
      <span className='purple'>${invoice.amountRequested}</span>
    </Card.Text>
    <Card.Text className="d-flex fs-13 justify-content-between mb-0">
       <span className='text-muted'>{invoice.contractedProject.job && invoice.contractedProject.job.title} {invoice.hoursWorked && <span className='text-muted'>{`(${invoice.hoursWorked} hrs)`}</span>}</span>
       <span className='text-muted'>{invoice.state}</span>
    </Card.Text>
      <Button disabled={invoice.state !== 'PENDING'} className='btnFill mt-1 me-1 red wide' onClick={()=>setShowReject(true)}>Cancel</Button>
  </Card.Body>
</Card>

  <Modal show={showReject} onHide={()=> {setShowReject(false); setRejectError(null);}}>
      <Modal.Header closeButton>
          <Modal.Title>Cancel Invoice</Modal.Title>
          </Modal.Header>
      <Modal.Body>
          <p>Are you sure you would like to cancel invoice to{' '}
              <strong>{invoice.contractedProject.job.company.name}</strong> for{' '}
              <strong>${invoice.amountRequested}</strong> for the <strong>{invoice.contractedProject.job.title}</strong> position?</p>
      </Modal.Body>
      <Modal.Footer>
          {rejectError && <p className='form-error'>{rejectError}</p>}
          <Button className='btnFill md red' disabled={loading} onClick={handleReject}>Cancel Invoice</Button>
      </Modal.Footer>
  </Modal>
</>
};
