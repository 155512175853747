import React, { useState } from 'react'
import styles from '../styles/University.module.scss'
import { RatingInput } from 'Input/Input'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { Root } from 'Root'
import { fields } from '../University'

const ReviewForm = ({closeModal, session, ipedsid, fetchReviews}) => {
  const [review, setReview] = useState({})
  const [formResponse, setFormResponse] = useState({})
  const scaleOptions = [1, 2, 3, 4, 5]

  function handleChangeReview (event) {
    const { name, value } = event.target
    setReview(prevState => ({ ...prevState, [name]: value }))
  }
  Array.prototype.contains = function (array) {
    return array.every(function (item) {
      return this.indexOf(item) !== -1
    }, this)
  }

  function handleSubmit () {
    setFormResponse({})

    if (!Object.keys(review).contains(Object.keys(fields))) {
      setFormResponse({ error: 'Please complete required fields.' })
      return
    }

    Root.collegeApi.reviewCollege(review, ipedsid, session.id).then(res => {
      if (res.status === 200) {
        setReview({})
        closeModal()
        fetchReviews()
      }
    }).catch(() => {
      setFormResponse({ error: 'Something went wrong, please contact support if error persists.' })
    })
  }
  return <>
    <Modal.Header closeButton>
        <Modal.Title><strong>Write Review</strong></Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Form>
        {Object.keys(fields).map((key, idx) => <div className='d-flex align-items-center mb-2' key={idx}>
          <p className='mb-0'>{fields[key]}</p>
          <RatingInput containerClassName={styles.scaleContainer} className={`${styles.scale}`} activeStyle={styles.active} options={scaleOptions} 
          fill={'rgba(237, 156, 60,0.2)'} errors={{}} name={key} value={review[key] || ''} onClick={handleChangeReview} />
        </div>)}
        <Form.Group>
          <p>Anything you would like to add? (Optional)</p>
          <Form.Control as="textarea" rows="3" maxLength="255" name={'comment'} value={review.comment || ''} onChange={handleChangeReview}/>
        </Form.Group>
        {formResponse.error && <p className='form-error'>{formResponse.error}</p>}
        <Button onClick={handleSubmit} className={'btnFill wide mt-3'}>Submit</Button>
      </Form>
    </Modal.Body>
  </>
}

export default (ReviewForm)
