import axios from 'axios'
import * as vars from './vars';
import {getTimezone} from 'Utils/time';

// get all connections for a userId
export function getAllConnections(userId, state=null) {
    return axios({
        method: 'GET',
        url: vars.baseURL + '/api/v2/connections',
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        params: {
            'userId': userId,
            'state': state
        }
    })
}


// get a connection between two users
export function getConnection(userId, otherUserId) {
    return axios({
        method: 'GET',
        url: vars.baseURL + '/api/v2/connections/connection',
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        params: {
            'userId': userId,
            'otherUserId': otherUserId
        }
    })
}

// respond to a conneciton request
export function respondToConnection(connectionId, userId, state) {
    return axios({
        method: 'PATCH',
        url: vars.baseURL + '/api/v2/connections/' + String(connectionId),
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data: {
            'respondentUserId': userId,
            'state': state
        }
    })
}

// propose an existing user connection
export function proposeExistingUserConnection(userId, respondentUserId) {
    return axios({
        method: 'POST',
        url: vars.baseURL + '/api/v2/connections',
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        params: {
            'userId': userId
        },
        data: {
            'respondentUserId': respondentUserId
        }
    })
}

// propose a future user connection (no account)
export function proposeFutureUserConnection(userId, email) {
    return axios({
        method: 'POST',
        url: vars.baseURL + '/api/v2/connections',
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        params: {
            'userId': userId
        },
        data: {
            'respondentEmail': email
        }
    })
}

// delete a connection
export function deleteExistingConnection(connectionId, userId) {
    return axios({
        method: 'DELETE',
        url: vars.baseURL + '/api/v2/connections/' + String(connectionId),
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        params: {
            'userId': userId
        }
    })
}