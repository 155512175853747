import React from 'react'
import Pagination from 'react-bootstrap/Pagination'

export const CustomPagination = ({ numPages, selected, setSelected, style }) => {
    let maxButtons = 3;
    const Abbreviated = () => {
      return <>
        {selected > 3 && <>
        <Pagination.Item onClick={() => setSelected(1)}>{1}</Pagination.Item>
        <Pagination.Ellipsis disabled/>
        </>}
        {selected > 1 && <Pagination.Item onClick={() => setSelected(selected-1)}>{selected-1}</Pagination.Item>}
        <Pagination.Item active={true}  onClick={() => setSelected(selected)}>{selected}</Pagination.Item>
        {selected <= numPages-1 && <Pagination.Item onClick={() => setSelected(selected+1)}>{selected+1}</Pagination.Item>}

        {selected < numPages-3 && <>
        <Pagination.Ellipsis disabled/>
        {selected < numPages-1 && <Pagination.Item onClick={() => setSelected(numPages)}>{numPages}</Pagination.Item>}
        </>}
      </>
}
  return  <Pagination style={style}>
            <Pagination.First onClick={() => setSelected(1)}/>
            <Pagination.Prev onClick={() => setSelected(Math.max(selected-1,1))}/>
        { maxButtons <= numPages ?
        <Abbreviated/> :
        [...Array(numPages).keys()].map( num =>
        <Pagination.Item active={num+1===selected} onClick={() => setSelected(num+1)}>{num+1}</Pagination.Item>
        )
        }
            <Pagination.Next onClick={() => setSelected(Math.min(selected+1,numPages))}/>
            <Pagination.Last onClick={() => setSelected(numPages)}/>
    </Pagination>
}
