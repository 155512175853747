import React, {useContext, useState, useEffect} from 'react'
import s from './styles/Discover.module.scss'
import SideBar from 'Components/Nav/SideBar';
import {SearchBar} from 'Components/Input/Search';
import { SessionContext, isMentor, isMentee } from 'Utils/sessions';
import { Link } from 'react-router-dom';
import { blogs } from '../Landing/Blog'
import Card from 'react-bootstrap/Card'
import { CollegeCard, MentorCard } from 'Components/Tile/Card';
import { Root } from 'Root';
import {JobCard} from '../Company/Jobs'
import Spinner from 'react-bootstrap/esm/Spinner'
import {Lightbox} from 'Components/Lightbox'

const colleges = [{ipedsid:152080, collegeName:'University of Notre Dame'},
                  {ipedsid:110635, collegeName:'University of California-Berkeley'},
                  {ipedsid:110653, collegeName:'University of California-Irvine'},
                  {ipedsid: 135726, collegeName:'University of Miami'},
                  {ipedsid: 166027, collegeName:'Harvard'},
                  {ipedsid: 110662, collegeName:'University of California-Los Angeles'},
                  {ipedsid: 217156, collegeName:'Brown University'},
                  {ipedsid: 139658, collegeName:'Emory University'},
                  {ipedsid: 123961, collegeName:'University of Southern California'},
                  {ipedsid: 170976, collegeName:'University of Michigan-Ann Arbor'},
                  {ipedsid: 215062, collegeName:'University of Pennsylvania'},
                  {ipedsid: 211440, collegeName:'Carnegie Mellon University'},
                  {ipedsid: 198419, collegeName:'Duke University'},
                ]

const Discover = () => {
  const {session, refreshSession} =  useContext(SessionContext);
  const [students, setStudents] = useState([]);
  const [error, setError] = useState(null);
  const [jobs, setJobs] = useState(null)
  const [jobError, setJobError] = useState(null)
  const [images, setImages] = useState(null)
  const [videos, setVideos] = useState(null)
  const [videoError, setVideoError] = useState(null)
  const [imageError, setImageError] = useState(null)
  const [photoIndex, setPhotoIndex] = useState(null)
  const [videoIndex, setVideoIndex] = useState(null)

  function getNetwork() {
    setStudents(null)
    setError(null)
    Root.careerApi.getNetwork(session.id).then((res)=> {
        setStudents(res.data)
    }).catch(()=> {
        setStudents([])
        setError("Please try again later")})
  }

  useEffect(()=>{
    getNetwork()
    if(isMentor(session)) {
      Root.careerApi.getDiscoveryJobs(session.id).then((res)=> {
        setJobs(res.data)
      }).catch(()=> {
        setJobs([])
        setJobError("Please try again later")
      })
    }
  },[])

  useEffect(()=> {
    if(isMentee(session)) {
      Root.collegeApi.getDiscoveryVideos(session.id).then((res)=> {
        setVideos(res.data)
      }).catch((err)=> {
        setVideos([])
        setVideoError("Please try again later")
      })
    }
  },[])

  useEffect(()=> {
    if(isMentee(session)) {
      Root.collegeApi.getDiscoveryImages(session.id).then((res)=> {
        setImages(res.data)
      }).catch(()=> {
        setImages([])
        setImageError("Please try again later")
      })
    }
  },[])

  return <div className={s.discover}>
        <SideBar session={session} title={"Discover"}>
          <SearchBar containerClassName={`mb-5 ${s.search}`}/>

          {isMentor(session) && <div className={s.row}>
            <h3 className='heading'>Recent Job Postings <Link className='btnLink' to="/jobs?pg=1">></Link></h3>
            <div className='horScroll'>
              {jobs && jobs.map((j, idx) => {
                   return <JobCard job={j} className='my-4 medCard me-4'/>
              })}
              {!jobError && jobs && jobs.length == 0 && <p className='text-muted fs-13'>No recent postings</p>}
              {jobError && <p className='text-muted fs-13'>{jobError}</p>}
              {!jobs && <Spinner style={{color:"rgb(16, 142, 115)"}} animation="border" role="status"/>}
            </div>
          </div>}

          <div className={s.row}>
            <h3 className='heading'>Popular Schools</h3>
            <div className='horScroll'>
              {colleges.map((c, idx) => {
                   return <CollegeCard college={c} className='my-4'/>
              })}
            </div>
          </div>
          <div className={s.row}>
            <h3 className='heading'>NXSTEP Student Network <Link className='btnLink' to="/network">></Link></h3>
            <div className='horScroll'>
                {students && students.map((s)=> {return <MentorCard mentor={s} className={`me-4 my-4 Discover_blogTile__HYLJA`} onClick={()=> window.location.href="/profile/student/" + s.userName}/>})}
                {!error && students && students.length == 0 && <p className='text-muted fs-13'>No students found</p>}
                {error && <p className='text-muted fs-13'>{error}</p>}
                {!students && <Spinner style={{color:"rgb(16, 142, 115)"}} animation="border" role="status"/>}
            </div>
          </div>
        {isMentee(session) && <>
          <div className={s.row}>
            <h3 className='heading'>Recently Uploaded College Pics</h3>
            <div className='horScroll'>
              {imageError && <p className='text-muted fs-13'>{imageError}</p>}
              {!images && <Spinner style={{color:"rgb(16, 142, 115)"}} animation="border" role="status"/>}
              {images && images.map((img, idx) => <div className='d-inline-block my-4' style={{maxWidth:'275px'}}>
                <img alt={img.caption || "image"} style={{height:'170px', width:'256px', objectFit:'cover', borderRadius:'15px'}}
                className={`my-1 me-4 ${s.blogTile}`} src={img.url} onClick={()=> setPhotoIndex(idx)}></img>
                <p className='text-muted fs-13 m-0 p-0 trunc'>{img.collegeName}</p>
              </div>
              )}
            </div>

            {images && <Lightbox
                contentList={images}
                idx={photoIndex}
                onHide={()=>setPhotoIndex(null)}
                setIdx={setPhotoIndex}
            /> }
          </div>

          <div className={s.row}>
            <h3 className='heading'>Recently Uploaded College Videos</h3>
            <div className='horScroll'>
              {videoError && <p className='text-muted fs-13'>{videoError}</p>}
              {!videos && <Spinner style={{color:"rgb(16, 142, 115)"}} animation="border" role="status"/>}
              {videos && videos.map((img, idx) => <div className='d-inline-block my-4' style={{maxWidth:'275px'}}>
              <video alt={img.caption || "image"} style={{height:'170px', width:'256px', objectFit:'cover', borderRadius:'15px'}}
              className={`my-1 me-4 ${s.blogTile}`}  src={img.url} playsInline autoPlay loop muted onClick={()=> setVideoIndex(idx)}/>
              <p className='text-muted fs-13 me-4 p-0 trunc'>{img.caption}</p>
              </div>
              )}
            </div>
            {videos && <Lightbox
                contentList={videos}
                idx={videoIndex}
                onHide={()=>setVideoIndex(null)}
                setIdx={setVideoIndex}
            /> }
          </div>
        </>}
          <div className={s.row}>
            <h3 className='heading'>Blog Posts <Link className='btnLink' to="/blog">></Link></h3>
            <div className='horScroll'>
              {blogs.map((blog, idx) => {
                   return <Card onClick={()=>window.location.href=blog.href} className={`medCard my-4 me-4 ${s.blogTile}`}>
                      <Card.Img style={{ height: '100px', objectFit: 'cover' }} variant="top" src={blog.image} />
                      <Card.Body>
                        <Card.Title className='mb-0 fs-16 trunc'>{blog.title}</Card.Title>
                        <Card.Text className='text-muted trunc fs-13'>{blog.author || 'Anonymous'} | {blog.school || 'Unknown University'}</Card.Text>
                      </Card.Body>
                    </Card>
              })}
            </div>
          </div>
   </SideBar>
</div>
}

export default (Discover);
