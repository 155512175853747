const AWS = require('aws-sdk')
// Configure client for use with Spaces
const spacesEndpoint = new AWS.Endpoint('sfo2.digitaloceanspaces.com')
const s3 = new AWS.S3({
  endpoint: spacesEndpoint,
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY
})

// const Compress = require('compress.js')
// function compressFile(file) {
//   const compress = new Compress()
//   const files = [file]
//   return compress.compress(files, {
//     size: 3, // the max size in MB, defaults to 2MB
//     quality: 1, // the quality of the image, max is 1,
//     maxWidth: 600, // the max width of the output image, defaults to 1920px
//     maxHeight: 600, // the max height of the output image, defaults to 1920px
//     resize: true, // defaults to true, set false if you do not want to resize the image width and height
//   }).then((data) => {
//     const img1 = data[0]
//     const base64str = img1.data
//     const imgExt = img1.ext
//     const file = Compress.convertBase64ToFile(base64str, imgExt)
//     return file
//   })
// }

function completeMultipartUpload(s3, doneParams, uploadParams) {
  s3.completeMultipartUpload(doneParams, function(err, data) {
    if (err) {
      console.log("An error occurred while completing the multipart upload");
      console.log(err);
      uploadParams.OnError();
    } else {
      var delta = (new Date() - uploadParams.StartTime) / 1000;
      console.log('Completed upload in', delta, 'seconds');
      console.log('Final upload data:', data);
      uploadParams.OnSuccess(uploadParams.ContentType);
    }
  });
}

function uploadPart(s3, multipart, partParams, uploadParams, tryNum) {
  var tryNum = tryNum || 1; 
  s3.uploadPart(partParams, function(multiErr, mData) {
    if (multiErr){
      console.log('multiErr, upload part error:', multiErr);
      if (tryNum < maxUploadTries) {
        console.log('Retrying upload of part: #', partParams.PartNumber)
        uploadPart(s3, multipart, partParams, tryNum + 1);
      } else {
        console.log('Failed uploading part: #', partParams.PartNumber)
      }
      return;
    }
    uploadParams.MultipartMap.Parts[this.request.params.PartNumber - 1] = {
      ETag: mData.ETag,
      PartNumber: Number(this.request.params.PartNumber)
    };
    console.log("Completed part", this.request.params.PartNumber);
    console.log('mData', mData);
    var numPartsLeft = Math.ceil(uploadParams.Buffer.size / partSize);

    if (--numPartsLeft > 0) return; // complete only when all parts uploaded

    var doneParams = {
      Bucket: bucket,
      Key: uploadParams.FileKey,
      MultipartUpload: uploadParams.MultipartMap,
      UploadId: multipart.UploadId
    };

    console.log("Completing upload...");
    completeMultipartUpload(s3, doneParams, uploadParams);
  });
}

var partSize = 1024 * 1024 * 5; // Minimum 5MB per chunk (except the last part) http://docs.aws.amazon.com/AmazonS3/latest/API/mpUploadComplete.html
var maxUploadTries = 3;
var bucket = 'cdn.nxstep'

export function uploadImageCDN(buffer, key, errCallback, successCallback){
    // Set the parameters for S3.getBucketCors
    var bucketParams = {Bucket: 'cdn.nxstep'};

    // call S3 to retrieve CORS configuration for selected bucket
    s3.getBucketCors(bucketParams, function(err, data) {
      if (err) {
        console.log("Error", err);
      } else if (data) {
        console.log("Success", JSON.stringify(data.CORSRules));
      }
    });


    var multipartMap = {
      Parts: []
    };

    var multiPartParams = {
      Bucket: bucket,
      Key: key,
      ContentType: buffer.type,
      ACL: 'public-read',
  };
    
    var partNum = 0;
    var uploadParams = {
      StartTime: new Date(),
      Buffer: buffer,
      MultipartMap: multipartMap,
      OnSuccess: successCallback,
      OnError: errCallback,
      FileKey: key,
      ContentType: buffer.type
    }

      console.log("Creating multipart upload for:", key);
      s3.createMultipartUpload(multiPartParams, function(mpErr, multipart){
        if (mpErr) { console.log('Error!', mpErr); return; }
        console.log("Got upload ID", multipart.UploadId);
        console.log(buffer.size)
        // Grab each partSize chunk and upload it as a part
        for (var rangeStart = 0; rangeStart <  buffer.size; rangeStart += partSize) {
          partNum++;
          var end = Math.min(rangeStart + partSize, buffer.size),
          partParams = {
                Body: buffer.slice(rangeStart, end),
                Bucket: bucket,
                Key: key,
                PartNumber: String(partNum),
                UploadId: multipart.UploadId
              };
          uploadParams.End = end
          // Send a single part
          console.log('Uploading part: #', partParams.PartNumber, ', Range start:', rangeStart);
          uploadPart(s3, multipart, partParams, uploadParams);
        }
      });
}

export function deleteImageCDN(image){
    var deleteParams = {
        Bucket: 'cdn.nxstep',
        Key: image
      }
    return s3.deleteObject(deleteParams, function (err, data) {
       if (err) {
        console.log(err)
        }
    })
}

export function makeId (length) {
    var result = ''
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    var charactersLength = characters.length
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
  }

// imgExtension: ['.jpg', '.jpeg', '.gif', '.png'],
// maxFileSize: 5242880,
export function validateFiles (files, maxFileSize, extensions) {
    var errors = []
  
    for (let i = 0; i < files.length; i++) {
      const file = files[i]
      let fileError = {
        name: file.name
      }
      // Check for file extension
      if (!hasExtension(file.name, extensions)) {
        fileError = Object.assign(fileError, {
          type: `${file.name} is not a supported file extension`
        })
        errors.push(fileError)
        continue
      }
      // Check for file size
      if (file.size > maxFileSize) {
        fileError = Object.assign(fileError, {
          type: `Max file size is ${maxFileSize}`
        })
        errors.push(fileError)
        continue
      }
    }
    return errors
  }
  
  /*
    Check file extension (onDropFile)
  */
  function hasExtension (fileName, extensions) {
    const pattern = '(' + extensions.join('|').replace(/\./g, '\\.') + ')$'
    return new RegExp(pattern, 'i').test(fileName)
  }