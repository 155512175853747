import React from 'react';
import { NavBar } from 'Components/Nav/NavBar';
import s from './styles/Blog.module.scss';
import Footer from './Footer';

export const blogs = [ 
  {
    author: "Samantha Steff",
    school: "Columbia University",
    desc: "Columbia University junior, Samantha Seiff, reflects on her experience as a college student in the Big Apple.",
    href: "/blog/college-life-in-nyc",
    title: "College Life in NYC",
    image: "https://cdn.nxstep.co/Skyline_AdobeStock_270697557.jpeg"
  },
  {
    author: "",
    school: "",
    desc: "College student shares their thoughts on attending community college and the transfer process.",
    href: "/blog/transfer",
    title: "Community College to Four Year University",
    image: "https://cdn.nxstep.co/transfer-application.jpg"
  },
  {
    author: "",
    school: "University of California, San Diego",
    desc: "Top 12 must have items for Freshman year",
    href: "/blog/college-essentials",
    title: "12 College Essentials",
    image: "https://mcusercontent.com/9e909094c1e228634b6c348c2/_compresseds/10f0ebbb-ed30-44fd-b16b-42efd5cc4098.jpeg"
  },
    {
    author: "Briana Buckley",
    school: "Rice University",
    desc: "Graduate student Brianna gives her take on the pros and cons of attending grad school.",
    href: "/blog/grad-school",
    title: "Is Grad School Right for Me?",
    image: " https://cdn.nxstep.co/bri-candid-pic.jpeg"
  },
  {
    author: "",
    school: "",
    desc: "Student breaks down the steps you can take as a high schooler, to help get into college.",
    href: "/blog/beating-the-odds",
    title: "How to Beat the Odds",
    image: "https://cdn.nxstep.co/decision-cloud.jpeg"
  }
]

const BlogTile = ({ blog }) => {
  const handleClick = () => window.location.href = blog.href;
  return (
    <div className={`${s.blogTile} my-5`} onClick={handleClick}>
      <div className={s.grid}>
        <img style={{height:'200px', borderRadius:'20px', objectFit:'cover', width:'100%'}} src={blog.image} />
        <div>
          <p>{blog.author || "Anonymous"} | {blog.school || "Unknown University"}</p>
          <h2><strong>{blog.title}</strong></h2>
          <p>{blog.desc}</p>
        </div>
      </div>
    </div>
  );
};

const Blog = () => (
  <>
    <div className={s.blog}>
      <div className={`${s.sectionOne}`}>
        <NavBar dark={true}/>
        <div className={`${s.container} mt-5`}>
          <h1><strong>Blog</strong></h1>
          <h3 className='fs-16'>Curated articles to answer some of your burning college questions</h3>
        </div>
      </div>
      <div className={`${s.sectionTwo} ${s.container} `}>
        {blogs.map((b, idx) => <BlogTile blog={b} />)}
      </div>
    </div>
    <Footer />
  </>
);

export default (Blog);
