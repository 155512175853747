import React, {useState} from 'react'
import s from './styles/Input.module.scss'
import Form from 'react-bootstrap/Form'
import PropTypes from 'prop-types'
import { Typeahead } from 'react-bootstrap-typeahead'
import { FaStar } from 'react-icons/fa'
import ReactPhoneInput from 'react-phone-input-2'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import 'react-day-picker/lib/style.css'
import {
  formatDate,
  parseDate
} from 'react-day-picker/moment'

export const FormErrors = ({ name, errors }) => {
  return <div className={'form-error'}>{errors[name] || null}</div>
}

FormErrors.propTypes = {
  name: PropTypes.string,
  errors: PropTypes.object
}

const defaultStyle = {
  backgroundColor: 'white',
  color: '#949DA6',
  borderRadius: '10px',
  cursor: 'pointer',
}

export const TextInput = ({maxLength, name, onChange, label, type, value, errors, containerClassName, className, placeholder, onBlur, formText }) => {
  return <Form.Group controlId={name} className={`${containerClassName} ${s.textInput}`}>
    {label && <Form.Label>{label}</Form.Label>}
    <Form.Control maxLength={maxLength || "255"} autoComplete="new-password" placeholder={placeholder} className={`${className}`} value={value || ''} type={type} name={name} onChange={onChange} onBlur={onBlur}/>
    {formText && <Form.Text>{formText}</Form.Text>}
    <FormErrors errors={errors} name={name}/>
  </Form.Group>
}

export const NoAutoFillTextInput = ({maxLength, name, onChange, label, type, value, errors, containerClassName, className, placeholder, onBlur, formText }) => {
  return <Form.Group controlId={name} className={`${containerClassName} ${s.textInput}`}>
    {label && <Form.Label>{label}</Form.Label>}
    <Form.Control maxLength={maxLength || "255"} autoComplete="new-password" placeholder={placeholder} className={`${className}`} value={value || ''} type={type} name={name} onChange={onChange} onBlur={onBlur}/>
    {formText && <Form.Text>{formText}</Form.Text>}
    <FormErrors errors={errors} name={name}/>
  </Form.Group>
}

TextInput.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.any,
  errors: PropTypes.object,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  onBlur: PropTypes.func
}

export const TextAreaInput = ({maxLength,  name, onChange, label, type, value, errors, className, containerClassName, placeholder, onBlur, formText }) => {
  return <Form.Group controlId={name} className={`${containerClassName} ${s.textInput}`} style={{width:'100%'}}>
    {label && <Form.Label>{label}</Form.Label>}
    <Form.Control as="textarea" rows="3"  maxLength={maxLength || "600"} placeholder={placeholder} className={`${className}`} value={value || ''} type={type} name={name} onChange={onChange} onBlur={onBlur}/>
    {formText && <Form.Text>{formText}</Form.Text>}
    <FormErrors errors={errors} name={name}/>
  </Form.Group>
}

export const TypeAhead = ({ name, onChange, label, options, type, value, errors, className, placeholder, id, ref }) => {
  return <Form.Group controlId={name}>
    {label && <Form.Label>{label}</Form.Label>}
    <Typeahead id={id} options={options} className={`${className}`} value={value} type={type} name={name} onChange={onChange}
      placeholder={placeholder}/>
    <FormErrors errors={errors} name={name}/>
  </Form.Group>
}

export const SelectInput = ({ name, onChange, label, type, value, errors, options, placeholder, ordered, className, containerClassName }) => {
  // Create items array
  var items = Object.keys(options).map(function (key) {
    return [key, options[key]]
  })
  if (ordered === 'true') {
    // Sort the array based on the second element
    items.sort(function (first, second) {
      return first[1].localeCompare(second[1])
    })
  }
  return <Form.Group className={`${containerClassName}`} controlId={name}>
    {label && <Form.Label>{label}</Form.Label>}
    <Form.Control style={defaultStyle} className={`${className}`} value={value || -1} type={type} name={name} onChange={onChange} as={'select'}>
      <option disabled value={-1}>{placeholder}</option>
      {items.map((item, idx) =>
        <option key= {idx} value={item[0]}>{item[1]}</option>
      )}
    </Form.Control>
    <FormErrors errors={errors} name={name}/>
  </Form.Group>
}

export const RatingInput = ({ name, label, errors, options, onClick, className, containerClassName, activeStyle, fill }) => {
  const [active, setActive] = useState(null)
  return <Form.Group controlId={name}>
    {label && <Form.Label>{label}</Form.Label>}
    <div className={`${containerClassName}`}>
      {options.map((item, idx) =>
        <button key={idx} type="button" name={name} onClick={(e) => { setActive(idx + 1); onClick(e) }}
          value={idx + 1} aria-pressed={(active === idx) ? 'true' : 'false'} className={`${className} btn ${(active && active > idx) ? activeStyle : ''}`}>
          <FaStar fill={fill} style={{ pointerEvents: 'none', fontSize: '1em' }}/>
        </button>
      )}
    </div>
    <FormErrors errors={errors} name={name}/>
  </Form.Group>
}

// https://www.npmjs.com/package/react-phone-input-2
export const PhoneInput = ({ name, onChange, label, errors, disableCountryCode, inputStyle, dropdownStyle, value, placeholder, containerClassName }) => {
  return <Form.Group controlId={name} className={containerClassName}>
    {label && <Form.Label>{label}</Form.Label>}
    <ReactPhoneInput inputStyle={ inputStyle ? inputStyle : defaultStyle} placeholder={placeholder} dropdownStyle={dropdownStyle} disableCountryCode={disableCountryCode}
      country={'us'} onlyCountries={['us']} value={value || ''} regions={['north-america']} onChange={onChange}/>
    <FormErrors errors={errors} name={name}/>
  </Form.Group>
}

// https://react-day-picker.js.org/docs/getting-started
export const DayInput = ({ name, placeholder, onChange, label, value, errors, disabledDays, className, containerClassName, format}) => {
  return <Form.Group controlId={name}>
    {label && <Form.Label>{label}</Form.Label>}
    <div className={`${containerClassName}`} style={{width: '100%'}}>
      <DayPickerInput
      style={{width: '100%'}}
        value={value}
        classname={`${className}`}
        formatDate={formatDate}
        parseDate={parseDate}
        format={format || "MM/DD/YYYY"}
        placeholder={placeholder}
        selectedDays={value}
        onDayChange={onChange}
        disabledDays={disabledDays} />
        <FormErrors errors={errors} name={name}/>
    </div>
  </Form.Group>
}

export const ScaleInput = ({ name, onChange, label, errors, options, onClick, containerClassName }) => {
  const [active, setActive] = useState(null)
  return <Form.Group controlId={name} containerClassName={containerClassName}>
    {label && <Form.Label>{label}</Form.Label>}
    <div className={'d-flex justify-content-between'}>
      {options.map((item, idx) =>
        <button key={idx} type="button" name={name} onClick={(e) => { setActive(idx); onChange(e); onClick(e) }}
          value={idx + 1} aria-pressed={(active === idx) ? 'true' : 'false'} className={`${(active === idx) ? s.activeScale : s.inactiveScale}`}>{item}</button>
      )}
    </div>
    <FormErrors errors={errors} name={name}/>
  </Form.Group>
}
