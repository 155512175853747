import React, {useContext, useState, useEffect } from 'react'
import s from './Jobs.module.scss'
import SideBar from 'Components/Nav/SideBar';
import { SessionContext, isStudent } from 'Utils/sessions';
import Card from 'react-bootstrap/Card'
import {CompanyImageLightbox} from 'Components/Lightbox'
import {Tile} from 'Components/Tile/Tile'
import Button from 'react-bootstrap/esm/Button';
import Modal from 'react-bootstrap/Modal';
import {Link} from 'react-router-dom'
import {Root} from 'Root'
import Spinner from 'react-bootstrap/esm/Spinner'
import { useRouteMatch } from 'react-router-dom'
import placeholder from 'Static/placeholder.jpeg'
import { states, countries } from 'Utils/regions'
import { majors } from 'Utils/majors'
import { ids } from 'Utils/idToCollege'
import { CareerCard, ScholarshipCard } from '../Dashboard/Components/Components'
import {  TextInput, TextAreaInput, SelectInput, FormErrors, DayInput } from 'Input/Input'
import {isEmployer} from 'Utils/sessions'
import { isLoggedIn } from '../../Utils/sessions';
import { FaLink, FaEdit } from 'react-icons/fa';
import { TiDelete } from 'react-icons/ti';
import Form from 'react-bootstrap/Form'
import { push } from 'Utils/validation'

const Job = () => {
  const {session, refreshSession} =  useContext(SessionContext);

  const [job, setJob] = useState(null)
  const [editing, setEditing] = useState(false)
  const [company, setCompany] = useState(null)
  const [jobError, setJobError] = useState(null)
  const [companyError, setCompanyError] = useState(null)
  const [deleteError, setDeleteError] = useState(null)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const match = useRouteMatch();
  const jobId = match.params.id;

  function getJob() {
    Root.careerApi.getJob(jobId).then(res => {
        setJob(res.data)
        setJobError(null)
    }).catch(err => {
        setJob({})
        setJobError('Unable to get role, please try again later')
    });
  }

  useEffect(() => {
    getJob()
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [])

function handleDelete() {
    Root.careerApi.deleteJob(job.id, job.companyId)
    .then(()=> {window.location.href='/company/' + company.id})
    .catch(()=>{setDeleteError('Unable to delete position, please try again later')})
}

function toggleVisibility() {
  Root.careerApi.updateJob(job.id, { active: !job.active })
  .then((res)=> {
    setJob(res.data);
    setFormError(null);
    setShow(false);
    setLoading(false);
  }).catch(() => {
      setFormError('Something went wrong, please try again later')
      setLoading(false)
   })
}

useEffect(() => {
    if (job && job.companyId) {
        Root.careerApi.getCompany(job.companyId).then(res => {
            setCompany(res.data)
            setCompanyError(null)
        }).catch(err => {
            setCompany({})
            setCompanyError('Unable to get company info, please try again later')
        });
    } else if (job && ! job.companyId) {
        setCompany({})
        setCompanyError('Unable to get company info, please try again later')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [job])

  return <div className={s.job}>
        <SideBar session={session} title={job && job.title}>
        <div className={s.grid}>
            <Card className={`xlCard mb-5`}>
                <Card.Body>
                {company ?  <>

                    <CompanyImageLightbox contentList={company.media || []}>
                        <div className={s.thumbnailGrid}>
                            <img src={company.media && company.media[0] && company.media[0].url || placeholder} />
                            <img src={company.media && company.media[1] && company.media[1].url || placeholder} />
                            <img src={company.media && company.media[2] && company.media[2].url || placeholder} />
                        </div>
                    </CompanyImageLightbox>
                    {companyError && <p className='form-error'>{companyError}</p>}
                    <Card.Title className='trunc d-flex align-items-center'>
                        <div className={s.logo} style={{backgroundImage: `url(${company.logo || placeholder})`, backgroundSize:'cover'}} />
                        {company.name}
                    </Card.Title>
                    </> : <Spinner style={{color:"rgb(16, 142, 115)"}} className='d-block mx-auto my-4' animation="border" role="status"/>}
                    {job && <>
                    <Card.Subtitle>{job.title}</Card.Subtitle>
                    <Card.Text className='fs-13 mb-1'>
                        <span className='d-block my-1'>{job.location}</span>
                        {company && <Link className='btnLink my-1' href={company.website}>{company.website}</Link>}
                    </Card.Text>

                    {!job.active && <Tile color="purple" className='fs-13 mt-1 mb-2'>Not Accepting Applications</Tile>}
                    </>}
                    <div className='d-flex align-items-center justify-content-between'>
                        <div>
                            <h3 className={'green fs-18 trunc'}><strong>{(company && company.jobPostings) ? company.jobPostings.length : '-'}</strong></h3>
                            <p className='text-muted fs-13 mb-0'>Open Roles</p>
                        </div>
                        <div className='ml-3'>
                            <h3 className={'green fs-18'}><strong>{company && company.employeeCount || '-'}</strong></h3>
                            <p className='text-muted fs-13 mb-0'># Employees</p>
                        </div>
                        <div className='ml-3'>
                            <h3 className={'green fs-18'}><strong>{company && company.yearFounded || '-'}</strong></h3>
                            <p className='text-muted fs-13 mb-0'>Founded</p>
                        </div>
                    </div>
                    <hr/>
                    { job && <>
                        <div className={'green fs-16 trunc'}><strong className='trunc'>{job.timeCommitment || (job.fullTime ? 'Full Time' : 'Part Time')}</strong></div>
                        <div className='text-muted fs-13 mb-2'>Time Commitment</div>
                        <div className={'green fs-16 trunc'}><strong className='trunc'>{job.location || '-'}</strong></div>
                        <div className='text-muted fs-13 mb-2'>Location</div>
                        <div className={'green fs-16 trunc'}><strong className='trunc'>{job.startDate || '-'}</strong></div>
                        <div className='text-muted fs-13 mb-2'>Start Date</div>
                        <div className={'green fs-16 trunc'}><strong className='trunc'>{job.isPaid ? 'Paid' : 'Unpaid'}</strong></div>
                        <div className='text-muted fs-13 mb-2'>Compensation</div>
                        {job.type && <><div className={'green fs-16 trunc'}><strong className='trunc'>{job.type.replaceAll('_', ' ')}</strong></div>
                        <div className='text-muted fs-13 mb-2'>Job Type</div> </>}
                    </>}
                    <hr/>
                    <Button className={'btnOutline wide sm my-3'} href={company && '/company/' + company.id}>Visit Company Profile</Button>
                    {!isLoggedIn() && <Button className={'btnFill wide sm my-3'} href={'/login'} >Log In to Apply</Button>}
                    {job && isEmployer(session) && session.companyId == job.companyId && <Button className={'btnFill wide md my-3'} onClick={()=> {setEditing(true)}}>Edit Job Post</Button>}
                    {job && isEmployer(session) && session.companyId == job.companyId && <Button className={'btnFill wide md my-3'} onClick={()=> {toggleVisibility()}}>{job.active ? 'Hide Job Post' : 'Un-Hide Job Post'}</Button>}
                    {job && isStudent(session) && <Apply session={session} job={job}/>}
                    {job && isEmployer(session) && session.companyId == job.companyId && <Button className={'btnFill wide md my-3 red'} onClick={()=> setShowDeleteModal(true)}>Delete Job Post</Button>}
                </Card.Body>
            </Card>

            <Modal show={showDeleteModal} onHide={()=> setShowDeleteModal(false)}>
                <Modal.Header closeButton/>
                <Modal.Body>
                    <p className='text-center mb-3'>Are you sure want to delete this job post?</p>
                </Modal.Body>
                <Modal.Footer>
                <Button className={'btnFill d-block md mx-auto red'} onClick={handleDelete}>Delete</Button>
                    {deleteError && <p className='form-error text-center'>{deleteError}</p>}
                </Modal.Footer>
            </Modal>

            { job ?
            <div>
                {jobError && <p className='form-error'>{jobError}</p>}
                {!jobError && <>
                <h3 className='heading'>Description</h3>
                <div className={s.row}>
                    <p>{job.description}</p>
                </div>
                <h3 className='heading'>Suggested Skills</h3>
                <div className={s.row}>
                    <p>{job.qualifications}</p>
                </div>
                <h3 className='heading'>Responsibilities</h3>
                <div className={s.row}>
                    <p>{job.responsibilities}</p>
                </div>
                <h3 className='heading'>Compensation Description</h3>
                <div className={s.row}>
                    <p>{job.compensationDescription}</p>
                </div>
                <h3 className='heading'>Benefits</h3>
                <div className={s.row}>
                    <p>{job.benefits}</p>
                </div>
                <h3 className='heading'>Employer Questions</h3>
                <div className={s.row}>
                    {job.questions.map((q)=> <p className='pb-0 mb-1'>{q.question}</p>)}
                </div>
                </>}
                {editing && <EditJob job={job} show={editing} getJob={getJob} setShow={setEditing}/>}
            </div> :   <Spinner style={{color:"rgb(16, 142, 115)"}} animation="border" role="status"/>}
        </div>
   </SideBar>
</div>
}

export default (Job);

const EditJob = ({job, show, getJob, setShow}) => {
    const [request, setRequest] = useState(job)
    const [formError, setFormError] = useState(null)
    const [formErrors, setFormErrors] = useState({})
    const [loading, setLoading] = useState(false)
    const [types, setTypes] = useState(null)
    const handleHide = () => { setShow(false); setFormErrors({})}


    useEffect(()=> {
        Root.careerApi.getJobTypes().then(res => {
            var typeMap = {}
            res.data.split(',').forEach(t => typeMap[t] = t.replaceAll('_', ' '))
            setTypes(typeMap)
        })
    },[])

    function handleChange(event) {
        const { name, value } = event.target;
        setRequest((prevState) => ({ ...prevState, [name]: value }));
    }

    function handlePayChange(event) {
        const { name, value } = event.target;
        setRequest((prevState) => ({ ...prevState, isPaid: name === 'true'}));
    }

    function handleChangeStartDate (selectedDay, modifiers, dayPickerInput) {
        setRequest(prevState => ({ ...prevState, startDate: dayPickerInput.getInput().value }))
      }

    function validate () {
        var errors = {}
        if (!request.timeCommitment) {
            push(errors, 'timeCommitment', 'This field is required ')
          }
        if (!request.title) {
            push(errors, 'title', 'This field is required ')
          }
        if (!request.location) {
          push(errors, 'location', 'This field is required ')
        }
        if (request.isPaid == null || request.isPaid == undefined) {
            push(errors, 'isPaid', 'This field is required ')
        }
        if (!request.description) {
            push(errors, 'description', 'This field is required ')
        }
        if (!request.qualifications) {
            push(errors, 'qualifications', 'This field is required ')
        }
        if (!request.responsibilities) {
            push(errors, 'responsibilities', 'This field is required ')
        }
        return errors
      }

    function handleSubmit() {
        setLoading(true)

        const errors = validate()
        if (Object.keys(errors).length > 0) {
            setFormErrors(errors)
            setLoading(false)
            return
        }

        let data = Object.fromEntries(Object.entries(request).filter(([_, v]) => v != null));
        Root.careerApi.updateJob(job.id, data).then(()=>{
            setFormError(null)
            getJob()
            setShow(false)
            setLoading(false)
        }).catch(() => {
            setFormError('Something went wrong, please try again later')
            setLoading(false)
         })
    }

    return <>
    <Modal show={show} onHide={handleHide} >
        <Modal.Header closeButton>
            <Modal.Title ><FaEdit/> Edit Job Post</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <TextInput containerClassName='mb-2' errors={formErrors} name="title" value={request.title || ''} onChange={handleChange} label="*Job Title" />
            <TextInput containerClassName='mb-2' errors={formErrors} name="location" value={request.location || ''} onChange={handleChange} label="*Job Location" />
            <TextInput containerClassName='mb-2' errors={formErrors} name="timeCommitment" value={request.timeCommitment || 'ex. 40 hrs/week, Part-Time, Full-Time'} onChange={handleChange} label="*Time Commitment" />
            <DayInput containerClassName='mb-2' errors={formErrors} name="startDate" value={request.startDate || ''} onChange={handleChangeStartDate} label="Start Date" />
            <div className="mb-3">
            <Form.Label>*Paid or Unpaid</Form.Label>
                <div>
                    <Form.Check inline label="Paid" name="true" type={'checkbox'} onChange={handlePayChange}
                        checked={request.isPaid == true} />
                    <Form.Check inline label="Unpaid" name="false" type={'checkbox'} onChange={handlePayChange}
                        checked={request.isPaid == false}/>
                </div>
                <FormErrors errors={formErrors} name={'isPaid'}/>
            </div>
            <TextInput containerClassName='mb-2 pb-2' errors={formErrors} name="compensationDescription" value={request.compensationDescription || ''} onChange={handleChange} label="Compensation Description ($USD)" placeholder={'ex. $20/hour, $100 Contract, Not Paid'} />
            {types && <SelectInput containerClassName='mb-2 pb-2' ordered={'true'} options={types} errors={formErrors} name="type" value={request.type || ''} onChange={handleChange} label="Job Type" placeholder={''}/>}
            <TextAreaInput containerClassName='mb-2 pb-2' errors={formErrors} name="description" value={request.description || ''} onChange={handleChange} label="*Job Description" placeholder={''} maxLength={2000}/>
            <TextAreaInput containerClassName='mb-2 pb-2' errors={formErrors} name="responsibilities" value={request.responsibilities || ''} onChange={handleChange} label="*Responsibilities" placeholder={''} maxLength={2000}/>
            <TextAreaInput containerClassName='mb-2 pb-2' errors={formErrors} name="qualifications" value={request.qualifications || ''} onChange={handleChange} label="*Suggested Skills" placeholder={''} maxLength={2000}/>
            <TextAreaInput containerClassName='mb-2 pb-2' errors={formErrors} name="benefits" value={request.benefits || ''} onChange={handleChange} label="Benefits" placeholder={''} maxLength={2000}/>

            <p className='mt-3 mb-0'><strong>Questions for Applicants</strong>
            </p>
            <p className='fs-13 text-muted'>Max response length is 600 characters</p>

            {job.questions.map((q, idx) => {
                return <div className='d-flex align-items-center'>
                <span className='fs-14 text-muted pb-2'>- {q.question}</span>
                </div>
            })}

        </Modal.Body>
        <Modal.Footer>
            {formError && <p className='form-error'>{formError}</p>}
            <Button className='btnFill mt-3 md' disabled={loading} onClick={handleSubmit}>Save Job</Button>
        </Modal.Footer>
    </Modal>
    </>
}

const Apply = ({session, job}) => {
    const [show, setShow] = useState(false)
    const [success, setSuccess] = useState(false)
    const [appError, setAppError] = useState(null)
    const [profile, setProfile] = useState({});
    const [jobs, setJobs] = useState(null)
    const [experiences, setExperiences] = useState(null)
    const [awards, setAwards] = useState(null)
    const [questions, setQuestions] = useState({})
    const [loading, setLoading] = useState(false)
    const [agreed, setAgreed] = useState(null)

setAgreementStatus
    function handleChange(event, ques) {
        const { name, value } = event.target;
        setQuestions((prevState) => ({ ...prevState, [name]: {question: ques, response: value, mediaType: 'TEXT'} }));
    }

    function setAgreementStatus(event) {
      if (agreed === 'true') {
        setAgreed(null)
      } else {
        setAgreed('true')
      }
    }

    function apply () {
        if(profile && !profile.resume) {
            setAppError("Please upload your resume to your profile before applying")
            return
        }

        if(job.questions.length != Object.keys(questions).length) {
            setAppError("Missing required fields")
            return
        }

        if (!agreed || agreed !== 'true') {
          setAppError("You must read and acknowledge the Independent Contractor Service Contract")
          return
        }

        setAppError(null)
        setSuccess(false);
        setLoading(true)
        const data = {
            applicantId: session.id || session.userId,
            companyId: job.companyId,
            jobPostingId: job.id,
            questionResponses: Object.values(questions)
        }
        Root.careerApi.submitApplication(data, job.id)
        .then(()=> {
            setSuccess(true);
            setQuestions({});
            setLoading(false);
    })
        .catch((err)=> {
            setLoading(false);
            if(err.response && err.response.status == 406){
                setAppError('An application for this role has already been submitted')
                return
            }
            setAppError('Unable to submit application, please contact support if error persists')
        })
    }

    useEffect(()=> {
        Root.userApi.getAttribute(session.id, "job_positions")
        .then(res => setJobs(res.data)).catch(
          ()=> {setJobs([]); })
    },[])

    useEffect(()=> {
        Root.userApi.getAttribute(session.id, "experiences")
        .then(res => setExperiences(res.data)).catch(
            ()=> {setExperiences([]);})
    },[])

    useEffect(()=> {
        Root.userApi.getAttribute(session.id, "accomplishments")
        .then(res => setAwards(res.data)).catch(
            ()=> {setAwards([]);})
    },[])

    useEffect(()=>{
        Root.userApi.getUserProfile(session.id).then((res) => {
          if (res.status === 200) {
              setProfile(res.data)
            }
        }).catch(() => {
          setProfile({})
        })
    },[])

    return <>
        {job.active && <Button onClick={()=>setShow(true)} className='btnFill md wide'>Apply Now</Button>}
        <Modal show={show} onHide={()=> {setShow(false); setAppError(null); setSuccess(false); setQuestions({})}}>
            <Modal.Header closeButton>
                <Modal.Title>Apply Now</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className='text-muted text-center fs-13 mb-2'>* Information is automatically imported from your NXSTEP profile *</p>
                <hr/>
                <h3 className='fs-18 mb-3'>Personal Info</h3>
               { profile && <> <p className='mb-2 fs-13'><strong>Name</strong></p>
                <p className='fs-13 text-muted'>{profile.firstName} {profile.lastName}</p>

                <p className='mb-2 fs-13'><strong>Location</strong></p>
                <p className='fs-13 text-muted'>{profile.city && profile.city != 'N/A' && profile.city}
                {profile.state && profile.state != 'N/A' && states[profile.state]} {countries[profile.country]}</p>

                <p className='mb-2 fs-13'><strong>About Me</strong></p>
                <p className='fs-13 text-muted'>{profile.bio}</p>

                <p className='mb-2 fs-13'><strong>Major</strong></p>
                <p className='fs-13 text-muted'>{majors[profile.major]}</p>

                <p className='mb-2 fs-13'><strong>University</strong></p>
                <p className='fs-13 text-muted'>{ids[profile.college]}</p> </>}
                <hr/>
                <h3 className='fs-18'>Professional Experience</h3>
                {jobs && jobs.length === 0 &&
                experiences && experiences.length === 0
                && awards && awards.length === 0 &&
                <p className='text-muted fs-13'>No experiences</p>}

                {jobs ? jobs.map((j)=> {return <CareerCard type='job_positions' job={j}/>})
                            : <Spinner style={{color:"rgb(16, 142, 115)"}} animation="border" role="status"/>}

                {experiences ? experiences.map((e)=> {return <CareerCard type='experiences' job={e}/>})
                            : <Spinner style={{color:"rgb(16, 142, 115)"}} animation="border" role="status"/>}

                {awards ? awards.map((a)=> {return <ScholarshipCard award={a}/>})
                            : <Spinner style={{color:"rgb(16, 142, 115)"}} animation="border" role="status"/>}
                <hr/>
                <h3 className='fs-18'>*Resume <span className='fs-13 text-muted'>(Required)</span></h3>
                {profile.resume ?
                       <a className='btnLink fs-14' target="_blank" style={{color:'rgb(118, 120, 237)'}} href={profile.resume}><FaLink/> {profile.firstName}'s Resume</a> :
                        <span className='fs-14' className='text-muted fs-13'>Please visit your profile to upload your resume</span>}
                <hr/>
                {job.questions.length >0  && <h3 className='fs-18'>* Employer Questions <span className='fs-13 text-muted'>(Required)</span></h3>}
                {job.questions.map((q, idx)=> {
                    return <>
                    <TextAreaInput name={q.question} containerClassName='mb-2' errors={{}} value={questions[q.question] && questions[q.question].response || ''} onChange={(e)=>handleChange(e, q.question)}
                    maxLength={600} label={q.question} placeholder={'Max 600 characters'}/>
                </>
                })}
                <div style={{paddingTop: '10px'}}>
                  <Link className='btnLink mb-3 fs-12' target="_blank" to="/independent-contractor-service-contract">Click to read the Independent Contractor Service Contract</Link>
                </div>
                <Form.Group className="mb-1">
                  <Form.Check inline name="tos" onChange={setAgreementStatus} value={'true'} checked={agreed === 'true'} label="I have read, and I agree to the Independent Contractor Service Contract" type='checkbox'/>
                </Form.Group>
                <Modal.Footer className='mt-4'>
                    {appError && <p className='form-error mt-2'>{appError}</p>}
                    {success && <Tile color='purple' className='fs-13'>Application Submitted</Tile>}
                    <Button onClick={apply} disabled={loading || !agreed} className='btnFill md'>Apply Now</Button>
                </Modal.Footer>
            </Modal.Body>
        </Modal>
    </>
}
