import axios from 'axios'
import * as vars from './vars';
import {getTimezone} from 'Utils/time'

// get all employees by company
 export function getEmployees (companyId) {
        return axios({
            method: 'GET',
            url: vars.baseURL + '/api/v2/employment/companies/' + companyId + '/employees',
            headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
            data: {}
        })
    }

// get all employees by company
export function getAllEmployees () {
    return axios({
        method: 'GET',
        url: vars.baseURL + '/api/v2/employment/employees',
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data: {}
    })
}

// get all employee by id
export function getEmployee (employeeId) {
    return axios({
        method: 'GET',
        url: vars.baseURL + '/api/v2/employment/employees/' + employeeId,
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data: {}
    })
}

// create new employee
// data = {
//     email: String,
//     firstName: String,
//     lastName: String,
//     userName: String,
//     phone: String,
//     password: String
//     }
export function createEmployee (companyId, data){
        return axios({
            method: 'POST',
            url: vars.baseURL + '/api/v2/employment/companies/' + companyId + '/employees',
            headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
            data: data
        })
      }


      export function referEmployee (companyId, data){
              return axios({
                  method: 'POST',
                  url: vars.baseURL + '/api/v2/employment/companies/' + companyId + '/employees',
                  headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
                  data: data
              })
            }

// data = {
// firstName: String,
// lastName: String,
// phone: String,
// photo: String
// }
export function updateEmployee (employeeId, data){
    return axios({
        method: 'PATCH',
        url: vars.baseURL + `/api/v2/employment/employees/${employeeId}`,
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data: data
    })
}

export function authenticateEmployee (data){
    return axios({
        method: 'POST',
        url: vars.baseURL + `/api/v2/employment/authentication/employees`,
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data: data
    })
}

export function logCompanyView (companyId, data){
    return axios({
        method: 'POST',
        url: vars.baseURL + `/api/v2/employment/companies/${companyId}/views`,
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data: data
    })
}

// get all companies
export function getCompanies () {
    return axios({
        method: 'GET',
        url: vars.baseURL + '/api/v2/employment/companies',
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data: {}
    })
}

// get company
export function getCompany (companyId) {
    return axios({
        method: 'GET',
        url: vars.baseURL + `/api/v2/employment/companies/${companyId}`,
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data: {}
    })
}

// Request Param(s):
// college: (Optional) String,
// region: (Optional) String,
// major: (Optional) String,
// gradYear: (Optional) String,
// gpa: (Optional) Double,
// limit: (Optional) Integer,  /* defaults to 50 */
// page: (Optional) Integer,  /* defaults to 1 */
// perPage: (Optional) Integer /* defaults to 10 */
export function getTalent (searchParams) {
    const params = Object.entries(searchParams)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&')
    return axios({
        method: 'GET',
        url: vars.baseURL + `/api/v2/employment/talent?` + params,
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data: {}
    })
}

export function getNetwork (userId) {
    const params = new URLSearchParams({userId: userId})
    return axios({
        method: 'GET',
        url: vars.baseURL + `/api/v2/algorithmic/mentors/similar?` + params,
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data: {}
    })
}

// Request Param(s):
// college: (Optional) String,
// region: (Optional) String,
// major: (Optional) String,
// gradYear: (Optional) String,
// gpa: (Optional) Double'
// interests
// skills comma sep list
export function getTalentCount (searchParams) {
    const params = Object.entries(searchParams)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&')
    return axios({
        method: 'GET',
        url: vars.baseURL + `/api/v2/employment/talent/population?` + params,
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data: {}
    })
}

export function uploadCompanyMedia (companyId, data){
    return axios({
        method: 'POST',
        url: vars.baseURL + `/api/v2/employment/companies/${companyId}/media`,
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data: data
    })
  }

  export function deleteCompanyMedia (companyId, mediaId){
    return axios({
        method: 'DELETE',
        url: vars.baseURL + `/api/v2/employment/companies/${companyId}/media/${mediaId}`,
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data: {}
    })
  }

// data = {
//     name: String,
//     description: String,
//     message: String,
//     logo: String,
//     mission: String,
//     yearFounded: String,
//     employeeCount: Integer,
//     city: String,
//     state: String,
//     country: String,
//     website: String
//     }
export function updateCompany (companyId, data) {
    return axios({
        method: 'PATCH',
        url: vars.baseURL + `/api/v2/employment/companies/${companyId}`,
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data : data
    })
}

// Returns a list of all of the jobs that meet or are close to the criteria.
// data: title, industry, salary, experience
export function getJobs (searchParams) {
    const params = new URLSearchParams(searchParams)
    return axios({
        method: 'GET',
        url: vars.baseURL + '/api/v2/employment/jobs?' + params,
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data: {}
    })
}

// Returns a list of all of the jobs that meet or are close to the criteria.
// data: title, industry, salary, experience
export function getJobsByCompany (companyId, searchParams) {
    const params = new URLSearchParams(searchParams)
    return axios({
        method: 'GET',
        url: vars.baseURL + `/api/v2/employment/companies/${companyId}/jobs?` + params,
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data: {}
    })
}

// Get job by id
export function getJob (jobId) {
    return axios({
        method: 'GET',
        url: vars.baseURL + `/api/v2/employment/jobs/${jobId}`,
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data: {}
    })
}

// Delete job by id
export function deleteJob (jobId, companyId) {
    const params = new URLSearchParams({companyId:companyId})
    return axios({
        method: 'DELETE',
        url: vars.baseURL + `/api/v2/employment/jobs/${jobId}?` + params,
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data: {}
    })
}

// Get application by id
export function getApplication (applicationId) {
    return axios({
        method: 'GET',
        url: vars.baseURL + `/api/v2/employment/applications/${applicationId}`,
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data: {}
    })
}

// Get applications by job
//title, college, major, location, gpa, year, sort, experienced
export function getApplicationsByJob (jobId, searchParams) {
    const params = new URLSearchParams(searchParams)
    return axios({
        method: 'GET',
        url: vars.baseURL + `/api/v2/employment/jobs/${jobId}/applications?` + params,
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data: {}
    })
}

// Get applications by company
//searchParams state applicantName position
export function getApplicationsByCompany (companyId, searchParams) {
    const params = new URLSearchParams(searchParams)
    return axios({
        method: 'GET',
        url: vars.baseURL + `/api/v2/employment/companies/${companyId}/applications?` + params,
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data: {}
    })
}

// Get applications by student
export function getApplicationsByStudent (userId, sort) {
    const params = new URLSearchParams({sort: sort})
    return axios({
        method: 'GET',
        url: vars.baseURL + `/api/v2/employment/applicants/${userId}/applications?` + params,
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data: {}
    })
}


// data = {
    // company: String
    // fullTime: Bool
    // location: [“Remote”, Name_of_City]
    // compensationType: [ “Hourly” | “Salary” | “Commission” | “Contract” ]
    // compensation: Double
    // description: String
    // requirements: String
    // qualification: String
    // benefits: String
    // }
export function createJob (data){
    return axios({
        method: 'POST',
        url: vars.baseURL + '/api/v2/employment/jobs',
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data: data
    })
  }

//   data = {
//   name: String,
//   description: String,
//   message: String,
//   logo: String,
//   mission: String,
//   yearFounded: String,
//   employmeeCount: Integer,
//   city: String,
//   state: String,
//   country: String,
//   website: String
//   }
  export function createCompany (data){
    return axios({
        method: 'POST',
        url: vars.baseURL + '/api/v2/employment/companies',
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data: data
    })
  }

//   data = {
//     applicant: BigInt
//     company: BigInt
//     jobPosting: BigInt
//     questionResponses: [
//     { question: String, mediaType: String, response: String },
//     { question: String, mediaType: String, response: String },
//     { question: String, mediaType: String, response: String },
//     ]
// }
export function submitApplication (data, jobId){
    return axios({
        method: 'POST',
        url: vars.baseURL + `/api/v2/employment/jobs/${jobId}/applications`,
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data: data
    })
}

//   data = {
//   name: "Twitter",
//   description: "A news and social media platform. We let you know what's happening",
//   message: "",
//   logo: "",
//   mission: "A news and social media platform. We let you know what's happening",
//   yearFounded: 2000,
//   employeeCount: 200,
//   city: "San Francisco",
//   state: "California",
//   country: "USA",
//   website: "www.twitter.com"
//   }

//   data = {
//     fullTime: Bool
//     location: [“Remote”, Name_of_City]
//     compensationType: [ “Hourly” | “Salary” | “Commission” | “Contract” ]
//     compensation: Double
//     description: String
//     requirements: String
//     qualification: String
//     benefits: String
//     }
export function updateJob (jobId, data) {
    return axios({
        method: 'PATCH',
        url: vars.baseURL + `/api/v2/employment/jobs/${jobId}`,
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data : data
    })
}

// data = {
// employer: String
// application: String
// date: YYYY-MM-DD
// time: hh:mm
// notes: String,
// date: String (yyyy-mm-dd),
// time: String (hh:mm)
// }
export function requestInterview (data){
    return axios({
        method: 'POST',
        url: vars.baseURL + '/api/v2/employment/interviews',
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data: data
    })
}

// data = {
//     date: YYYY-MM-DD
//     time: hh:mm
//     status: [“Scheduled”, “OnGoing”, “Completed”, “Canceled”]
//     notes: String
//     date: String (yyyy-mm-dd),
//     time: String (hh:mm)
// }
export function updateInterview (interviewId, data){
    return axios({
        method: 'PATCH',
        url: vars.baseURL + `/api/v2/employment/interviews/${interviewId}`,
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data: data
    })
}

// data = {
// state: [“New”, “Pending”, “Considered”, “Interviewing”, “Reviewing”, “Hired”, “Rejected”]
// note: String
// }
export function updateApplication (jobId, applicationId, data) {
    return axios({
        method: 'PATCH',
        url: vars.baseURL + `/api/v2/employment/jobs/${jobId}/applications/${applicationId}`,
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data : data
    })
}

export function getDiscoveryJobs (userId) {
    const params = new URLSearchParams({userId: userId})
    return axios({
        method: 'GET',
        url: vars.baseURL + `/api/v2/discovery/jobs?` + params,
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data: {}
    })
}

// Param(s): userId: (Optional) BigInteger, companyId: (Optional) String
export function getInterviews (searchParams) {
    const params = new URLSearchParams(searchParams)
    return axios({
        method: 'GET',
        url: vars.baseURL + `/api/v2/employment/interviews?` + params,
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data: {}
    })
}

// Param(s): userId: (Optional) BigInteger, companyId: (Optional) String
export function getInterview (interviewId, searchParams) {
    const params = new URLSearchParams(searchParams)
    return axios({
        method: 'GET',
        url: vars.baseURL + `/api/v2/employment/interviews/${interviewId}?` + params,
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data: {}
    })
}

export function joinInterview (interviewId, userId) {
    const params = new URLSearchParams({userId: userId})
    return axios({
        method: 'GET',
        url: vars.baseURL + `/api/v2/employment/interviews/${interviewId}/access?` + params,
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data: {}
    })
}

export function getJobTypes () {
    return axios({
        method: 'OPTIONS',
        url: vars.baseURL + `/api/v2/employment/jobs/types`,
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data: {}
    })
}

export function getJobSubTypes (type) {
    return axios({
        method: 'OPTIONS',
        url: vars.baseURL + `/api/v2/employment/jobs/types/${type}/subtypes`,
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data: {}
    })
}

//{userId, companyId}
export function getUserContracts (searchParams) {
    const params = new URLSearchParams(searchParams)
    return axios({
        method: 'GET',
        url: vars.baseURL + `/api/v2/employment/contracts?` + params,
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data: {}
    })
}

export function getContract (contractId) {
    return axios({
        method: 'GET',
        url: vars.baseURL + `/api/v2/employment/contracts/${contractId}`,
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data: {}
    })
}

export function completeContract (contractId){
    return axios({
        method: 'PATCH',
        url: vars.baseURL + `/api/v2/employment/contracts/${contractId}`,
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data: {state: "COMPLETED"}
    })
}

export function getReviewsByContract (contractId) {
    return axios({
        method: 'GET',
        url: vars.baseURL + `/api/v2/employment/contracts/${contractId}`,
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data: {}
    })
}

// data = {reviewerId: BigInt, review: String, rating Double}
export function submitReviewByContract (contractId, data) {
    return axios({
        method: 'PUT',
        url: vars.baseURL + `/api/v2/employment/contracts/${contractId}/reviews`,
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data: data
    })
}

export function getReviewsByContractor (contractorId) {
    return axios({
        method: 'GET',
        url: vars.baseURL + `/api/v2/employment/contractors/${contractorId}/reviews`,
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data: {}
    })
}
