import React, {useState, useEffect} from 'react';
import { NavBar } from 'Components/Nav/NavBar';
import Button from 'react-bootstrap/Button';
import Footer from './Footer';
import s from './styles/CollegeLanding.module.scss';
import { Root } from 'Root';
import {FaStar, FaArrowRight} from 'react-icons/fa'
import Fade from 'react-reveal/Fade'
import Zoom from 'react-reveal/Zoom'

import step_1 from './static/step_1.svg'
import step_2 from './static/step_2.svg'
import step_3 from './static/step_3.svg'
import step_4 from './static/step_4.svg'
import num_1 from './static/num_1.svg'
import num_2 from './static/num_2.svg'
import num_3 from './static/num_3.svg'
import num_4 from './static/num_4.svg'
import arrow_icon from './static/arrow_icon.svg'
import exit_icon from './static/exit_icon.svg'
import sad_icon from './static/sad_icon.svg'
import heading from './static/heading.svg'
import landing_img_2 from './static/landing.svg'
import background from './static/collegebg.svg'

const Landing = () => {
  const [applicant,setApplicant] = useState(true)
  const [mentorCount, setMentorCount] = useState(null)
  const [collegeRepCount, setCollegeRepCount] = useState(null)

  useEffect(() => {
    Root.collegeApi.getMentorCount().then(rsp => {
        setMentorCount(Math.round(rsp.data / 50) * 50)
    }).catch(error => {
      console.log(error)})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    Root.collegeApi.getRepresentedCollegesCount().then(rsp => {
        setCollegeRepCount(Math.round(rsp.data / 50) * 50)
    }).catch(error => {
      console.log(error)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <>
    <div className={s.landing}>
      <div
      className={s.sectionOne}
      style={{ backgroundImage: `url(${background})`,
               backgroundPosition: "center",
               backgroundSize: "cover",
               backgroundRepeat: "no-repeat",
             }}>
            <NavBar className={s.nav}/>
            <div className={``}>
              <div className={s.grid}>
              <Fade left>
                <span>
                    <h1>
                      <strong>Connecting College Students With Short-term <span style={{color:'#8EE3C8'}}>Professional Projects.</span></strong>
                    </h1>

                    <h2 className={s.subtitle}>
                    Build your resume and earn cash via remote freelance projects and micro-internships.
                    </h2>

                    <div className={s.action}>
                      <Button className={`btnFill lg px-5 green ${s.join}`} href='/jobs?pg=1'>Find Projects</Button>
                      <Button className={`btnFill lg dark ms-3 ${s.join}`} href='/register'>Sign Up</Button>
                    </div>
                  </span>
              </Fade>
                  <Fade down>
                  <div className={s.image}>
                    <img style={{width:'85%', maxWidth: '700px'}} src={landing_img_2}/>
                  </div>
                  </Fade>
              </div>

              <div className={s.stats}>
                <div className={s.stat}>
                  <p>{collegeRepCount || "850"} +</p>
                  <p>Colleges Represented</p>
                </div>
                <div className={s.stat}>
                  <p>{mentorCount || "3100"} +</p>
                  <p>College Students</p>
                </div>
              </div>
            </div>
      </div>
      <div className={s.sectionTwo}>
        <div className={s.container}>
          <Fade><h2>How it Works</h2></Fade>

          <div className={s.gridContainer}>

            <div className={s.grid}>
            <Fade left>
              <span className={s.step}>
                <img src={num_1}/>
                <span>
                  <h4>Let us get to know you</h4>
                  <p>Once you create an account, make sure to fill out your NXSTEP Profile. This helps employers get to know you and increases your chances of being hired!</p>
                </span>
              </span>
            </Fade>
            <Fade right>
              <span><img src={step_1}/></span>
            </Fade>
            </div>

            <div className={s.grid}>
            <Fade right>
              <span className={`${s.stagger}`}>
                  <img src={num_2}/>
                  <span>
                    <h4>Start Using NXSTEP</h4>
                    <p>Once your profile is filled out, you can immediately start looking for projects or network with other students who share your goals!</p>
                  </span>
                </span>
            </Fade>
                <Fade left><span><img src={step_2}/></span></Fade>
            </div>
            <div className={s.grid}>
            <Fade left>
              <span className={`${s.step}`}>
                    <img src={num_3}/>
                    <span>
                    <h4>Apply to Projects</h4>
                    <p>Students can either apply to projects or get invited to apply by an employer. You can check the status of your application directly from your dashboard.</p>
                  </span>
                </span>
            </Fade>
                <Fade right><span><img src={step_3}/></span></Fade>
            </div>
            <div className={s.grid}>
            <Fade right>
             <span className={`${s.stagger}`}>
                    <img src={num_4}/>
                    <span>
                    <h4>Send Invoices</h4>
                    <p>Once you’ve completed a project, it is up to you to send an invoice to the employer from the ‘Payment’ tab. If the project is paid hourly, ask the employer how often you should request payment (weekly, bi-weekly, etc.)</p>
                    </span>
                </span>
            </Fade>
                <Fade left><span><img src={step_4}/></span></Fade>
            </div>
          </div>
        </div>
      </div>

      <div className={s.sectionThree}>
        <div className={`${s.container} ${s.grid}`}>
          <Fade up>
          <div>
           <h3 className='text-muted'><strong>College Students</strong></h3>
           <h2>College Work <br/> Experience is Crucial</h2>
            <h3 className='mt-3'>Getting that experience can be difficult</h3>
            <div className='mt-5 mb-3'>
              <span className={`${s.stat} d-flex`}>
                <img style={{height:'70px'}} className='px-2' src={arrow_icon}/>
                <span>
                  <Zoom><p className='mb-0'>91%</p></Zoom>
                  <p>of full-time employers prefer candidates with prior work experience</p>
                </span>
              </span>
            </div>
            <div className='mb-3'>
              <span className={`${s.stat} d-flex`}>
                <img style={{height:'70px'}} className='px-2' src={exit_icon}/>
                <span>
                  <Zoom><p className='mb-0'>60%</p></Zoom>
                  <p>of students are able to successfully complete an internship during college</p>
                </span>
              </span>
            </div>
            <div>
              <span className={`${s.stat} d-flex`}>
                <img style={{height:'70px'}} className='px-2' src={sad_icon}/>
                <span>
                  <Zoom><p className='mb-0'>40%</p></Zoom>
                  <p>of those college internships are unpaid</p>
                </span>
              </span>
            </div>
            <Button className='btnOutline orange md mt-5' href='/jobs?pg=1'>See How NXSTEP Can Help</Button>
          </div>
        </Fade>
          <Fade right><div><img style={{width:'100%'}} className='mt-5' src={'https://cdn.nxstep.co/landing_phone_mockup.svg'}/></div></Fade>
        </div>

        <Fade>
        <div className={`${s.container} ${s.grid}`}>
          <div>
           <h3 className='text-muted'><strong>College Students</strong></h3>
          <h2 className='mb-5'>Make $$$ and build your resume with freelance jobs</h2>
          <p>NXSTEP works closely with unique and fast-growing companies & start-ups to bring you a variety of flexible, temporary and paid freelance jobs so you can earn cash, gain relevant experience and build your resume year-round. Hence the term: micro-internships!</p>
          <p>Once your NXSTEP Profile is all set up, you will immediately be able to discover, filter, and apply to open positions with the click of a button.</p>
          <Button className='btnOutline md orange my-4' href='/register'>Sign Up as College Student</Button>
          </div>
          <div><img style={{width:'100%'}} src={'https://cdn.nxstep.co/landing_laptop_mockup.svg'}/></div>
        </div>
        </Fade>

      </div>

      /*<div className={`${s.sectionFour}  `}>
        <div className={s.container}>
          <h2>Colleges of the Month</h2>
          <div className={s.grid}>
            <div className='mb-4' className={s.uniTile}>
              <img src={"https://lh3.googleusercontent.com/p/AF1QipMs-IWz0qatxsfHebnM9WRLmKS_bcQpwVaH04ab=s1600"}/>
              <p className='text-start mb-0'>University of Notre Dame</p>
              <p className='text-start mb-0'>South Bend, IN</p>
              <div className='d-flex justify-content-between mb-3'>
                <span className='d-flex align-items-center'><FaStar style={{marginRight:'5px'}} fill='#F45728'/>{' '}4.2</span>
                <Button className='btnOutline' href='/university?name=University+of+Notre+Dame&id=152080'><FaArrowRight/></Button>
              </div>
            </div>
            <div className='mb-4' className={s.uniTile}>
              <img  src="https://lh3.googleusercontent.com/p/AF1QipNZ8ubqe03uqX_i_oYdvHpfbqRqfjggZPyMXBse=s1600"/>
              <p className='text-start mb-0'>University of California-Berkeley</p>
              <p className='text-start mb-0'>Berkeley, CA</p>
              <div className='d-flex justify-content-between mb-3'>
                <span className='d-flex align-items-center'><FaStar style={{marginRight:'5px'}} fill='#F45728'/>{' '}4.0</span>
                <Button className='btnOutline' href='/university?name=University+of+California-Berkeley&id=110635'><FaArrowRight/></Button>
              </div>
            </div>
            <div className='mb-4' className={s.uniTile}>
              <img src={"https://lh3.googleusercontent.com/p/AF1QipOA55HwMxQPJigBkFZBi4ki1d7MYU80ThUZRgn8=s1600"}/>
              <p className='text-start mb-0'>University of California-Irvine</p>
              <p className='text-start mb-0'>Irvine, CA</p>
              <div className='d-flex justify-content-between'>
                  <span className='d-flex align-items-center'><FaStar style={{marginRight:'5px'}} fill='#F45728'/>{' '}4.4</span>
                  <Button className='btnOutline' href='/university?name=University+of+California-Irvine&id=110653'><FaArrowRight/></Button>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <Footer />
  </>
};

export default (Landing);
