// Ref http://www.act.org/content/act/en/research/reports/act-publications/college-choice-report-class-of-2013/college-majors-and-occupational-choices/college-majors-and-occupational-choices.html
export const majors = [
    'Undecided', 
    // AGRICULTURE & NATURAL RESOURCES CONSERVATION
    'Agriculture, General', 
    'Agribusiness Operations', 
    'Agricultural Business & Management', 
    'Agricultural Economics', 
    'Agricultural Mechanization', 
    'Agricultural Production', 
    'Agronomy & Crop Science', 
    'Animal Sciences', 
    'Food Sciences & Technology', 
    'Horticulture Operations & Management',
    'Horticulture Science',
    'Natural Resources Conservation, General',
    'Environmental Science',
    'Forestry',
    'Natural Resources Management', 
    'Wildlife & Wildlands Management',
    // ARCHITECTURE
    'Architecture, General', 
    'Architectural Environmental Design', 
    'City/Urban/Regional Planning', 
    'Interior Architecture', 
    'Landscape Architecture',
    // AREA, ETHNIC, & MULTIDISCIPLINARY STUDIES 
    'Area Studies, General (e.g., African, Middle Eastern)', 
    'Asian Area Studies', 
    'European Area Studies', 
    'Latin American Area Studies', 
    'North American Area Studies', 
    'Ethnic & Minority Studies, General', 
    'African American Studies', 
    'American Indian/Native American Studies', 
    'Latino/Chicano Studies', 
    'Women’s Studies', 
    'Liberal Arts & General Studies*', 
    'Library Science', 
    'Multi/Interdisciplinary Studies*', 
    // ARTS: VISUAL & PERFORMING
    'Art, General', 
    'Art History, Criticism & Conservation', 
    'Fine/Studio Arts', 
    'Cinema/Film', 
    'Cinematography/Film/Vide Production', 
    'Dance', 
    'Design & Visual Communications, General', 
    'Fashion/Apparel Design', 
    'Graphic Design', 
    'Industrial Design', 
    'Interior Design', 
    'Music, General', 
    'Music, Performance', 
    'Music, Theory & Composition', 
    'Photography', 
    'Theatre Arts/Drama', 
    //BUSINESS
    'Accounting', 
    'Accounting Technician', 
    'Business Administration & Management, General', 
    'Hotel/Motel Management', 
    'Human Resources Development/Training', 
    'Human Resources Management', 
    'International Business Management', 
    'Labor/Industrial Relations', 
    'Logistics & Materials Management', 
    'Marketing Management & Research', 
    'Office Supervision & Management', 
    'Operations Management & Supervision', 
    'Organizational Behavior', 
    'Purchasing/Procurement/Contracts Management', 
    'Restaurant/Food Services Management', 
    'Small Business Management/Operations', 
    'Travel/Tourism Management', 
    'Business/Management Quantitative Methods, General', 
    'Actuarial Science*',
    'Business/Managerial Economics', 
    'Finance, General', 
    'Banking & Financial Support Services', 
    'Financial Planning & Services', 
    'Insurance & Risk Management', 
    'Investments & Securities', 
    'Management Information Systems',
    'Real Estate', 
    'Sales, Merchandising, & Marketing, General', 
    'Fashion Merchandising', 
    'Tourism & Travel Marketing', 
    'Secretarial Studies & Office Administration', 
    // COMMUNICATIONS
    'Communications, General',
    'Advertising', 
    'Digital Communications/Media', 
    'Journalism, Broadcast',
    'Journalism, Print', 
    'Mass Communications', 
    'Public Relations & Organizational Communication', 
    'Radio & Television Broadcasting', 
    'Communications Technology, General', 
    'Graphic & Printing Equipment Operation*', 
    'Multimedia/Animation/Special Effects', 
    'Radio & Television Broadcasting Technology*', 
    //COMMUNITY, FAMILY, & PERSONAL SERVICES
    'Family & Consumer Sciences, General', 
    'Adult Development & Aging/Gerontology', 
    'Child Care Services Management', 
    'Child Development', 
    'Consumer & Family Economics', 
    'Food & Nutrition', 
    'Textile & Apparel', 
    'Parks, Recreation, & Leisure, General', 
    'Exercise Science/Physiology/Kinesiology', 
    'Health & Physical Education/Fitness', 
    'Parks/Rec/Leisure Facilities Management', 
    'Sport & Fitness Administration/Management', 
    'Personal Services, General', 
    'Cosmetology/Hairstyling', 
    'Culinary Arts/Chef Training', 
    'Funeral Services & Mortuary Science', 
    'Protective Services, General', 
    'Corrections',
    'Criminal Justice', 
    'Fire Protection & Safety Technology',
    'Law Enforcement', 
    'Military Technologies*', 
    'Public Administration & Services, General', 
    'Community Organization & Advocacy', 
    'Public Administration', 
    'Public Affairs & Public Policy Analysis', 
    'Social Work', 
    // COMPUTER SCIENCE & MATHEMATICS
    'Computer & Information Sciences, General', 
    'Computer Networking/Telecommunications', 
    'Computer Science & Programming', 
    'Computer Software & Media Applications', 
    'Computer System Administration', 
    'Data Management Technology', 
    'Information Science', 
    'Webpage Design', 
    'Mathematics, General',
    'Applied Mathematics', 
    'Statistics', 
    // EDUCATION
    'Counseling & Student Services', 
    'Educational Administration', 
    'Special Education', 
    'Teacher Education, General', 
    'Curriculum & Instruction', 
    'Early Childhood Education',
    'Elementary Education',
    'Junior High/Middle School Education', 
    'Postsecondary Education', 
    'Secondary Education',
    'Teacher Assisting/Aide Education', 
    'Teacher Education, Subject-Specific*',
    'Agricultural Education',
    'Art Education',
    'Business Education',
    'Career & Technical Education', 
    'English-as-a-Second-Language Education', 
    'English/Language Arts Education', 
    'Foreign Languages Education',
    'Health Education',
    'Mathematics Education',
    'Music Education', 
    'Physical Education & Coaching',
    'Science Education',
    'Social Studies/Sciences Education',
    // ENGINEERING
    'Engineering (Pre-Engineering), General', 
    'Aerospace/Aeronautical Engineering',
    'Agricultural/Bioengineering',
    'Architectural Engineering',
    'Biomedical Engineering', 
    'Chemical Engineering', 
    'Civil Engineering', 
    'Computer Engineering', 
    'Construction Engineering/Management', 
    'Electrical, Electronics & Communications Engineering', 
    'Environmental Health Engineering', 
    'Industrial Engineering', 
    'Mechanical Engineering', 
    'Nuclear Engineering',
    // ENGINEERING TECHNOLOGY & DRAFTING
    'Drafting/CAD Technology, General',     
    'Architectural Drafting/CAD Technology',      
    'Mechanical Drafting/CAD Technology', 
    'Engineering Technology, General', 
    'Aeronautical/Aerospace Engineering Technologies', 
    'Architectural Engineering Technology', 
    'Automotive Engineering Technology', 
    'Civil Engineering Technology', 
    'Computer Engineering Technology', 
    'Construction/Building Technology', 
    'Electrical, Electronics Engineering Technologies', 
    'Electromechanical/Biomedical Engineering Technologies',
    'Environmental Control Technologies', 
    'Industrial Production Technologies', 
    'Mechanical Engineering Technology', 
    'Quality Control & Safety Technologies', 
    'Surveying Technology',
    //  ENGLISH & FOREIGN LANGUAGES
    'English Language & Literature, General', 
    'American/English Literature', 
    'Creative Writing', 
    'Public Speaking', 
    'Foreign Languages/Literatures, General', 
    'Asian Languages & Literatures', 
    'Classical/Ancient Languages & Literatures', 
    'Comparative Literature', 
    'French Language & Literature', 
    'German Language & Literature', 
    'Linguistics', 
    'Middle Eastern Languages & Literatures', 
    'Spanish Language & Literature', 
    // HEALTH ADMINISTRATION & ASSISTING
          'Health Services Administration,General', 
          'Hospital/Facilities Administration', 
          'Medical Office/Secretarial', 
          'Medical Records', 
          'Medical/Clinical Assisting, General',
           'Dental Assisting', 
           'Medical Assisting', 
           'Occupational Therapy Assisting', 
           'Physical Therapy Assisting', 
           'Veterinarian Assisting/Technology',
    //  HEALTH SCIENCES & TECHNOLOGIES
           'Chiropractic (Pre-Chiropractic)', 
           'Dental Hygiene', 
           'Dentistry (Pre-Dentistry)', 
           'Emergency Medical Technology', 
           'Health-Related Professions & Services, General*', 
           'Athletic Training', 
           'Communication Disorder Services (e.g., Speech Pathology)', 
           'Public Health',
           'Health/Medical Technology, General', 
           'Medical Laboratory Technology', 
           'Medical Radiologic Technology', 
           'Nuclear Medicine Technology', 
           'Respiratory Therapy Technology', 
           'Surgical Technology', 
           'Medicine (Pre-Medicine)', 
           'Nursing, Practical/Vocational (LPN)', 
           'Nursing, Registered (BS/RN)', 
           'Optometry (Pre-Optometry)', 
           'Osteopathic Medicine', 
           'Pharmacy (Pre-Pharmacy)', 
           'Physician Assisting', 
           'Therapy & Rehabilitation, General', 
           'Alcohol/Drug Abuse Counseling', 
           'Massage Therapy', 
           'Mental Health Counseling', 
           'Occupational Therapy', 
           'Physical Therapy (Pre-Physical Therapy)', 
           'Psychiatric/Mental Health Technician', 
           'Rehabilitation Therapy', 
           'Vocational Rehabilitation Counseling', 
           'Veterinary Medicine (Pre-Veterinarian)',
    // PHILOSOPHY, RELIGION, & THEOLOGY
           'Philosophy', 
           'Religion', 
           'Theology, General', 
           'Bible/Biblical Studies', 
           'Divinity/Ministry', 
           'Religious Education',
    // REPAIR, PRODUCTION, & CONSTRUCTION
           'Aviation & Airway Science, General', 
           'Aircraft Piloting & Navigation', 
           'Aviation Management & Operations', 
           'Construction Trades (e.g., carpentry, plumbing, electrical)',
           'Mechanics & Repairers, General',
          'Aircraft Mechanics/Technology', 
          'Autobody Repair/Technology', 
          'Automotive Mechanics/Technology', 
          'Avionics Technology', 
          'Diesel Mechanics/Technology', 
          'Electrical/Electronics Equip Installation & Repair', 
          'Heating/Air Cond/Refrig Install/Repair', 
          'Precision Production Trades, General', 
          'Machine Tool Technology', 
          'Welding Technology', 
          'Transportation & Materials Moving (e.g., air, ground, & marine)', 
     // SCIENCES: BIOLOGICAL & PHYSICAL     
          'Biology, General', 
          'Biochemistry & Biophysics', 
          'Cell/Cellular Biology', 
          'Ecology', 
          'Genetics', 
          'Marine/Aquatic Biology', 
          'Microbiology & Immunology', 
          'Zoology', 
          'Physical Sciences, General', 
          'Astronomy', 
          'Atmospheric Sciences & Meteorology', 
          'Chemistry', 
          'Geological & Earth Sciences', 
          'Physics', 
    // SOCIAL SCIENCES & LAW
          'Legal Studies, General*', 
          'Court Reporting*', 
          'Law (Pre-Law)', 
          'So Legal Administrative Assisting/Secretarial*', 
          'Paralegal/Legal Assistant', 
          'Social Sciences, General', 
          'Anthropology', 
          'Criminology', 
          'Economics', 
          'Geography', 
          'History', 
          'International Relations & Affairs', 
          'Political Science & Government', 
          'Psychology, Clinical & Counseling', 
          'Psychology, General', 
          'Sociology', 
          'Urban Studies/Urban Affairs']
    
    export const degrees = {
      PHD: 'Doctor of Philosophy (Ph.D.)',
      JD: 'Juris Doctor (J.D.)',
      MD: 'Doctor of Medicine (M.D.)',
      DDS: 'Doctor of Dental Surgery (DDS)',
      MA: 'Master of Arts (M.A.)',
      MS: 'Master of Science (M.S.)',
      MBA: 'Master of Business Administration (MBA)',
      MFA: 'Master of Fine Arts (MFA)',
      BA: 'Bachelor of Arts (B.A.)',
      BS: 'Bachelor of Science (B.S.)',
      BFA: 'Bachelor of Fine Arts (BFA)',
      BAS: 'Bachelor of Applied Science (BAS)',
      AA: 'Associate of Arts (A.A.)',
      AS: 'Associate of Science (A.S.)',
      AAS: 'Associate of Applied Science (AAS)'
    }
    