
import axios from 'axios';
import * as vars from './vars';

export function contactSupport (email, name, subject, message){
    return axios({
        method: 'POST',
        url: vars.baseURL + '/api/v2/support/form_contact',
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data : {
        email: email,
        name: name,
        subject: subject,
        message: message,
        }
    })
}

export function serverPulseCheck (){
    return axios({
        method: 'GET',
        url: vars.baseURL + '/api/v2/pulse',
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data : {}
    })
}
