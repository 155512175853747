import React from 'react';
import { NavBar } from 'Components/Nav/NavBar';
import s from './styles/TopTalent.module.scss';
import Footer from './Footer';
import h from './styles/HowItWorks.module.scss';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import landing_img_2 from './static/landing.svg'
import Zoom from 'react-reveal/Zoom'
import Fade from 'react-reveal/Fade'
import Pulse from 'react-reveal/Pulse'
import background1 from './static/talentbg.svg'

const TopTalent = () => (
  <>
    <div className={s.talent}>
    <div className={s.sectionOne}
         style= {{ backgroundImage: `url(${background1})`,
                   backgroundPosition: "center",
                   backgroundSize: "cover",
                   backgroundRepeat: "no-repeat"
                  }}>
            <NavBar className={s.nav}/>
            <div className={``}>
              <div className={s.grid}>
                <span>
                    <h1>
                      <strong>Do You Have The Skills to Be A Part of NXSTEP’s <span style={{color:'#8EE3C8'}}>Top Talent?</span></strong>
                    </h1>

                    <h2 className={s.subtitle}>
                    Get direct access to higher-paying gigs that match your skills.
                    </h2>

                    <div className={s.action}>
                      <Button className={`btnFill lg px-5 green ${s.join}`} href='https://syzzikodcey.typeform.com/to/QtB9hvUh'>Apply Now</Button>
                      <Button className={`btnFill lg dark ms-3 ${s.join}`} href='/jobs?pg=1'>Find Projects</Button>
                    </div>
                  </span>
                  <div className={s.image}>
                    <img style={{width:'85%', maxWidth: '700px'}} src={landing_img_2}/>
                  </div>
              </div>

            </div>
      </div>    

      <div className={`${s.sectionTwo} ${s.container} `}>
       <Fade>
        <h2>How It Works</h2>
      </Fade>
        <div >
        <Fade>
           <div className={`${s.sectionTwo} ${h.partner} mt-5`}>
          <div className={s.gridThreeCol}>
          <Card style={{flex:1, backgroundColor:'#22388C'}} className={h.card}>
            <Card.Body>
              <Card.Title style={{fontSize:'24px',display: 'flex', justifyContent:'center', alignItems: 'center'}}><strong>1. Apply to Top Talent</strong></Card.Title>
              <Card.Text className='mt-4' style={{lineHeight:'30px', fontSize:'18px'}}>
              Fill out a form letting us kow about your areas of expertise, skills, interests, resume, work experience and your portfolio/featured work, and any certifications you may have (if any).              </Card.Text>
            </Card.Body>
          </Card>
          <Card className={h.card}>
            <Card.Body>
              <Card.Title style={{fontSize:'24px',display: 'flex', justifyContent:'center', alignItems: 'center'}}><strong>2. Application Review</strong></Card.Title>
              <Card.Text className='mt-4' style={{lineHeight:'30px', fontSize:'18px',}}>
              A member of the team will review your application along with your featured work/samples to gauge your level of skill and decide if it meets the Top Talent standards. If accepted, a representative will reach out regarding next steps.              </Card.Text>
              </Card.Body>
          </Card>
          <Card style={{flex:1, backgroundColor:'#05AF8C'}} className={h.card}>
            <Card.Body>
              <Card.Title style={{fontSize:'24px',display: 'flex', justifyContent:'center', alignItems: 'center'}}><strong>3. Acceptance</strong></Card.Title>
                <Card.Text className='mt-4' style={{lineHeight:'30px', fontSize:'18px',}}>
                    The representative that reaches out  upon acceptance will act as your personal assistant. They are will help you manage incoming project offers, work progress, payments and meeting scheduling. Feel free to direct any questions you may have to the representative.
                </Card.Text>
            </Card.Body>
          </Card>
          </div>
          </div>
        </Fade>
        </div>
      </div>
      <Fade>
      <div className={`${s.sectionThree}`}>
       <h2>Perks</h2>
       <h2 className={s.banner}>Top Talent Students will not only receive a badge on their NXSTEP profile to show employers, on-platform, that their skills have been vetted, but they will also be matched directly, off-platform, with <span className="green">short-term project offers</span> that match their skills and strengths.</h2>
        <div className="d-flex justify-content-center">
        <Zoom><Button className={`btnFill lg dark ms-3 ${s.join}`} href='https://syzzikodcey.typeform.com/to/QtB9hvUh'>Apply Now</Button></Zoom>
        </div>
      </div>
      </Fade>
    </div>
    <Footer />
  </>
);
export default (TopTalent);
