import React, {useState, useContext} from 'react'
import { Root } from 'Root';
import Modal from 'react-bootstrap/Modal';
import { SessionContext } from 'Utils/sessions';
import Button from 'react-bootstrap/Button'
import { TextAreaInput } from 'Input/Input';
import {Tile} from 'Components/Tile/Tile'

export const Message = ({recipientId}) => {
    const [show, setShow] = useState(null)
    const [message, setMessage] = useState(null)
    const [error, setError] = useState(null)
    const [success, setSuccess] = useState(false)

    const onHide = () => { setShow(false); setMessage(null); setError(null); setSuccess(false);}
    const {session, refreshSession} =  useContext(SessionContext);

    function sendMessage(){
        setError(null)
        setSuccess(false)
        if(!message) {
            return
        }
        Root.miscApi.createThread({
            participantIds: [recipientId, session.id || session.userId],
            senderId: session.id || session.userId,
            message: message,
            subject: "",
            messageType: "Text"
        }).then(()=> {
            setMessage(null)
            setSuccess(true)
        }).catch(()=> setError('Error sending message, please try again'))
    }

    return <>
        <Button onClick={()=>setShow(true)} className='btnOutline wide'>Message</Button>
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>New Message</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <TextAreaInput containerClassName="mb-3" maxLength={300} name="message" value={message} errors={{}}
            onChange={(e)=> setMessage(e.target.value)} placeholder="..." />
            </Modal.Body>
            <Modal.Footer>
                {success && <Tile color='purple' className='fs-13'>Message sent</Tile>}
                {error && <p className='form-error'>{error}</p>}
                <Button onClick={sendMessage} className='btnFill lg'>Send</Button>
            </Modal.Footer>
        </Modal>
    </>
  };