import React from 'react';
import { NavBar } from 'Components/Nav/NavBar';
import s from './styles/About.module.scss';
import Footer from './Footer';
import h from './styles/HowItWorks.module.scss';
import Card from 'react-bootstrap/Card';

const About = () => (
  <>
    <div className={s.about}>
      <div className={s.sectionOne}>
      <NavBar />
        <div className={`${s.container} pt-5`}>
          <h1><strong>About <span className='green'>Us</span></strong></h1>
          <h3 className='fs-16'>Democratizing <strong>Paid</strong> Professional Experience for All Students</h3>
        </div>
      </div>
      <div className={`${s.sectionTwo} ${s.container} `}>
        <h2>Our Mission</h2>
        <div >
           <div className={`${s.sectionTwo} ${h.partner} mt-5`}>
          <div className={s.gridThreeCol}>
          <Card className={h.card}>
            <Card.Body>
              <Card.Title style={{fontSize:'24px',display: 'flex', justifyContent:'center', alignItems: 'center'}}><strong>Find Students Work</strong></Card.Title>
              <Card.Text className='mt-4' style={{lineHeight:'30px', fontSize:'18px'}}>
              Being a team of students and recent grads, we understand how hard it can be to find jobs that you can manage while being a full-time student.              </Card.Text>
            </Card.Body>
          </Card>
          <Card className={h.card}>
            <Card.Body>
              <Card.Title style={{fontSize:'24px',display: 'flex', justifyContent:'center', alignItems: 'center'}}><strong>Build Student Resumes</strong></Card.Title>
              <Card.Text className='mt-4' style={{lineHeight:'30px', fontSize:'18px',}}>
              Your resume is the #1 most important factor in securing a full-time job of any sort post-undergrad. We’re here to make sure that factor doesn’t hold you back!
              </Card.Text>
              </Card.Body>
          </Card>
          <Card className={h.card}>
            <Card.Body>
              <Card.Title style={{fontSize:'24px',display: 'flex', justifyContent:'center', alignItems: 'center'}}><strong>Get Students Paid</strong></Card.Title>
              <Card.Text className='mt-4' style={{lineHeight:'30px', fontSize:'18px',}}>
              Unpaid internships are a thing of the past. You deserve to get paid for your hard work. “Resume experience” alone is, by no means, a proper compensation.               </Card.Text>
          </Card.Body>
          </Card>
          </div>
          </div>
        </div>
        <h2 className={s.banner}>ANY COLLEGE STUDENT WITH A COMPUTER, ACCESS TO THE INTERNET, AND THE WILLINGNESS TO LEARN AN IN-DEMAND SKILL CAN MAKE MONEY AND BUILD A CAREER WITH NXSTEP.</h2>
        {/* <h2>Our Platform</h2>
        <div className={s.grid}>
          <p>NXSTEP is a one of a kind platform that allows prospective college students to have a 1-on-1 live virtual discussion with a real college student- matched specifically to them by major, personality, etc! Think of it as talking to your future self to gauge whether a school is right for you. During these conversations, you’re encouraged to ask any question that you may have without holding back. Don’t know what questions to ask? We got you! In the menu we provide a questions guide that you can always refer to in order to jumpstart conversation and get the most out of your meetings.</p>
          <p>
            Unlike traditional college tours and other online resources, NXSTEP provides you with an open, honest and comfortable environment to ask questions that you find important. This means that none of the college students are paid to make the school look good but rather to give you their raw and honest feedback.
            Here at NXSTEP, we are committed to creating the ultimate tool to help high school students find the college that’s perfect for them! This means always innovating, improving, and discussing with our users in order to make sure that we are not only the best in the business but the best for them.
          </p>
        </div>
        <h2 className={s.banner}>A PERSONALIZED, AFFORDABLE, CONVENIENT AND EFFECTIVE COLLEGE SEARCH EXPERIENCE</h2> */}
      </div>
    </div>
    <Footer />
  </>
);


{/* <div className={`${s.sectionTwo} ${h.partner}`}>
<h2 className='text-center my-5'>Why NXSTEP</h2>
<div className={h.gridThreeCol}>
<Card className={h.card}>
  <Card.Body>
    <Card.Title style={{fontSize:'24px'}}><strong>Great Value</strong></Card.Title>
    <Card.Text className='mt-4' style={{lineHeight:'30px', fontSize:'18px',}}>
    Work with highly motivated and enthusiastic freelancers who want nothing else but to leave the best impression for their clients while building their resume in the process- all at a price point that's right for your company.
    </Card.Text>
  </Card.Body>
</Card>
<Card className={h.card}>
  <Card.Body>
    <Card.Title style={{fontSize:'24px'}}><strong>Streamlined Hiring</strong></Card.Title>
    <Card.Text className='mt-4' style={{lineHeight:'30px', fontSize:'18px',}}>
    From posting a project to accepting applications in just a few days, NXSTEP provides you with everything necessary to seamlessly scout, interview and communicate with your new hire every step of the way            </Card.Text>
  </Card.Body>
</Card>
<Card className={h.card}>
  <Card.Body>
    <Card.Title style={{fontSize:'24px'}}><strong>Flexible Contracts</strong></Card.Title>
    <Card.Text className='mt-4' style={{lineHeight:'30px', fontSize:'18px',}}>
    Whether you're looking to pay your hire a fixed payment, commission, or on an hourly basis- We support all kinds of flexible and remote contracts to suit all kinds of needs
    </Card.Text>
</Card.Body>
</Card>
</div>
</div> */}

export default (About);
